import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Avatar, Button } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import bgHero from '../../images/home.jpg';
import getPostData from '../Functions/Get-query/getPostData';
import Footer from '../UI-Component/Footer';
import Share from '../UI-Component/Share';

const useStyles = makeStyles((theme) => ({
  root: {
    '& div': {
      alignItems: 'flex-start',
      textAlign: 'left',
      flexDirection: 'row',
    },
    '& a': {
      color: '#37383e',
      transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:active, &:focus, &:visited': {
        color: '#37383e',
      },
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  },
  rootCard: {
    padding: 20,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      padding: '20px 0',
    },
  },

  bgHero: {
    backgroundImage: `url(${bgHero})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '60vh',
    backgroundPosition: 'center',
    position: 'relative',
    marginBottom: 50,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '60vh',
      backgroundImage:
        'linear-gradient(to right, #7063798a, #7063798a, rgb(232 22 129 / 30%), rgb(232 22 129 / 8%), rgb(232 22 129 / 18%))',
    },
    '& h1': {
      position: 'absolute',
      top: '50%',
      left: 0,
      zIndex: 1,
      textAlign: 'center',
      color: theme.palette.primary.main,
      width: '80%',
      margin: '0 10%',
      [theme.breakpoints.down('md')]: {
        top: '40%',
      },
    },
  },
  blogContainer: {
    position: 'relative',
    paddingBottom: '50px',
    [theme.breakpoints.down('md')]: {
      top: '0',
      paddingLeft: '2vh!important',
      paddingRight: '2vh!important',
    },
    '& .MuiGrid-spacing-xs-2': {
      margin: '0 auto',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {},
  avatar: {
    width: '5em',
    height: '5em',
    [theme.breakpoints.down('md')]: {
      width: '3em',
      height: '3em',
    },
  },
  reverseButtonStyle: {
    maxWidth: 'fit-content',
    color: '#E5087E',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: '20px',
    },
  },
}));

const Post = (props) => {
  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }

  const [post, setPost] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const {
      match: {
        params: { slug },
      },
    } = props;
    getPostData(slug)
      .then((result) => {
        setPost(result);
      });
  }, []);

  if (!post) {
    return null;
  }
  const str = post.content_cache;
  if (!str) {
    return null;
  }

  const metaUrl = post.slug;
  const { title } = post;

  const parts = str
    .split('</removable>')
    .map((x) => x.replace('<removable>', '')
      .trimStart()
      .trimEnd()
    );

  const desc = parts[3].slice(0, 150)
    .replace(/(<([^>]+)>)/gi, '');

  const author = parts[1];

  const img = parts[4];

  return (
    <main style={{ overflowX: 'hidden' }}>
      <div>
        <div className={classes.root}>
          <div className={classes.bgHero}>
            <Container maxWidth={false} className={classes.homeContainer}>
              <Typography variant="h1">{title}</Typography>
            </Container>
          </div>

          <Container maxWidth="lg" className={classes.blogContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.rootCard} square>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <Avatar
                        className={classes.avatar}
                        src={parts[0]}
                      ></Avatar>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <Typography
                        variant="body1"
                        component="p"
                        color="textSecondary"
                        style={{ lineHeight: 2 }}
                      >
                        {parts[1]}
                      </Typography>
                      <Typography variant="caption" color="primary">
                        <CalendarTodayIcon color="secondary"/>
                        {parts[2]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>

                <Card className={classes.rootCard} square>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{
                        __html: `${parts[3]}`,
                      }}
                    />
                    <img
                      src={parts[4]}
                      style={{
                        width: '100%',
                        maxHeight: 'auto',
                        minHeight: 300,
                        objectFit: 'cover',
                      }}
                    ></img>
                  </CardContent>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: `${parts[5]}`,
                      }}
                    />

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: `${parts[6]}`,
                      }}
                    />
                    <Share/>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Button
              className={classes.reverseButtonStyle}
              onClick={() => navigate('/blog')}
            >
              <ArrowBackIosIcon/> Retour au blog
            </Button>
          </Container>
        </div>
        <Footer/>
      </div>
    </main>
  );
};

export default Post;
