import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Breadcrumbs,InputAdornment,Divider,Card,CardContent,Container,TextField,Typography, Grid, Chip, List, ListItem,CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import bgHero from '../../images/blog-image.jpg';
import Footer from '../UI-Component/Footer';
import { getBlogData } from '../Functions/Get-query/getBlogData';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    bgHero: {
      backgroundImage: `url(${bgHero})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '40vh',
      backgroundPosition: 'top',
      position: 'relative',
      marginBottom: 50,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '40vh',
        backgroundImage:
          'linear-gradient(to right, #7063798a, #7063798a, rgb(232 22 129 / 30%), rgb(232 22 129 / 8%), rgb(232 22 129 / 18%))',
      },
    },
    homeContainer: {
      position: 'absolute',
      top: '30%',
      left: 0,
      zIndex: 1,
      width: '100%',
      textAlign: 'center',
      display:'flex',
      justifyContent: 'center',
      display: 'inline-grid',
      
      '& h1': {
        color: theme.palette.primary.main,
      },
    },
    blogContainer: {
      position: 'relative',
      borderBottom: '3px solid #edf1f3',
      margin: '15px 0px',
      [theme.breakpoints.down('md')]: {
        top: '0',
        margin: '0px',
        paddingLeft: '2vh!important',
        paddingRight: '2vh!important',
      },
      '& .MuiGrid-spacing-xs-2': {
        margin: '0 auto',
      },
    },
    noResultContainer: {
      position: 'relative',
      margin: '15px 0px',
      [theme.breakpoints.down('md')]: {
        top: '0',
        margin: '0px',
        paddingLeft: '2vh!important',
        paddingRight: '2vh!important',
      },
      '& .MuiGrid-spacing-xs-2': {
        margin: '0 auto',
      },
    },
    categoryContainer:{
      backgroundColor: 'white',
      border: '15px solid #EDF1F3',
      [theme.breakpoints.down('md')]: {
          padding: '0px 15px!important',
      },

    },
    rootCard:{
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
          padding: '15px 0px',
           borderBottom: '1px solid #dde7ee'
      },
    },
    media: {
      width: '100%',
      backgroundColor: "#2637481a",
      [theme.breakpoints.up('lg')]: {
          height: 350,
      },
      objectFit: 'cover',
      borderRadius: '20px',
    },
    cardContent: {
      wordBreak: 'break-word',
      overflow: 'hidden',
      padding : '0px!important',
      [theme.breakpoints.down('md')]: {
        padding: '10px 0px',
       
      },
    },
    postTitle:{
      
      
      [theme.breakpoints.down('lg')]: {
        marginBottom: '15px'
      },
    },
    gridItem: {
      padding: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1), 
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2), 
      },
    },
    cardFooter :{
      '& *': {
        marginRight: '5px',
        marginBottom: '5px'
      }
    },
    listCategories:{
      width: '100%'
    },
    categoryItem:{
      borderBottom: '3px solid #edf1f3',
      
    },
    categoryLastItem : {
      borderBottom: 'none',
    },
    paginationContainer :{
      margin: '20px 0px',
      marginBottom: '40px',
      '& ul': {
        justifyContent: 'center'
      },
      '& button': {
        boxShadow: 'none'
      },
    },
    linkCategory:{
      textDecoration: 'none',
      cursor: 'pointter',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    breadcrumb:{
      [theme.breakpoints.down('xs')]: {
        padding: '0px 15px'
      },
    },
    searchForm: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      '& input' :{
        borderRadius: '10px'
      }
    },
    loader:{
      textAlign: 'center',
      marginTop: '100px',
    },
    fadeIn: {
      opacity: 0,
      animation: '$fadeIn 1s forwards', // Définition de l'animation
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  }),
);

const Blog = () => {
  const classes = useStyles();
  const [PostList, setPostList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activePage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  // Fonction mise à jour pour inclure le filtrage par catégorie
  const fetchData = async (category = null) => {
    setLoading(true); // Commencer le chargement
    setCurrentPage(1);
    try {
      const result = await getBlogData(searchTerm, category);
      if (isObject(result)) {
        const posts = result.fragments;
        setPostList(posts);
        setCategoryList(result.category);
      } else {
        setPostList([]);
        setCategoryList([]);
      }
    } finally {
      setTimeout(() => {  setLoading(false); }, 1000);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initializeSearch = () => {
    setSelectedCategory(null); 
    setSearchTerm(null);
    fetchData();
    scrollTop();
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchData(category);
    scrollTop();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData(selectedCategory);
  };

  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  const scrollTop = () =>{
    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 180,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 180,
      });
    }
  };

  const postToShow = [...PostList];
  const PostsPerPage = 6;
  const indexOfLastPage = activePage * PostsPerPage;
  const indexOfFirstPage = indexOfLastPage - PostsPerPage;
  const currentPost = postToShow.slice(indexOfFirstPage, indexOfLastPage);

  const renderPost = currentPost.map((post) =>(
    <Grid className={`${classes.gridItem} ${classes.fadeIn}`} item sm={12} md={6} lg={6} key={post.slug}>
      <Card className={classes.rootCard} square>
        <Link
            to={`/article/${post.slug}`}
            aria-label="Lire"
            style={{ textDecoration: 'none' }}
        >
          <img
            src={post.image}
            alt={post.slug}
            className={classes.media}
          />
        </Link>
        <CardContent className={classes.cardContent}>
          
          <Link
            to={`/article/${post.slug}`}
            aria-label="Lire"
            style={{ textDecoration: 'none' }}
          >
          <Typography
            variant="h6"
            style={{
              color : "#004589"
            }}
          > { post.date } - { post.author }</Typography>
          <Typography
            className={classes.postTitle}
            variant="body1"
            color="textSecondary"
            component="h2"
            dangerouslySetInnerHTML={{
              __html: post.title,
            }}
          />
          </Link>
          
            <div className={classes.cardFooter}>
              {post.categories.map((category, i) =>
                <Chip onClick={() => handleCategoryClick(category)} style={{cursor:'pointer',fontWeight:500}} label={ category }color='secondary' />
              )}
          </div>
          
        </CardContent>
      </Card>
    </Grid>
  ));

  const renderCategory = CategoryList.map((category, i) => (
    <ListItem key={category.id} className={(i === CategoryList.length - 1) ? classes.categoryLastItem : classes.categoryItem} onClick={() => handleCategoryClick(category.label)}>
      <Typography variant="body2" style={{cursor:'pointer',fontWeight:500}}>{category.label}</Typography>
      <Chip label={category.count} color="secondary" />
    </ListItem>
  ));


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    scrollTop();
  };

  return (
    <>
      <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
          <div className={classes.bgHero}>
            <Container maxWidth={false} className={classes.homeContainer}>
              <Typography variant="h1">Les nouvelles moovejob</Typography>
              <form className={classes.searchForm} onSubmit={handleSearchSubmit}>
                      <TextField
                        id="search"
                        variant="outlined"
                        placeholder="Rechercher parmis les articles..."
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                </form>
            </Container>
          </div>
          
            <Grid container >
              <Grid item md={1} xs={12}>
              </Grid>
              <Grid item md={8} xs={12}>
                
                <Grid item lg={12} className={classes.searchContainer}>
                  <div className={classes.breadcrumb}>
                    <Breadcrumbs separator=" " aria-label="breadcrumb">
                      <Typography variant="h2" style={{textAlign:'left', width:'100%'}}>Blog</Typography>
                      {selectedCategory!=null && (
                        <Typography color="textPrimary" style={{margin:'4vh 0 2vh'}} onClick={() => handleCategoryClick(selectedCategory)}><Chip label={selectedCategory} onDelete={initializeSearch} color="secondary" /></Typography>
                      )}
                    </Breadcrumbs>
                    <p> {PostList.length} article(s) </p>
                    <br/>
                    <Divider/>
                  </div>
                </Grid>
                {loading && (
                  <div className={classes.loader}>
                    <CircularProgress color='secondary' />
                  </div>
                )}                

                {!loading && (
                  <>
                    {PostList.length > 0 && (
                      <Grid item lg={12} className={classes.blogContainer}>
                        <Grid container>
                          {renderPost}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item lg={12} className={classes.paginationContainer}>
                        {postToShow.length > 6 && (
                          <Pagination
                            count={Math.floor(postToShow.length/ 6)}
                            siblingCount={0}
                            page={activePage}
                            onChange={handlePageChange}
                            color="secondary"
                            size="large"
                          />
                        )}
                    </Grid>
                  </>
                )}

                
              </Grid>
              <Grid item md={3} xs={12} style={{ backgroundColor: '#EDF1F3', marginTop : '32xp'}}>
                <Container className={classes.categoryContainer}>
                  <Grid container spacing={2}>
                      <Typography variant="h2" style={{textAlign:'left', width:'100%'}}>Categories</Typography>
                      <List className={classes.listCategories}>
                        {renderCategory}
                      </List>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
        </div>
        <Footer/>
      </main>
    </>
  );
};

export default Blog;
