import React from 'react';
import {
  Dialog, DialogActions, DialogTitle, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 20,
    },
    '& h2': {
      fontSize: '1.4em',
      fontWeight: '500',
      textAlign: 'center'
    },
    '& button': {
      minWidth: 'unset',
      margin: 0,
      padding: '0 16px',
      height: '40px'
    }
  },
  cancelBtn: {
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
  }
}));

const SubscriptionDialog = ({ open, handleClose, updateAutoRenew, subscription }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle id="alert-dialog-title">
        {subscription.allow_renew ? "Êtes vous certain de vouloir annuler le renouvellement automatique ?" : 'Activer le renouvellement automatique ?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {subscription.allow_renew ? 'Conserver' : 'Annuler'}
        </Button>
        <Button onClick={updateAutoRenew} className={classes.cancelBtn}>
          {subscription.allow_renew ? 'Annuler' : 'Activer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionDialog;
