import React from 'react';
import { useHistory, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Hooks/scrollToTop';
import Header from './UI-Component/Header';
import Cookie from './UI-Component/Cookie';
import Routes from './Routes';
import AlertUser from './AlertUser';

export default () => {
  const history = useHistory();

  return (
    <>
      <AlertUser />
      <Router history={history}>
        <ScrollToTop />
        <Header />
        <Routes />
      </Router>
      {!process.env.TEST_MODE && (
        <Cookie />
      )}
    </>
  );
};
