import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from '../components/App';

Sentry.init({
  dsn: 'https://ed78bc2522134f92be2a54597cebb11d@o481104.ingest.sentry.io/5713232',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.RAILS_ENV,
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  }
});

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('div'))
  );
});
