export default (productPrice, discountAmount, discountPercent) => {
  const discountAmountNumber = () => {
    if (discountPercent != null) {
      const basePrice = parseFloat(productPrice);
      const discount = discountPercent / 100;
      const discountedPrice = basePrice * discount;
      return (`-${discountedPrice.toString()}`);
    }
    const discount = discountAmount / 100;
    return (`-${discount.toString()}`);
  };

  const appliedDiscount = () => {
    if (discountPercent != null) {
      const discount = discountPercent / 100;
      const basePrice = parseFloat(productPrice);
      const discountedTotal = basePrice * discount;
      const discountedPrice = basePrice - discountedTotal;
      return discountedPrice.toString();
    } else {
      const discount = discountAmount / 100;
      const basePrice = parseFloat(productPrice);
      const discountedPrice = basePrice - discount;
      return discountedPrice.toString();
    }
  };

  const calculatedDiscountedTPS = () => {
    const basePrice = parseFloat(appliedDiscount());
    const taxedPrice = basePrice * 0.05;
    const roundedPrice = taxedPrice.toFixed(2);
    return parseFloat(roundedPrice);
  };

  const calculatedDiscountedTVQ = () => {
    const basePrice = parseFloat(appliedDiscount());
    const taxedPrice = basePrice * 0.09975;
    const roundedPrice = taxedPrice.toFixed(2);
    return parseFloat(roundedPrice);
  };

  const calculatedTotalDiscounted = () => {
    const basePrice = parseFloat(appliedDiscount());
    const taxesTotal = parseFloat(
      calculatedDiscountedTVQ() + calculatedDiscountedTPS()
    );
    return parseFloat(basePrice + taxesTotal)
      .toFixed(2);
  };

  const calculatedTPS = () => {
    const basePrice = parseFloat(productPrice);
    const taxedPrice = basePrice * 0.05
    const roundedPrice = taxedPrice.toFixed(2)
    return parseFloat(roundedPrice);
  };

  const calculateTVQ = () => {
    const basePrice = parseFloat(productPrice);
    const taxedPrice = basePrice * 0.09975;
    const roundedPrice = taxedPrice.toFixed(2);
    return parseFloat(roundedPrice);
  };

  const calculatedTotal = () => {
    const basePrice = parseFloat(productPrice);
    const taxesTotal = parseFloat(calculateTVQ() + calculatedTPS());
    return parseFloat(basePrice + taxesTotal).toFixed(2);
  };

  return {
    calculatedTotal,
    calculateTVQ,
    calculatedTPS,
    calculatedTotalDiscounted,
    calculatedDiscountedTVQ,
    calculatedDiscountedTPS,
    discountAmountNumber
  };
};
