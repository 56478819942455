import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardHeader, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import theme from '../../../theme';
import LangueModal from '../FormModal/langueModal';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  languageCard: {
    width: '100%',
    borderTop: 'none'
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  languageTitle: {
    '& span': {
      fontSize: '1.1rem',
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
}));

const Langues = (props) => {
  const { languages } = props;
  const { refreshUserData, updateUser, user } = useSessionContext();

  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  const classes = useStyles(theme);

  const [targetLanguage, setTargetLanguage] = useState(0);
  const [modalMode, setModalMode] = useState('create');

  const updateLanguages = (lastLangs) => {
    const raw = {
      user: {
        languages: lastLangs,
      },
    };

    updateUser(raw).then(async (response) => {
      if (response.status === 200) {
        await refreshUserData();
      }
    });
  };

  const CarteLangues = () => {
    const carteArr = [];
    languages.forEach((lang, i) => {
      carteArr.push(
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <CardHeader title={lang.name} className={classes.languageTitle} />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Fab
              id={`deleteLanguage-${lang.id}`}
              aria-label="Delete"
              color='secondary'
              onClick={() => {
                const filtertedLangs = languages.filter(
                  (l) => l.id !== lang.id,
                );
                updateLanguages(filtertedLangs);
              }}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        </Grid>
      );
    });
    return carteArr;
  };
  return (
    <Grid container>
      <Card square className={classes.rootCard} elevation={0} variant="outlined">
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <CardHeader title="Langues" subheader={user.type_of_user=='Candidate' ? "Renseigner les langues parlées" : "Renseigner les langues que les candidats doivent parler"} className={classes.profileTabTitle} />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 0',
            }}
          >
            <Fab
              id="addLanguage"
              color="secondary"
              aria-label="add"
              onClick={() => {
                refreshUserData();
                setModalMode('add');
                openModal();
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Card>
      
      <LangueModal
        targetLanguage={targetLanguage}
        updateLanguages={updateLanguages}
        mode={modalMode}
        userLanguages={languages}
        closeModal={closeModal}
        show={show}
      />
      <Card square className={classes.languageCard} elevation={0} variant="outlined">
        {CarteLangues()}
      </Card>
    </Grid>
  );
};

export default Langues;
