import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  FormGroup,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions';
import Rating from '@material-ui/lab/Rating';
import theme from '../../../theme';
import { getMetiersSearch } from '../../../Functions/Get-query/getMetiersSearch';
import { getJobRequirements } from '../../../Functions/Get-query/getJobRequirements';
import { getUserMetier } from '../../../Functions/Get-query/getUserMetier';
import useSessionContext from '../../../sessions/useSessionContext';
import changeMetier from '../../../Functions/Save-data/changeMetier';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  addButton: {
    minWidth: '50px',
    height: '50px',
    margin: theme.spacing(3, 0, 3),
    padding: 0,
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  html: {
    height: '100%',
    margin: 0,
  },
  root: {
    display: 'block',
    margin: '2rem 0',
    width: '100%',
    '& span': {
      fontSize: '1.3rem',
      fontWeight: ' 300',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: `${CheckIcon}`,
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#000',
    backgroundImage: `${CheckIcon}`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `${CheckIcon}`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  radioLegend: {
    textAlign: 'left',
    fontSize: '1.3rem',
    fontWeight: '300',
    marginBottom: '2vh',
  },
  selectionLabel: {
    display: 'block',
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: '1rem',
  },
  fieldSetWrapper: {
    marginBottom: 24,
    borderRadius: 6,
  },
}));

const starsLabel = ['0 à 2 ans', '2 à 5 ans', '5 à 7 ans', '7 à 10 ans', '+ de 10 ans'];

const parseFromElasticSearchResults = (results, userMetiers) => {
  const parsedJobsArray = [];
  results.forEach((res) => {
    parsedJobsArray.push({
      title: res._source.name,
      id: res._source.id,
    });
    if (!userMetiers.some(metier => metier.metier_id == res._source.id)) {
      parsedJobsArray.push({
        title: res._source.name,
        id: res._source.id,
      });
    }
  });
  return parsedJobsArray;
};

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles(theme);
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
      icon={<span className={classes.icon}/>}
      {...props}
    />
  );
}

const EditMetierSelectionSteps = ({
  userType,
  jobLimit,
  onFormChange,
  handleClose,
  metierId,
  userMetierId,
  metierName,
}) => {
  const classes = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [initialData, setInitialdata] = useState({
    experienceLevels: [],
    generalEducation: [],
    specificEducation: [],
    workType: [],
    materialType: [],
    otherSpecific: [],
  });
  const [allowSimilarMatch, setAllowSimilarMatch] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [newMetier, setNewMetier] = useState({
    metierId: metierId,
    metierName : metierName
  });
  const [disableAutoComplete, setDisableAutoComplete ] = useState(true);
  const [numberOfStars, setNumberOfStars] = useState({});

  const onNumberOfStarsChange = ({target}, newValue) => {
    const { name } = target;
    setNumberOfStars((numberOfStars => ({
      ...numberOfStars,
      [name]: newValue
    })));
  }

  useEffect(() => {
    if (Object.keys(formErrors).length > 0 && formErrors.verifyAll === true) {
      const items = document.getElementsByClassName('error');
      const visible = [...items].filter((el) =>
        // jQuery-like :visible selector
        !!(
          el.offsetWidth
          || el.offsetHeight
          || el.getClientRects().length
        ));

      if (visible.length > 0) {
        window.scrollTo({
          top: items[0].offsetTop,
          behavior: 'smooth',
        });
        setTimeout(() => {
          window.scrollBy(0, 0);
          setFormErrors({
            ...formErrors,
            verifyAll: false,
          });
        }, 100);
      }
    }
  }, [formErrors]);

  const handleArrayChoice = (event) => {
    const data = event.target.value;
    if (data === undefined || selectedData === undefined) {
      return null;
    }
    const key = data.split(',')[0];
    const id = data.split(',')[1];

    let oldSelection = selectedData[key];

    if (oldSelection === undefined) {
      oldSelection = [];
    }

    let newSelection = [...oldSelection];

    if (key === 'experienceLevels') {
      if (!oldSelection.includes(id) && oldSelection.length === 0) {
        newSelection.push(id);
      } else {
        newSelection = [];
        newSelection.push(id);
      }
    } else if (!oldSelection.includes(id)) {
      newSelection.push(id);
    } else {
      newSelection = oldSelection.filter((e) => id !== e);
    }
    setSelectedData({
      ...selectedData,
      [key]: newSelection,
    });
  };

  function handleCheck(i, element) {
    let isChecked = false;
    if (Object.keys(initialData)[i] in selectedData) {
      if (selectedData[Object.keys(initialData)[i]].includes(element.id.toString())) {
        isChecked = true;
      }
    }
    return isChecked;
  }

  const renderCheckboxes = () => {
    const titleTrad = {
      generalEducation: 'Enseignement géréral',
      experienceLevels: 'Expérience de travail',
      specificEducation: 'Étude spécifique',
      workType: 'Type de travail ( Minimum 3 choix )',
      materialType: 'Outils de travail utilisés',
      otherSpecific: 'Autres spécifications',
    };

    return (
      Object.keys(initialData)
        .map((key, index) => {
          if (initialData[Object.keys(initialData)[index]].length === 0) {
            return false;
          }
          return (
            <fieldset className={classes.fieldSetWrapper}>
              <legend>
                {titleTrad[Object.keys(initialData)[index]]}
              </legend>
              <FormGroup>
                {initialData[Object.keys(initialData)[index]].map((element) => (
                  <>
                    <FormControlLabel
                      value={[Object.keys(initialData)[index], element.id, element.label]}
                      label={element.label}
                      control={<StyledRadio/>}
                      checked={handleCheck(index, element)}
                    />
                    { handleCheck(index, element) && Object.keys(initialData)[index] != 'experienceLevels'
                      && Object.keys(initialData)[index] != 'generalEducation' && Object.keys(initialData)[index] != 'specificEducation' &&
                    <>
                      <Typography component="legend">Niveau d'expérience</Typography>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box component="fieldset" mb={3} borderColor="transparent">
                            <Rating
                              name={element.label}
                              value={numberOfStars[element.label] ?? 0}
                              onChange={onNumberOfStarsChange}
                            />
                          </Box>
                        </Grid>
                          <Grid item xs={6}>
                              <Typography component="legend">{starsLabel[numberOfStars[element.label] - 1]}</Typography>
                          </Grid>
                      </Grid>
                    </>
                    }
                  </>
                ))}
              </FormGroup>
            </fieldset>
          );
        })
    );
  };

  function createCheckBoxes() {
    return (
      <div className={classes.root}>
        <FormLabel className={classes.selectionLabel}>
          Sélections
        </FormLabel>
        <RadioGroup
          required
          onClick={handleArrayChoice}
          id="id"
          defaultValue=""
          aria-label="Experience"
          name="customized-radios"
        >
          {renderCheckboxes()}
        </RadioGroup>
      </div>
    );
  }

  const handleJobs = () => {
    const value = []

    getUserMetier(userMetierId)
      .then((userMetierData) => {
        setAllowSimilarMatch(userMetierData.allowSimilarMatch);
        const numberOfAllowedMetierChanges = userMetierData.numberOfAllowedMetierChanges;
        setNumberOfStars(userMetierData.numberOfStars);
        if (numberOfAllowedMetierChanges  > 0) {
          setDisableAutoComplete(false)
        }
        getJobRequirements(metierId)
          .then((response) => {
            if (response.total > 0 && response.results) {
              setSelectedData(userMetierData);
              setInitialdata({
                experienceLevels: response.results.ExperienceLevels,
                generalEducation: response.results.GeneralEducation,
                specificEducation: response.results.SpecificEducation,
                workType: response.results.WorkType,
                materialType: response.results.MaterialType,
                otherSpecific: response.results.OtherSpecific,
              });
            }

            setShowNextSteps(true);
            onFormChange({
              target: {
                name: 'jobs',
                value,
              },
            });
          });
      });
  };

  useEffect(() => {
    handleJobs();
  }, []);

  const onTextChanged = (event) => {
    if (!loading) {
      setLoading(true);
      const query = event.target.value;
      if (query === null) {
        setJobList([]);
      } else {
        getMetiersSearch(query).then((result) => {
          if (result.total > 0) {
            const parsedRes = parseFromElasticSearchResults(result.results, user.metiers);
            setJobList(parsedRes);
          }
          setLoading(false);
        });
      }
    }
  };

  const errors = {};

  const {
    refreshUserData,
    updateUser,
    user,
  } = useSessionContext();

  const handleSubmit = () => {
    selectedData.remote = false;
    selectedData.table_type = 'metier';
    selectedData.allow_similar_match = 1;
    const raw = {
      user: {
        selected_data: selectedData,
        update_user_metier: true,
        user_metier_id: metierId,
        number_of_stars: numberOfStars,
      },
    };
    if (metierId != newMetier.metierId)  {
      changeMetier(metierId, newMetier.metierId).catch(error => console.log(error));
    }
    updateUser(raw)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.status === 400 || response.status === 401) {
            if (response.data.error) {
              setFormErrors({
                ...errors,
                verifyAll: true,
              });
            }
          }
          if (response.status === 200) {
            refreshUserData();
            handleClose();
          }
        }
      });
  };

  const close = () => {
    setJobList([]);
  };
  const handleMetierSelection = (e, data) => {
    const value = [];
    if (metierId != data.id) {
      getJobRequirements(data.id)
      .then((response) => {
        if (response.total > 0 && response.results) {
          setSelectedData({});
          setInitialdata({
            experienceLevels: response.results.ExperienceLevels,
            generalEducation: response.results.GeneralEducation,
            specificEducation: response.results.SpecificEducation,
            workType: response.results.WorkType,
            materialType: response.results.MaterialType,
            otherSpecific: response.results.OtherSpecific,
          });
        }
        setShowNextSteps(true);
        onFormChange({
          target: {
            name: 'jobs',
            value,
          },
        });
      });
      setNewMetier({
        metierId: data.id,
        metierName : data.name
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.smallTitle}>
          {userType === 'Enterprise' ? (
            <>
              Sélectionnez le métier que vous recherchez
            </>
          ) : (
            <>
              Votre profession
            </>
          )}
        </Typography>
        <Autocomplete
          multiple={jobLimit != 1}
          onOpen={onTextChanged}
          onClose={close}
          limitTags={jobLimit || 3}
          name="jobs"
          id="jobs"
          loading={loading}
          options={jobList}
          getOptionLabel={(option) => option.title}
          key={(option) => option.id}
          disabled={true}
          onChange={handleMetierSelection}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={onTextChanged}
              variant="outlined"
              error={!!errors.jobs}
              label={metierName}
              placeholder="Emploi"
            />
          )}
        />
        <>{showNextSteps && createCheckBoxes()}</>
      </Grid>
      <DialogActions>
        <Button onClick={handleSubmit}>Modifier</Button>
      </DialogActions>
    </>
  );
};

export default EditMetierSelectionSteps;
