import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import useSessionContext from './sessions/useSessionContext';

export default () => {
  const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const { SnackBar } = useSessionContext();
  const {
    handleClose,
    snackBarOpened,
    alert
  } = SnackBar;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();
  const {
    msg,
    severity
  } = alert;

  return (
    <div className={classes.root}>
      <Snackbar open={snackBarOpened} autoHideDuration={6000} onClose={handleClose}>
        <Alert key={msg} onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
};
