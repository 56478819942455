import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getServicesSearch = (value) => (
  axios.get(`${apiUrl()}/services?query=${value}*`)
    .then((response) => response['data'])
    .catch((error) => errorHandling(error))
);

export default getServicesSearch;
