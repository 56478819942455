import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardHeader } from '@material-ui/core';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
  },
  fitRight: {
    height: '40px',
    '& span': {
      padding: '0 16px',
    },
  },
  typography: {
    margin: 16,
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  formLabel: {
    width: '100%',
    marginLeft: 0,
  },
  buttonRow: {
    padding: '10px 0 5px',
  },
  buttonBox: {
    margin: '15px auto',
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
}));

const ListOfValues = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, refreshUserData } = useSessionContext();
  const { userValues } = user;
  const { informError, informSuccess } = SnackBar;
  const [valuesList, setValuesList] = useState({});

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const trueCount = Object.values(valuesList).filter(
      (value) => value === true,
    ).length;
    if (trueCount === 7 && !valuesList[name]) {
      informError('Vous avez déjà choisi 7 valeurs');
    } else {
      const userValuesArray = [];
      Object.entries(valuesList).map(([key, value]) => {
        if (value) {
          userValuesArray.push(key);
        }
      });
      saveUserValueData(e.target, checked);
      setValuesList((valuesList) => ({
        ...valuesList,
        [name]: checked,
      }));
    }
  };

  const saveUserValueData = (target, checked) => {
    let url = '/values/user/create';

    if (!checked) {
      url = '/values/user/delete';
    }
    refreshUserData();
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_value_name: target.name,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          return Promise.reject({
            errorMessage: data.error_message,
            errorStatus: response.status,
          });
        }
        informSuccess('Succès!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchValuesList = () => {
    fetch('/get_list_of_values')
      .then((response) => response.json())
      .then((data) => {
        userValues.forEach((value) => {
          data[value] = true;
        });
        setValuesList(data);
      })
      .catch(() => informError('Une erreur est survenue'));
  };
  useEffect(() => {
    fetchValuesList();
  }, []);

  const renderValuesList = Object.entries(valuesList).map(([key, value]) => (
    <FormControlLabel
      className={classes.formLabel}
      key={key}
      control={
        <Checkbox
          checked={value}
          onChange={(event) => handleChange(event)}
          name={key}
        />
      }
      label={key}
    />
  ));

  return (
    <Card square className={classes.rootCard} elevation={0} variant="outlined">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <CardHeader title="Vos valeurs"  subheader={user.type_of_user=='Candidate' ? "Choisissez dans la liste les 7 valeurs qui vous ressemblent et touchent le plus" : "Choisissez dans la liste les 7 valeurs qui ressemblent le plus à votre culture d’entreprise"} className={classes.profileTabTitle} />
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <FormGroup row>{renderValuesList}</FormGroup>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ListOfValues;
