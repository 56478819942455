import React from 'react';
import { Route } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import bgHero from '../images/monde-ouvert.jpg';
import ConnexionBox from './UI-Component/ConnexionBox';

export default ({
  component: Component,
  children,
  hasAccess,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    bgHero: {
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${bgHero})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '60vh',
        backgroundPosition: 'center',
        position: 'relative',
        marginBottom: 50,

        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '60vh',
          backgroundImage:
            'linear-gradient(to right, #7063798a, #7063798a, rgb(232 22 129 / 30%), rgb(232 22 129 / 8%), rgb(232 22 129 / 18%))',
        },
        '& h1': {
          position: 'absolute',
          top: '50%',
          left: 0,
          zIndex: 1,
          width: '100%',
          textAlign: 'center',
          color: theme.palette.primary.main,
        },
      },
    },
    profileContainer: {
      [theme.breakpoints.up('md')]: {
        minHeight: '100vh',
        top: '-12vh',
        position: 'relative',
      },
      [theme.breakpoints.down('md')]: {
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        paddingLeft: '2vh!important',
        paddingRight: '2vh!important',
      },
    },
    cardButton: {
      backgroundColor: theme.palette.secondary.secondary,
      padding: '0 8px',

      '& svg': {
        width: 20,
        height: 20,
        fill: theme.palette.secondary.main,
      },
    },
  }));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasAccess) return (Component ? <Component {...props} {...rest} /> : children);
        return <ConnexionBox defaultOpen />;
      }}
    />
  );
};
