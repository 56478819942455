import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CheckIcon from '@material-ui/icons/Check';
import theme from '../../../theme';
import { Checkbox, FormGroup } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '2vh 0',
    width: '100%',
    '& span': {
      fontSize: '1.3rem',
      fontWeight: ' 300',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: `${CheckIcon}`,
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#000',
    backgroundImage: `${CheckIcon}`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `${CheckIcon}`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  radioLegend: {
    textAlign: 'left',
    fontSize: '1.3rem',
    fontWeight: '300',
    marginBottom: '2vh',
  },
});


// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles(theme);
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}



const WorldWide = (props) => {
  const {checked, onChange} = props;
  const classes = useStyles(theme);

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend" className={classes.radioLegend}>
      </FormLabel>
      <FormGroup
        defaultValue=""
        aria-label="Partout dans le monde"
        name='world'
      >
        <FormControlLabel
        control={
          <Checkbox
          checked={checked}
          onChange={onChange}
          id="worldWide"
          value="worldWide"
          control={<StyledRadio />}
          />
        }
          label="Partout dans le monde"
        />

      </FormGroup>
    </FormControl>
  );
}
export default WorldWide;

