import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup, Typography,
  Checkbox,
  Box,
  Select,
  MenuItem,
  ListItemText,
  Input,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Rating from '@material-ui/lab/Rating';
import clsx from 'clsx';
import theme from '../../../theme';
import { getMetiersSearch } from '../../../Functions/Get-query/getMetiersSearch';
import { getJobRequirements } from '../../../Functions/Get-query/getJobRequirements';
import { getUserMetier } from '../../../Functions/Get-query/getUserMetier';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  addButton: {
    minWidth: '50px',
    height: '50px',
    margin: theme.spacing(3, 0, 3),
    padding: 0,
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '500',
    margin: '2vh 0',
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  html: {
    height: '100%',
    margin: 0,
  },
  root: {
    display: 'block',
    margin: '2rem 0',
    width: '100%',
    '& span': {
      fontSize: '1.3rem',
      fontWeight: ' 300',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: `${CheckIcon}`,
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#000',
    backgroundImage: `${CheckIcon}`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `${CheckIcon}`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  radioLegend: {
    textAlign: 'left',
    fontSize: '1.15rem',
    fontWeight: '300',
    marginBottom: '2vh',
  },
  selectionLabel: {
    display: 'block',
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: '1rem',
  },
  fieldSetWrapper: {
    marginBottom: 24,
    borderRadius: 6,
  },
  listItemsContract: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textTransform: 'uppercase',
    fontSize: '1.3rem',
  },
  bold: {
    fontWeight: 600,
  }
}));

const starsLabel = ['0 à 2 ans', '2 à 5 ans', '5 à 7 ans', '7 à 10 ans', '+ de 10 ans'];

const parseFromElasticSearchResults = (results) => {
  const parsedJobsArray = [];
  results.forEach((res) => {
    parsedJobsArray.push({
      title: res._source.name,
      id: res._source.id,
    });
  });
  return parsedJobsArray;
};

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles(theme);
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const ViewPret = ({
  userType,
  jobLimit,
  metierId,
  userMetierId,
  metierName,
  viewProfile,
}) => {
  const classes = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [initialData, setInitialdata] = useState({});
  const [contractTypeList, setContractTypeList] = useState([]);
  const [contractTypeUserId, setContractTypeUserId] = useState(null);
  const [pendingContract, setPendingContract] = useState([]);
  const [candidatePersonalInformation, setCandidatePersonalInformation] = useState({
    numberOfChildren: 0,
    availability: '',
    maritalStatus: '--',
    driverLicense: '--',
    candidateNationalities: [] ,
  });
  const [candidateLinks, setCandidateLinks] = useState({
    personalityTest: "",
    portfolioLink: "",
    linkedinLink: "",
    presentationLink: "",
  })
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [candidateDesiredDestinations, setCandidateDesiredDestinations] = useState({})
  const { user } = useSessionContext();
  const [numberOfStars, setNumberOfStars] = useState({});
  const [userValues, setUserValues] = useState([]);
  const [commonValues, setCommonValues] = useState([]);
  const [matchPercentage, setMatchPercentage] = useState(0);
  const [remote, setRemote] = useState(false);
  const [daysAvailability, setDaysAvailability] = useState(null);
  const [monthsAvailability, setMonthsAvailability] = useState([]);
  const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']

  function handleCheck(i, element) {
    let isChecked = false;
    if (Object.keys(initialData)[i] in selectedData) {
      if (selectedData[Object.keys(initialData)[i]].includes(element.id.toString())) {
        isChecked = true;
      }
    }
    return isChecked;
  }

  const renderCheckboxes = () => {
    const titleTrad = {
      generalEducation: 'Enseignement géréral',
      experienceLevels: 'Expérience de travail',
      specificEducation: 'Étude spécifique',
      workType: 'Type de travail ( Minimum 3 choix )' ,
      materialType: 'Outils de travail utilisés',
      otherSpecific: 'Autres spécifications',
    };

    return (
      Object.keys(initialData).map((key, index) => {
        if (initialData[Object.keys(initialData)[index]].length === 0) {
          return false;
        }
        return (
          <fieldset key={key} className={classes.fieldSetWrapper}>
            <legend className={classes.smallTitle}>
              {titleTrad[Object.keys(initialData)[index]]}
            </legend>
            <FormGroup>
              {initialData[Object.keys(initialData)[index]].map((element) => {
                if (handleCheck(index, element)) {
                  const numberOfStarsExists = (numberOfStars[element.label] == undefined || numberOfStars[element.label] == null ||
                    numberOfStars[element.label] == 0) ? false : true;
                  return (
                    <>
                      <FormControlLabel
                        key={element.id}
                        value={[Object.keys(initialData)[index], element.id, element.label]}
                        label={element.label}
                        control={<StyledRadio />}
                        checked
                      />
                      { handleCheck(index, element) && numberOfStarsExists && Object.keys(initialData)[index] != 'experienceLevels'
                        && Object.keys(initialData)[index] != 'generalEducation' && Object.keys(initialData)[index] != 'specificEducation' &&
                      <>
                        <Typography component="legend">Niveau d'expérience</Typography>
                        <Grid container>
                          <Grid item xs={6}>
                            <Box component="fieldset" mb={3} borderColor="transparent">
                              <Rating
                                name={element.label}
                                value={numberOfStars[element.label] ?? 0}
                                readOnly
                              />
                            </Box>
                          </Grid>
                            <Grid item xs={6}>
                                <Typography component="legend">{starsLabel[numberOfStars[element.label] - 1]}</Typography>
                            </Grid>
                        </Grid>
                      </>
                      }
                    </>
                  );
                }
                return false;
              })}
            </FormGroup>
          </fieldset>
        );
      })
    );
  };

  function createCheckBoxes() {
    const arraySelections = [];
    const titleTrad = {
      generalEducation: 'Enseignement géréral',
      experienceLevels: 'Expérience de travail',
      specificEducation: 'Étude spécifique',
      workType: 'Type de travail',
      materialType: 'Outils de travail utilisés',
      otherSpecific: 'Autres spécifications',
    };
    for (let i = 0, l = Object.keys(initialData).length; i < l; i += 1) {
      if (initialData[Object.keys(initialData)[i]].length === 0) {
        break;
      }
      const title = titleTrad[Object.keys(initialData)[i]];
      arraySelections.push(<FormControl>
        <FormLabel component="legend" className={classes.radioLegend} style={{ justifyContent: 'space-around' }} />
        <FormLabel component="legend" className={classes.radioLegend} style={{ justifyContent: 'space-around' }}>
          {title}
        </FormLabel>
      </FormControl>);
      initialData[Object.keys(initialData)[i]].forEach((element) => {
        let isChecked = false;
        if (Object.keys(initialData)[i] in selectedData) {
          if (selectedData[Object.keys(initialData)[i]].includes(element.id.toString())) {
            isChecked = true;
          }
        }
        { isChecked ? arraySelections.push(
            <FormControlLabel
              value={[Object.keys(initialData)[i], element.id, element.label]}
              label={element.label}
              control={<StyledRadio />}
              checked={isChecked}
            />,
          ) : null;
        }
      });
    }

    if (!Array.isArray(selectedData.generalEducation)) {
      selectedData.generalEducation = [selectedData.generalEducation]
    }

    selectedData.generalEducation = selectedData.generalEducation.filter(x => x !== null)

    return (
        <div className={classes.root}>
          <FormLabel className={classes.selectionLabel}>
            {viewProfile || 'Sélections des informations relative au métier'}
          </FormLabel>
          {user.type_of_user == "Enterprise" && selectedData.generalEducation.length==0 ?  (
            <Typography  variant="caption" className={classes.selectionLabel}>
              La fiche métier n'est pas encore complète
            </Typography>
          ) : null}

          {user.type_of_user == "Candidate" && selectedData.generalEducation.length==0 ? (
            <Typography variant="caption" className={classes.selectionLabel}>
              L'entreprise n'a pas encore complété la fiche métier
            </Typography>
          ) : null}
         
          <RadioGroup
            required
            id="id"
            defaultValue=""
            aria-label="Experience"
            name="customized-radios"
          >
            {renderCheckboxes()}
          </RadioGroup>
          <fieldset className={classes.fieldSetWrapper}>
              
              <legend className={classes.smallTitle}>
              Nb jours de disponibilité par semaine
              </legend>
              <FormGroup>
              <Select
              readOnly
                        value={daysAvailability}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={1}>1 jour</MenuItem>
                        <MenuItem value={2}>2 jours</MenuItem>
                        <MenuItem value={3}>3 jours</MenuItem>
                        <MenuItem value={4}>4 jours</MenuItem>
                        <MenuItem value={5}>5 jours</MenuItem>
                        <MenuItem value={0}>A discuter</MenuItem>
                </Select>
              </FormGroup>
        </fieldset>
        <fieldset className={classes.fieldSetWrapper}>
        <legend className={classes.smallTitle}>
        Mois de disponibilité
              </legend>
              <FormGroup>
              <Select
                multiple
                readOnly
                value={monthsAvailability}
                input={<Input />}
                renderValue={(selected) => selected.map(index => months[index]).join(', ')}
              >
                {months.map((name,index) => (
                  <MenuItem key={name} value={index}>
                    <Checkbox checked={monthsAvailability.indexOf(index) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              </FormGroup>
        </fieldset>

        { !viewProfile && (
          <>
            <FormLabel className={classes.selectionLabel}>
              Étendre les matchs
            </FormLabel>
            <RadioGroup
              required
              name="customized-radios"
            >
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    className={classes.switch}
                    control={<Switch checked={selectedData.allowSimilarMatch} readOnly={true}/>}
                    label="Obtenez des correspondances élargies avec des métiers aux compétences similaires"
                  />
                </FormGroup>
              </FormControl>
            </RadioGroup>
          </>
        )}
        </div>
      );
  }


  const enterpriseInformationList = (
    <>
      <li className={classes.listItemsContract}>Nombre d'employés: <span className={classes.bold}>{employeeNumber}</span></li>
    </>
  );

  const handleJobs = () => {
    getUserMetier(userMetierId).then((userMetierData) => {
      setUserValues(userMetierData.userValues ?? []);
      setCommonValues(userMetierData.commonValues ?? []);
      setEmployeeNumber(userMetierData.employeeCount)
      setNumberOfStars(userMetierData.numberOfStars);
      setMatchPercentage(userMetierData.matchPercentage);
      setCandidateDesiredDestinations(userMetierData.candidateDesiredDestinations);
      setRemote(userMetierData.remote);
      setDaysAvailability(userMetierData.days_availability);
      if(userMetierData.months_availability ){
        setMonthsAvailability(JSON.parse(userMetierData.months_availability).map(str => parseInt(str, 10)));
      }      
      getJobRequirements(metierId).then((response) => {
        let generalEducationList = [];
        let experienceLevelsList = [];
        let specificEducationList = [];
        let workTypeList = [];
        let materialTypeList = [];
        let otherSpecificList = [];

        if (response.total > 0) {
          experienceLevelsList = response.results.ExperienceLevels;
          generalEducationList = response.results.GeneralEducation;
          specificEducationList = response.results.SpecificEducation;
          workTypeList = response.results.WorkType;
          materialTypeList = response.results.MaterialType;
          otherSpecificList = response.results.OtherSpecific;

        }
        setContractTypeList(userMetierData.contractTypes ?? [])
        setContractTypeUserId(userMetierData.userId)
        setSelectedData(userMetierData ?? {});
        setInitialdata({
          experienceLevels: experienceLevelsList,
          generalEducation: generalEducationList,
          specificEducation: specificEducationList,
          workType: workTypeList,
          materialType: materialTypeList,
          otherSpecific: otherSpecificList,
        });
        setCandidatePersonalInformation((candidatePersonalInformation) => ({
          ...candidatePersonalInformation,
          numberOfChildren: userMetierData.numberOfChildren,
          availability: userMetierData.availability,
          maritalStatus: userMetierData.maritalStatus,
          driverLicense: userMetierData.driverLicense ?? '--',
          candidateNationalities: userMetierData.candidateNationalities ?? [],
        }));

        setCandidateLinks((candidateLinks) => ({
          ...candidateLinks,
          personalityTest: userMetierData.candidateLinks[0],
          portfolioLink: userMetierData.candidateLinks[1],
          linkedinLink: userMetierData.candidateLinks[2],
          presentationLink: userMetierData.candidateLinks[3],
        }));
        setShowNextSteps(true);
      });
    });
  };
  useEffect(() => {
    handleJobs();
  }, []);

  const onTextChanged = (event) => {
    if (!loading) {
      setLoading(true);
      const query = event.target.value;
      if (query === null) {
        setJobList([]);
        setIsOpen(false);
      } else {
        getMetiersSearch(query,"loan").then((result) => {
          if (result.total > 0) {
            const parsedRes = parseFromElasticSearchResults(result.results);
            setJobList(parsedRes);
            setIsOpen(true);
          }
          setLoading(false);
        });
      }
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          multiple={jobLimit != 1}
          onOpen={onTextChanged}
          onClose={() => setJobList([])}
          limitTags={jobLimit || 3}
          name="jobs"
          id="jobs"
          loading={loading}
          options={jobList}
          getOptionLabel={(option) => option.title}
          key={(option) => option.id}
          disabled
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={onTextChanged}
              variant="outlined"
              label={metierName}
              placeholder="Emploi"
            />
          )}
        />
        {showNextSteps && (
          <>
            <br/>
            <fieldset className={classes.fieldSetWrapper}>
            <legend className={classes.smallTitle}>
            Localisation                           
            </legend>
                  <FormGroup>
                     <Select
                      value={remote}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      <MenuItem value={0}>En présentiel</MenuItem>
                      <MenuItem value={1}>100% Télétravail </MenuItem>
                      <MenuItem value={2}>Télétravail partiel</MenuItem>
                    </Select>
                  </FormGroup>
            </fieldset>
          </>
        )}
        <>{showNextSteps && createCheckBoxes()}</>

        { user.type_of_user != 'Enterprise'  && viewProfile &&
        <fieldset className={classes.fieldSetWrapper}>
          <FormLabel component="legend" className={classes.smallTitle} style={{ justifyContent: 'space-around' }}>
            {"Informations de l'Entreprise"}
          </FormLabel>
          {enterpriseInformationList}
        </fieldset>
        }

        { user.type_of_user != 'Partner'  && matchPercentage >= 50 &&
          <fieldset className={classes.fieldSetWrapper}>
            <FormLabel component="legend" className={classes.smallTitle} style={{ justifyContent: 'space-around' }}>
            </FormLabel>
            {`Pourcentage de correspondance aux compétences: ${matchPercentage}%`}
          </fieldset>
        }
      </Grid>

    </>
  );
};

export default ViewPret;
