import React from 'react';
import RestrictedRoute from './RestrictedRoute';
import useSessionContext from './sessions/useSessionContext';

export default (props) => {
  const { user } = useSessionContext();
  return (
    <RestrictedRoute {...props} hasAccess={user} />
  );
};
