import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';
import {
  List, ListItem, Card, CardContent
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Nationalities from '../Nationality';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
  },
  fitRight: {
    marginRight: '5%',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
    },
  },
  typography: {
    margin: '16px 0 10px',
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
  },
  submitButton: {
    marginTop: '20px!important',
    height: '40px',
    '& span': {
      padding: '0 16px',
    },
  },
  submitButtonRow: {

    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
    },
  },
}));

const PersonalInformation = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { informError, informSuccess } = SnackBar;
  const [formData, setFormData] = useState({
    project: user.project,
    passportValid: user.passportValid,
    availability: user.availability || '',
  });
  const [driverLicense, setDriverLicense] = useState({
    moto: user.driverLicense.moto ?? false,
    auto: user.driverLicense.auto ?? false,
    camion: user.driverLicense.camion ?? false,
  });

  const countriesList = Nationalities;
  const userCountries = user.userNationalities
    ? countriesList.filter((country) =>
      user.userNationalities.includes(country.name),
    )
    : [];
  const [nationalities, setNationalities] = useState(userCountries);

  const onNationalitiesChange = (e, value) => {
    setNationalities(value);
  };

  const onDriverLicenseChange = ({ target }) => {
    const { name, checked } = target;
    setDriverLicense((driverLicense) => ({
      ...driverLicense,
      [name]: checked,
    }));
  };

  const onFormChange = ({ target }) => {
    const { name, value } = target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    
  };

  const handleSubmit = () => {
    const userNationalities = [];
    nationalities.map((nationality) => {
      userNationalities.push(nationality.name);
    });
    const raw = {
      user: {
        project: formData.project,
        passport_valid: formData.passportValid,
        availability: formData.availability,
        driver_license: driverLicense,
        nationalities_name: userNationalities,
      },
    };
    updateUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          refreshUserData();
          informSuccess('Succès!');
        } else {
          informError('Une erreur est survenue');
        }
      }
    });
  };

  const renderProjectList = [
    {
      name: 'Projet personnel',
      value: 'personal_project'
    },
    {
      name: 'Projet de couple',
      value: 'couple_project'
    },
    {
      name: 'Projet familial',
      value: 'family_project'
    }

  ].map((project) => (
    <MenuItem
      key={project.value}
      value={project.value}
      label={project.name}
    >
      {project.name}
    </MenuItem>
  ));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <List className={classes.root}>
        <ListItem divider>
          <Grid container className={classes.root}>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <CardHeader
                    title='Informations personnelles'
                    className={classes.profileTabTitle}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Projet
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <TextField
                    className={classes.fitRight}
                    id="project"
                    variant="outlined"
                    select
                    value={formData.project}
                    name="project"
                    onChange={onFormChange}
                  >
                    {renderProjectList}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Disponibilité (Date a laquelle vous seriez prêt a déménager /
                    immigrer)
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <TextField
                    className={classes.fitRight}
                    id="availability"
                    type="date"
                    variant="outlined"
                    value={formData.availability}
                    name="availability"
                    onChange={onFormChange}
                  />
                </Grid>



                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                  Votre passeport est-il encore valide + de 6 mois?
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <RadioGroup name="passportValid" value={formData.passportValid} onChange={onFormChange}>
                      <FormControlLabel checked={formData.passportValid} value="true" control={<Radio />} label="Oui" />
                      <FormControlLabel checked={!formData.passportValid && formData.passportValid!=null} value="false" control={<Radio />} label="Non" />
                    </RadioGroup>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Permis de conduire
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={driverLicense.moto}
                          onChange={onDriverLicenseChange}
                          name="moto"
                        />
                      }
                      label="Moto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={driverLicense.auto}
                          onChange={onDriverLicenseChange}
                          name="auto"
                        />
                      }
                      label="Auto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={driverLicense.camion}
                          onChange={onDriverLicenseChange}
                          name="camion"
                        />
                      }
                      label="Camion"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Nationalités
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <Autocomplete
                    className={classes.fitRight}
                    multiple
                    id="nationalities"
                    options={countriesList}
                    getOptionLabel={(option) => option.name}
                    value={nationalities}
                    onChange={onNationalitiesChange}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button size="small" className={classes.submitButton} onClick={handleSubmit}>
                    Enregistrer
                  </Button>
                </Grid>
                </CardContent>
            </Card>
          </Grid>
        </ListItem>
      </List>
    </Grid>

  );
};

export default PersonalInformation;
