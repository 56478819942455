import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Select, InputLabel, MenuItem, TextField, Box, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '16px',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "1.4em",
    textAlign: 'left'
  },
  pos: {
    marginBottom: 12,
  },
  compact: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  dialogTitle: {
    margin: "15px"
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  cardElement: {
    padding: theme.spacing(2),
    border: '1px solid #c4c4c4',
    borderRadius: theme.shape.borderRadius,
  },
  listItem: {
    border: '1px solid #bdbdbd',
    borderRadius: "20px",
    padding: 0
  },
  addressInfo: {
    border: '1px solid #bdbdbd',
    borderRadius: "20px",
    padding: "16px",
    fontWeight: 'normal!important'
  },
  btn: {
    width: 'fit-content',
    padding: '0px 16px',
    minWidth: '120px',
    height: '40px',
  },
  cancelBtn: {
    width: 'fit-content',
    padding: '0px 16px',
    minWidth: '120px',
    border: '2px solid #e5087e',
    color: '#e5087e',
    height: '40px',
    backgroundColor: "white",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const BillingInfo = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [countries, setCountries] = useState([]);
  const [billingInfo, setBillingInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const loadBillingInfo = () => {
    axios.get('/billing_info').then((response) => {
      setBillingInfo(response.data);
      setFormData(response.data);
      setLoading(false);
    }).catch(() => {
      setLoading(false); 
    });
  }

  useEffect(() => {
    loadBillingInfo();
    axios.get('/list_countries').then((response) => {
      setCountries(response.data);
    }).catch(() => {});
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      ['country']: e.target.value,
    });
  };

  

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Le nom est requis';
    if (!formData.country) errors.country = 'Le pays est requis';
    if (!formData.address_line1) errors.address_line1 = 'L\'adresse ligne 1 est requise';
    if (!formData.city) errors.city = 'La ville est requise';
    if (!formData.postal_code) errors.postal_code = 'Le code postal est requis';
    return errors;
  };

  const handleSave = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setLoadingDialog(true);
    const cardElement = elements.getElement(CardElement);
    if (cardElement._empty !== true) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: formData.name,
        },
      });
      await axios.post('/update_billing_info', {
        ...formData,
        payment_method_id: paymentMethod.id,
      });
      if (error) {
        setLoadingDialog(false);
        return;
      }
    }else{
      try {
        await axios.post('/update_billing_info', {
          ...formData,
        });
        
      } catch (error) {
        setLoadingDialog(false);
        return
      }
      
    }
    loadBillingInfo();
    setEditDialogOpen(false);
    setLoadingDialog(false);
  };


  return (
    <div className={classes.root}>
      <div className={classes.compact}>
        <Typography variant='h2' className={classes.title} color="textSecondary" gutterBottom>
          Moyens de paiement
        </Typography>
        {loading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <>
            <List className={classes.listItem}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <CreditCardIcon />
                  </Avatar>
                </ListItemAvatar>
                {billingInfo.last4!=null ? (
                <ListItemText primary={billingInfo.name} secondary={"#### #### #### " + billingInfo.last4} />
                ):
                (
                  <ListItemText primary={billingInfo.name} secondary={"Non renseigné"} />
                )}
              </ListItem>
            </List>
          </>
        )}
        <Typography variant='h2' className={classes.title} color="textSecondary" gutterBottom>
          Adresse de facturation
        </Typography>
        {loading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <Typography variant="body2" className={classes.addressInfo}>
            {billingInfo.address_line1} {billingInfo.address_line2 && `, ${billingInfo.address_line2}`}<br />
            {billingInfo.city}, {billingInfo.state} {billingInfo.postal_code}<br />
            {billingInfo.country}
          </Typography>
        )}

        <Button disabled={loading} className={classes.btn} onClick={() => setEditDialogOpen(true)}>
          Mettre à jour
        </Button>
      </div>
      <Dialog fullWidth maxWidth="md" open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle className={classes.dialogTitle}>Mettre à jour les informations de facturation</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant='h2' className={classes.title} color="textSecondary" gutterBottom>
            Carte de crédit
          </Typography>
          <Box className={classes.cardElement}>
            <CardElement options={{ hidePostalCode: true }} />
          </Box>
          <Typography variant='h2' className={classes.title} color="textSecondary" gutterBottom>
            Adresse
          </Typography>
          <TextField variant="outlined"
            label="Nom du titulaire de la carte"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.name}
            helperText={formErrors.name}
          />
          <FormControl variant="outlined" className={classes.formControl} error={!!formErrors.country}>
            <InputLabel id="country-outlined-label">Pays</InputLabel>
            <Select
              labelId="country-outlined-label"
              name="country"
              value={formData.country}
              onChange={handleSelectChange}
              label="Pays"
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.iso2}>{country.country}</MenuItem>
              ))}
            </Select>
            {formErrors.country && <Typography color="error">{formErrors.country}</Typography>}
          </FormControl>
          <TextField variant="outlined"
            label="Adresse ligne 1"
            name="address_line1"
            value={formData.address_line1}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.address_line1}
            helperText={formErrors.address_line1}
          />
          <TextField variant="outlined"
            label="Adresse ligne 2"
            name="address_line2"
            value={formData.address_line2}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField variant="outlined"
            label="Ville"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.city}
            helperText={formErrors.city}
          />
          <TextField variant="outlined"
            label="État/Région"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField variant="outlined"
            label="Code postal"
            name="postal_code"
            value={formData.postal_code}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.postal_code}
            helperText={formErrors.postal_code}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingDialog} className={classes.cancelBtn} onClick={() => setEditDialogOpen(false)} color="primary">
            Annuler
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              className={classes.btn}
              disabled={loadingDialog}
              onClick={handleSave}
            >
              Enregistrer
            </Button>
            {loadingDialog && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BillingInfo;
