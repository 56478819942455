import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator';
import JobsStep from './FormSteps/Shared/JobsStep';
import LoaderSpinner from '../UI-Component/LoaderSpinner';
import { apiUrl } from '../Functions/apiUrl';
import axios from 'axios';
import { errorHandling } from '../Functions/errorHandling';
import CloseIcon from '@material-ui/icons/Close';
import useSessionContext from '../sessions/useSessionContext';


const useStyles = makeStyles((theme) => ({
  homeContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0 0 0 .5px rgba(50,50,93,.1),0 2px 5px 0 rgba(50,50,93,.1),0 1px 1.5px 0 rgba(0,0,0,.07)',
    borderRadius: '7px',
    padding: '20px',
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const ReferenceCandidateForm = () => {
  const userType = "Referrer"
  const classes = useStyles();
  const [formErrors, setFormErrors] = useState({});
  const { user } = useSessionContext();
  
  const initialData = {
    firstName: user!=undefined ? user.first_name : '',
    lastName: user!=undefined  ? user.last_name : '',
    email: user!=undefined ? user.email : '',
    referralFirstName: null,
    referralLastName: null,
    referralEmail: null,
    jobs: null,
    showSpinner: false,
    byWorkType: false,
  }
  const [formData, setFormData] = useState(initialData);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    setFormData({
      firstName: user!=undefined ? user.first_name : '',
      lastName: user!=undefined  ? user.last_name : '',
      email: user!=undefined ? user.email : '',
      ...initialData
    })
  }, [user]);

  const addReferral = (data) => {
    const options = {
      url: `${apiUrl()}/add-candidate-reference`,
      method: 'POST',
      data: data,
    };

    return axios(options)
      .then((response) => response)
       .catch((error) => {
         return errorHandling(error);
       });
  };


  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = (verifyAll = false) => {
    const errors = {};

    if (formData.firstName || verifyAll) {
      if (!formData.firstName) {
        errors.firstName = 'Le prénom est requis.';
      } else if (!validator.isLength(formData.firstName, { min: 2, max: 15 })) {
        errors.firstName = 'Le prénom doit contenir entre 2 et 15 caractères.';
      }
    }

    if (formData.lastName || verifyAll) {
      if (!formData.lastName) {
        errors.lastName = 'Le nom de famille est requis.';
      } else if (!validator.isLength(formData.lastName, { min: 2, max: 15 })) {
        errors.lastName = 'Le nom de famille doit contenir entre 2 et 15 caractères.';
      }
    }

    if (formData.email || verifyAll) {
      if (!formData.email) {
        errors.email = 'L\'adresse courriel est requise.';
      } else if (!validator.isEmail(formData.email)) {
        errors.email = 'L\'adresse courriel n\'est pas valide.';
      } 
    }

    if (formData.referralFirstName || verifyAll) {
      if (!formData.referralFirstName) {
        errors.referralFirstName = 'Le prénom est requis.';
      } else if (!validator.isLength(formData.referralFirstName, { min: 2, max: 15 })) {
        errors.referralFirstName = 'Le prénom doit contenir entre 2 et 15 caractères.';
      }
    }

    if (formData.referralLastName || verifyAll) {
      if (!formData.referralLastName) {
        errors.referralLastName = 'Le nom de famille est requis.';
      } else if (!validator.isLength(formData.referralLastName, { min: 2, max: 15 })) {
        errors.referralLastName = 'Le nom de famille doit contenir entre 2 et 15 caractères.';
      }
    }

    if (formData.referralEmail || verifyAll) {
      if (!formData.referralEmail) {
        errors.referralEmail = 'L\'adresse courriel est requise.';
      } else if (!validator.isEmail(formData.referralEmail)) {
        errors.referralEmail = 'L\'adresse courriel n\'est pas valide.';
      }else if (formData.referralEmail === formData.email) {
        errors.referralEmail = 'Veuillez renseignez une autre adresse courriel';
      }
    }

    if (formData.jobs || verifyAll) {
      if (formData.jobs === null) {
        errors.jobs = 'Vous devez renseigner l\'emploi du candidat';
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value,
      },
      validateForm(),
    );
  };

  const handleSubmit = () => {
    if (!validateForm(true)) {
      console.error('Unable to validate form', formErrors);
      return;
    }

    const data = {
        referrer_email: formData.email,
        referrer_first_name: formData.firstName,
        referrer_last_name: formData.lastName,
        referred_email: formData.referralEmail,
        referred_first_name: formData.referralFirstName,
        referred_last_name: formData.referralLastName,
        metier_id: formData.jobs.id,
    };

    setFormData({
      ...formData,
      showSpinner: true,
    });

    addReferral(data)
      .then((response) => {
        if (response != null && response != undefined) {
          if (response.status === 400) {
            if (response.data.error === 'Exist' || response.data.error === 'Have account') {
              setFormErrors({
                referralEmail: response.data.message,
                verifyAll: true,
              });
            }
          }
          if (response.status === 200) {
            setOpen(true);
            setFormData({
              firstName: user!=undefined ? user.first_name : '',
              lastName: user!=undefined  ? user.last_name : '',
              email: user!=undefined ? user.email : '',
              referralFirstName: null,
              referralLastName: null,
              referralEmail: null,
              jobs: null,
              showSpinner: false,
              byWorkType: false,
            });
            return
          }
        }
        setFormData({
          ...formData,
          showSpinner: false,
        });
      });
  };


  return (
      

    <>
      {formData.showSpinner && (
        <LoaderSpinner sizeOfSpinner={150} text="Validation en cours"/>
      )}
      <Container maxWidth="sm" className={classes.homeContainer}>
        <Box className={classes.bgHero}>
          <form className={classes.form}>
              <Typography variant="h2" color='secondary'>Formulaire de parrainage</Typography>

              <Grid container spacing={2} className={classes.containerForm}>
                {user == undefined && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h2" className={classes.smallTitle}>
                        Vos informations
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.firstName}
                        required
                        fullWidth
                        id="firstName"
                        value={formData.firstName || ''}
                        label="Prénom"
                        autoComplete="given-name"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        name="firstName"
                      />
                      {formErrors.firstName && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.firstName}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.lastName}
                        required
                        fullWidth
                        value={formData.lastName || ''}
                        label="Nom de famille"
                        name="lastName"
                        autoComplete="family-name"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                      />
                      {formErrors.lastName && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.lastName}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        error={!!formErrors.email}
                        required
                        fullWidth
                        value={formData.email || ''}
                        label="Courriel"
                        name="email"
                        onChange={(event) => onFormChange(event)}
                        onBlur={(event) => onFormChange(event)}
                        autoComplete="email"
                      />
                      {formErrors.email && (
                        <p className={`${classes.error} ${'error'}`}>
                          {formErrors.email}
                        </p>
                      )}
                    </Grid>
                  </>
                )}
                  
                  <Grid item xs={12}>
                    <Typography variant="h2" className={classes.smallTitle}>
                      Informations sur le candidat
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.referralFirstName}
                      required
                      fullWidth
                      id="referralFirstName"
                      value={formData.referralFirstName || ''}
                      label="Prénom"
                      autoComplete="given-name"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                      name="referralFirstName"
                    />
                    {formErrors.referralFirstName && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.referralFirstName}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.referralLastName}
                      required
                      fullWidth
                      value={formData.referralLastName || ''}
                      label="Nom de famille"
                      name="referralLastName"
                      autoComplete="family-name"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                    />
                    {formErrors.referralLastName && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.referralLastName}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.referralEmail}
                      required
                      fullWidth
                      value={formData.referralEmail || ''}
                      label="Courriel"
                      name="referralEmail"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                      autoComplete="referralEmail"
                    />
                    {formErrors.referralEmail && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.referralEmail}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                      <JobsStep
                      userType={userType}
                      errors={formErrors}
                      setErrors={setFormErrors}
                      formData={formData}
                      onFormChange={onFormChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleSubmit}
                      disabled={false}
                      className={classes.submit}
                    >
                      Envoyer
                    </Button>
                  </Grid>
                  
            </Grid>
          </form>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '18px 24px',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Typography variant='h3' color='secondary' style={{textAlign:'center',margin:'5px 0px'}}>
              Félicitation !
            </Typography>
            <Typography gutterBottom style={{textAlign:'center'}}>
                Les informations sur le candidat ont bien été enregistré !
            </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReferenceCandidateForm;
