import axios from 'axios';
import { apiUrl } from '../apiUrl';

const changeMetier = async (metierId, newMetierId) => {
    const options = {
        url: `${apiUrl()}/change_user_metier`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
          },       
          data: JSON.stringify({
            metier_id: metierId,
            new_metier_id: newMetierId,           
        }),
    }; 
    await axios(options)  
}

export default changeMetier; 