import React, { useEffect, useState } from 'react';
import {
  Fab, Grid, makeStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../components/theme'
import useSessionContext from '../sessions/useSessionContext';
import { getUserMetier } from '../Functions/Get-query/getUserMetier';
import { getJobRequirements } from '../Functions/Get-query/getJobRequirements';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
        height: '80%',
      },
    },
  },

}));

const ViewCv = (props) => {
  const {
    show, closeModal, cvData
  } = props;


  const { user } = useSessionContext();


  const [open, setOpen] = React.useState(false);
  const [userCvData, setUserCVData] = useState(null);

  const handleClose = () => {
    closeModal(true);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);


  useEffect(()=>{
    handleCv()
  },[])

  useEffect(()=>{
  },[userCvData])

  const handleCv = () => {
    getUserMetier(cvData.userMetierId).then((userMetierData) => {
      setUserCVData(userMetierData.userCV)
    });
  };


  return (
    <div>
      <Dialog
        className={classes.root}
        open={show ? open === false : open === true}
        onClose={handleClose}
        aria-labelledby="Curriculum"
        id="openCVModal"
        fullScreen
      >
        <Grid container>
          <DialogContent>
              <iframe src={userCvData} width="100%" height="600px" > </iframe>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default ViewCv;
