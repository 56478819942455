import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Nav from './Nav';
import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: theme.spacing(2),
  },

  menuMoove: {
    height: '100px',
    display: 'inline',
    margin: '0px',
    padding: '0px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '100px',
      maxWidth: '100%',
    },
  },
  root:{
    '& .MuiAppBar-root':{
      padding: '0px!important'
    }
  },
  nav:{
    height: '100px'
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="top">
      <AppBar position="fixed">
        <Toolbar className={classes.menuMoove}>
          <Logo/>
          <Nav className={classes.nav}/>
        </Toolbar>
      </AppBar>
    </div>
  );
}
