import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CardHeader,
    Link,
    Typography,
    Grid,
    Card,
    Fab,
    CardContent
} from '@material-ui/core';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    rootCard: {
        width: '100%',
    },
    profileTabTitle: {
        '& span:first-child': {
          fontWeight: 600,
          fontSize: '1.25rem',
          color: '#E5087E',
        },
        '& span:last-child': {
          fontWeight: 500,
          fontSize: '1.05rem',
        },
      },
    input: {
        display: 'none',
      },
}));

const CVProfile = (props) => {
    const classes = useStyles(theme);
    const { SnackBar, refreshUserData, updateUser } = useSessionContext();
    const { profileInfo, setProfileInfo } = props;
    const { informSuccess, informError } = SnackBar;

    const onFormChange = (event) => {
        setProfileInfo({
            ...profileInfo,
            [event.target.name]: event.target.value,
        });
    };

    const fileUpload = ({ target }) => {
        if (target.files.length > 0) {
            const { type } = target.files[0];
            if (type != 'application/pdf') {
                informError('Veuillez utiliser un document de type PDF');
            } else {
                onFormChange({ target: { name: 'linkedCv', value: target.files[0] } });
                const raw = {
                    user: {
                        cv_file: target.files[0],
                    },
                };
                updateUser(raw).then((response) => {
                    if (response.status === 400) {
                        if (response.data.error) {
                            setFormErrors({
                                ...errors,
                                verifyAll: true,
                            });
                            informError(errors);
                        }
                    }
                    if (response.status === 200) {
                        refreshUserData();
                        informSuccess('CV mis à jour avec succès');
                    }
                });
            }
        }
    };

    return (
        <>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <CardHeader
                            title="CV"
                            subheader="Déposez votre CV et incluez une lettre de motivation"
                            className={classes.profileTabTitle}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px 24px',
                        }}
                    >
                        <input
                            accept=".pdf"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={fileUpload}
                        />
                        <label htmlFor="contained-button-file">
                            <Fab
                                color="secondary"
                                aria-label="add"
                                component="span"
                            >
                                {profileInfo.cvFilename ? <EditIcon /> : <AddIcon />}
                            </Fab>
                        </label>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {profileInfo.cvFilename && (
                        <CardContent>
                            <Typography>
                                <Link href={profileInfo.linkedCv} target="blank">
                                {profileInfo.cvFilename}
                                </Link>
                            </Typography>
                        </CardContent>
                        
                    )}
                </Grid>
            </Card>


        </>
    );
};

export default CVProfile;
