import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import bgHero from '../../images/connection_background.jpg';
import theme from '../theme';
import Seo from '../UI-Component/Seo';
import logoMoovejob from '../../images/Logo-Moovejob.png';
import ConnexionBox from '../UI-Component/ConnexionBox';

const useStyles = makeStyles((thm) => ({
  homeContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      textAlign: 'center',
    },
  },
  bgHero: {
    position: 'relative',
    backgroundImage: `url(${bgHero})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    backgroundPosition: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255,255,255, 0.7)',
    },
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
  },
  logoStyle: {
    maxWidth: '200px',
    margin: '0 auto',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: thm.spacing(4),
  },
  submit: {
    margin: thm.spacing(3, 0, 3),
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  tabContainer: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },
  forgotPasswordBtn: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#37383e',
    cursor: 'pointer',
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
}));

export default function Connexion() {
  const classes = useStyles(theme);

  return (
    <main>
      <Seo name="robots" robot="noindex"/>
      <div className={classes.bgHero}>
        <Container maxWidth="md" className={classes.homeContainer}>
          <Box>
            <Card square>
              <CardContent>
                <Typography>
                  Félicitations! Votre compte à été confirmé avec succès. Vous
                  pouvez désormais vous connecter!
                </Typography>
              </CardContent>
              <CardContent>
                <ConnexionBox />
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    </main>
  );
}
