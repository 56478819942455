import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import theme from '../../../theme';
import ServiceModal from '../FormModal/serviceModal';
import FormConfirmServiceDelete from '../../ServicesConfimationBox';
import useSessionContext from '../../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  serviceTitle:{
    '& span': {
      fontSize: '1.1rem',
    },
  },
  contactBtn: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#e5087e',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    margin: 0,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const Services = (props) => {
  const { user, refreshUserData } = useSessionContext();
  const [show, setShow] = useState(false);
  const openModalAddService = () => setShow(true);
  const closeModalAddService = () => setShow(false);
  const classes = useStyles(theme);
  const [userServices, setUserServices] = useState([]);

  useEffect(() => {
    setUserServices(user.user_services);
  }, [user]);

  return (
    <Grid container>
      <Card square className={classes.rootCard} elevation={0} variant="outlined">
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <CardHeader
              title={user.type_of_user=='Partner' ? 'Vos services' : "Service(s) offert(s) par le partenaire" }
              className={classes.profileTabTitle}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px 0',
            }}
          >
            <Fab
              id="addService"
              color="secondary"
              aria-label="add"
              onClick={() => {
                refreshUserData();
                openModalAddService();
              }}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.rootCard} square variant="outlined">
        {userServices.map((service, ind) => (
          <Grid key={ind} alignItems="center" container>
            <Grid item xs={10}>
              <CardHeader title={service.name}  className={classes.serviceTitle}/>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FormConfirmServiceDelete service={service} />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <CardContent>
            <Button
              className={classes.contactBtn}
              href="mailto:communication@moovejob.com"
            >
              <Typography>Suggérer de nouveaux services</Typography>
            </Button>
          </CardContent>
        </Grid>
      </Card>
      <ServiceModal
        closeModal={closeModalAddService}
        show={show}
        userServices={userServices}
      />
    </Grid>
  );
};
export default Services;
