import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { apiUrl } from '../Functions/apiUrl';

const SEO = ({
  title,
  description,
  url,
  fbTitle,
  fbDescription,
  fbImage,
  authorName,
  twitterTitle,
  twitterDescription,
  twitterImage,
  robot,
  canonicalUrl,
}) => (
  <Helmet
    title={`moovejob - Recrutement international - ${title}`}
    htmlAttributes={{ lang: 'fr' }}
    meta={[
      {
        content: description,
        name: 'description',
      },
      {
        content: url,
        property: 'og:url',
      },
      {
        content: 'website',
        property: 'og:type',
      },
      {
        content: fbTitle,
        property: 'og:title',
      },
      {
        content: fbDescription,
        property: 'og:description',
      },
      {
        content: fbImage,
        property: 'og:image',
      },
      {
        content: 'moovejob - Recrutement international',
        property: 'og:site_name',
      },
      {
        content: 'summary',
        property: 'twitter:card',
      },
      {
        content: authorName,
        property: 'twitter:creator',
      },
      {
        content: twitterTitle,
        property: 'twitter:title',
      },
      {
        content: twitterDescription,
        property: 'twitter:description',
      },
      {
        content: twitterImage,
        property: 'twitter:image',
      },
      {
        content: robot,
        property: 'robots',
      },
    ]}
    link={[
      {
        href: canonicalUrl,
        rel: 'canonical',
      },
    ]}
  />
);

SEO.propTypes = {
  authorName: PropTypes.string,
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  fbDescription: PropTypes.string,
  fbImage: PropTypes.string,
  fbTitle: PropTypes.string,
  robot: PropTypes.string,
  title: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.string,
  twitterTitle: PropTypes.string,
  url: PropTypes.string,
};

SEO.defaultProps = {
  authorName: 'L\'équipe de moovejob',
  canonicalUrl: 'https://www.moovejob.com/',
  description:
    'moovejob applique les différents principes en accord avec les bonnes pratiques mondiales en matière de protection de la vie privée et des documents électroniques de nos clients.',
  fbDescription:
    'moovejob applique les différents principes en accord avec les bonnes pratiques mondiales en matière de protection de la vie privée et des documents électroniques de nos clients.',
  fbImage: `${apiUrl()}"/logoMoovejob.png"`,
  fbTitle: 'moovejob - Recrutement international',
  robot: 'index',
  title: 'moovejob - Recrutement international',
  twitterDescription:
    'moovejob applique les différents principes en accord avec les bonnes pratiques mondiales en matière de protection de la vie privée et des documents électroniques de nos clients.',
  twitterImage: `${apiUrl()}"/logoMoovejob.png"`,
  twitterTitle: 'moovejob - Recrutement international',
  url: `${apiUrl()}`,
};

export default SEO;
