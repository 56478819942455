const Nationalities = [
  {
    "id": "1","name": "Afghane"
  },
  {
    "id": "2","name": "Albanaise"
  },
  {
    "id": "3","name": "Antarctique"
  },
  {
    "id": "4","name": "Algérienne"
  },
  {
    "id": "5","name": "Samoa Américaines"
  },
  {
    "id": "6","name": "Andorrane"
  },
  {
    "id": "7","name": "Angolaise"
  },
  {
    "id": "8","name": "Antiguaise et barbudienne"
  },
  {
    "id": "9","name": "Azerbaïdjanaise"
  },
  {
    "id": "10","name": "Argentine"
  },
  {
    "id": "11","name": "Australienne"
  },
  {
    "id": "12","name": "Autrichienne"
  },
  {
    "id": "13","name": "Bahamienne"
  },
  {
    "id": "14","name": "Bahreinienne"
  },
  {
    "id": "15","name": "Bangladaise"
  },
  {
    "id": "16","name": "Arménienne"
  },
  {
    "id": "17","name": "Barbadienne"
  },
  {
    "id": "18","name": "Belge"
  },
  {
    "id": "19","name": "Bermudes"
  },
  {
    "id": "20","name": "Bhoutanaise"
  },
  {
    "id": "21","name": "Bolivienne"
  },
  {
    "id": "22","name": "Bosnienne"
  },
  {
    "id": "23","name": "Botswanaise"
  },
  {
    "id": "24","name": "Île Bouvet"
  },
  {
    "id": "25","name": "Brésilienne"
  },
  {
    "id": "26","name": "Bélizienne"
  },
  {
    "id": "27","name": "Territoire Britannique de l'Océan Indien"
  },
  {
    "id": "28","name": "Îles Salomon"
  },
  {
    "id": "29","name": "Îles Vierges Britanniques"
  },
  {
    "id": "30","name": "Brunéi Darussalam"
  },
  {
    "id": "31","name": "Bulgare"
  },
  {
    "id": "32","name": "Birmane"
  },
  {
    "id": "33","name": "Burundaise"
  },
  {
    "id": "34","name": "Biélorusse"
  },
  {
    "id": "35","name": "Cambodgienne"
  },
  {
    "id": "36","name": "Camerounaise"
  },
  {
    "id": "37","name": "Canadienne"
  },
  {
    "id": "38","name": "Cap-verdienne"
  },
  {
    "id": "39","name": "Îles Caïmanes"
  },
  {
    "id": "40","name": "Centrafricaine"
  },
  {
    "id": "41","name": "Sri-lankaise"
  },
  {
    "id": "42","name": "Tchadienne"
  },
  {
    "id": "43","name": "Chilienne"
  },
  {
    "id": "44","name": "Chinoise"
  },
  {
    "id": "45","name": "Taiwanaise"
  },
  {
    "id": "46","name": "Île Christmas"
  },
  {
    "id": "47","name": "Îles Cocos (Keeling)"
  },
  {
    "id": "48","name": "Colombienne"
  },
  {
    "id": "49","name": "Comorienne"
  },
  {
    "id": "50","name": "Mayotte"
  },
  {
    "id": "51","name": "Congolaise"
  },
  {
    "id": "52","name": "Congolaise (Kinshasa)"
  },
  {
    "id": "53","name": "Îles Cook"
  },
  {
    "id": "54","name": "Costaricaine"
  },
  {
    "id": "55","name": "Croate"
  },
  {
    "id": "56","name": "Cubaine"
  },
  {
    "id": "57","name": "Chypriote"
  },
  {
    "id": "58","name": "Tchèque"
  },
  {
    "id": "59","name": "Béninoise"
  },
  {
    "id": "60","name": "Danoise"
  },
  {
    "id": "61","name": "Dominiquaise"
  },
  {
    "id": "62","name": "Dominicaine"
  },
  {
    "id": "63","name": "Equatorienne"
  },
  {
    "id": "64","name": "Salvadorienne"
  },
  {
    "id": "65","name": "Equato-guinéenne"
  },
  {
    "id": "66","name": "Ethiopienne"
  },
  {
    "id": "67","name": "Erythréenne"
  },
  {
    "id": "68","name": "Estonienne"
  },
  {
    "id": "69","name": "Îles Féroé"
  },
  {
    "id": "70","name": "Îles (malvinas) Falkland"
  },
  {
    "id": "71","name": "Géorgie du Sud et les Îles Sandwich du Sud"
  },
  {
    "id": "72","name": "Fidjienne"
  },
  {
    "id": "73","name": "Finlandaise"
  },
  {
    "id": "74","name": "Îles Åland"
  },
  {
    "id": "75","name": "Française"
  },
  {
    "id": "76","name": "Guyanienne"
  },
  {
    "id": "77","name": "Polynésie Française"
  },
  {
    "id": "78","name": "Terres Australes Françaises"
  },
  {
    "id": "79","name": "Djiboutienne"
  },
  {
    "id": "80","name": "Gabonaise"
  },
  {
    "id": "81","name": "Georgienne"
  },
  {
    "id": "82","name": "Gambienne"
  },
  {
    "id": "83","name": "Palestinienne"
  },
  {
    "id": "84","name": "Allemande"
  },
  {
    "id": "85","name": "Ghana"
  },
  {
    "id": "86","name": "Gibraltar"
  },
  {
    "id": "87","name": "Kiribatienne"
  },
  {
    "id": "88","name": "Hellénique"
  },
  {
    "id": "89","name": "Groenland"
  },
  {
    "id": "90","name": "Grenadienne"
  },
  {
    "id": "91","name": "Guadeloupe"
  },
  {
    "id": "92","name": "Guam"
  },
  {
    "id": "93","name": "Guatemaltèque"
  },
  {
    "id": "94","name": "Guinéenne"
  },
  {
    "id": "95","name": "Guyanaise (Guyana)"
  },
  {
    "id": "96","name": "Haïtienne"
  },
  {
    "id": "97","name": "Îles Heard et Mcdonald"
  },
  {
    "id": "98","name": "Saint-Siège (état de la Cité du Vatican)"
  },
  {
    "id": "99","name": "Hondurienne"
  },
  {
    "id": "100","name": "Hong-Kong"
  },
  {
    "id": "101","name": "Hongroise"
  },
  {
    "id": "102","name": "Islandaise"
  },
  {
    "id": "103","name": "Indienne"
  },
  {
    "id": "104","name": "Indonésienne"
  },
  {
    "id": "105","name": "Iranienne"
  },
  {
    "id": "106","name": "Irakienne"
  },
  {
    "id": "107","name": "Irlandaise"
  },
  {
    "id": "108","name": "Israélienne"
  },
  {
    "id": "109","name": "Italienne"
  },
  {
    "id": "110","name": "Ivoirienne"
  },
  {
    "id": "111","name": "Jamaïcaine"
  },
  {
    "id": "112","name": "Japonaise"
  },
  {
    "id": "113","name": "Kazakhstanaise"
  },
  {
    "id": "114","name": "Jordanienne"
  },
  {
    "id": "115","name": "Kenyane"
  },
  {
    "id": "116","name": "Nord-coréenne"
  },
  {
    "id": "117","name": "Sud-coréenne"
  },
  {
    "id": "118","name": "Koweitienne"
  },
  {
    "id": "119","name": "Kirghize"
  },
  {
    "id": "120","name": "Laotienne"
  },
  {
    "id": "121","name": "Libanaise"
  },
  {
    "id": "122","name": "Lesothane"
  },
  {
    "id": "123","name": "Lettone"
  },
  {
    "id": "124","name": "Libérienne"
  },
  {
    "id": "125","name": "Libyenne"
  },
  {
    "id": "126","name": "Liechtensteinoise"
  },
  {
    "id": "127","name": "Lituanienne"
  },
  {
    "id": "128","name": "Luxembourgeoise"
  },
  {
    "id": "129","name": "Macao"
  },
  {
    "id": "130","name": "Malgache"
  },
  {
    "id": "131","name": "Malawienne"
  },
  {
    "id": "132","name": "Malaisienne"
  },
  {
    "id": "133","name": "Maldivienne"
  },
  {
    "id": "134","name": "Malienne"
  },
  {
    "id": "135","name": "Maltaise"
  },
  {
    "id": "136","name": "Martinique"
  },
  {
    "id": "137","name": "Mauritanienne"
  },
  {
    "id": "138","name": "Mauricienne"
  },
  {
    "id": "139","name": "Mexicaine"
  },
  {
    "id": "140","name": "Monégasque"
  },
  {
    "id": "141","name": "Mongole"
  },
  {
    "id": "142","name": "Moldave"
  },
  {
    "id": "143","name": "Montserrat"
  },
  {
    "id": "144","name": "Marocaine"
  },
  {
    "id": "145","name": "Mozambicaine"
  },
  {
    "id": "146","name": "Omanaise"
  },
  {
    "id": "147","name": "Namibienne"
  },
  {
    "id": "148","name": "Nauruane"
  },
  {
    "id": "149","name": "Népalaise"
  },
  {
    "id": "150","name": "Néerlandaise"
  },
  {
    "id": "151","name": "Antilles Néerlandaises"
  },
  {
    "id": "152","name": "Aruba"
  },
  {
    "id": "153","name": "Nouvelle-Calédonie"
  },
  {
    "id": "154","name": "Vanuatuane"
  },
  {
    "id": "155","name": "Neo-zélandaise"
  },
  {
    "id": "156","name": "Nicaraguayenne"
  },
  {
    "id": "157","name": "Nigérienne"
  },
  {
    "id": "158","name": "Nigériane"
  },
  {
    "id": "159","name": "Niué"
  },
  {
    "id": "160","name": "Île Norfolk"
  },
  {
    "id": "161","name": "Norvégienne"
  },
  {
    "id": "162","name": "Îles Mariannes du Nord"
  },
  {
    "id": "163","name": "Îles Mineures Éloignées des États-Unis"
  },
  {
    "id": "164","name": "Micronésienne"
  },
  {
    "id": "165","name": "Marshallaise"
  },
  {
    "id": "166","name": "Palau"
  },
  {
    "id": "167","name": "Pakistanaise"
  },
  {
    "id": "168","name": "Panaméenne"
  },
  {
    "id": "169","name": "Papouane-neoguinéenne"
  },
  {
    "id": "170","name": "Paraguayenne"
  },
  {
    "id": "171","name": "Péruvienne"
  },
  {
    "id": "172","name": "Philippine"
  },
  {
    "id": "173","name": "Pitcairn"
  },
  {
    "id": "174","name": "Polonaise"
  },
  {
    "id": "175","name": "Portugaise"
  },
  {
    "id": "176","name": "Bissau-Guinéenne"
  },
  {
    "id": "177","name": "Est-timoraise"
  },
  {
    "id": "178","name": "Portoricaine"
  },
  {
    "id": "179","name": "Qatarienne"
  },
  {
    "id": "180","name": "Réunion"
  },
  {
    "id": "181","name": "Roumaine"
  },
  {
    "id": "182","name": "Russe"
  },
  {
    "id": "183","name": "Rwandaise"
  },
  {
    "id": "184","name": "Sainte-Hélène"
  },
  {
    "id": "185","name": "Kittitienne-et-névicienne"
  },
  {
    "id": "186","name": "Anguilla"
  },
  {
    "id": "187","name": "Saint-Lucienne"
  },
  {
    "id": "188","name": "Saint-Pierre-et-Miquelon"
  },
  {
    "id": "189","name": "Saint-Vincentaise-et-Grenadine"
  },
  {
    "id": "190","name": "Saint-Marinaise"
  },
  {
    "id": "191","name": "Santoméenne"
  },
  {
    "id": "192","name": "Saoudienne"
  },
  {
    "id": "193","name": "Sénégalaise"
  },
  {
    "id": "194","name": "Seychelloise"
  },
  {
    "id": "195","name": "Sierra-leonaise"
  },
  {
    "id": "196","name": "Singapourienne"
  },
  {
    "id": "197","name": "Slovaque"
  },
  {
    "id": "198","name": "Vietnamienne"
  },
  {
    "id": "199","name": "Slovène"
  },
  {
    "id": "200","name": "Somalienne"
  },
  {
    "id": "201","name": "Sud-africaine"
  },
  {
    "id": "202","name": "Zimbabwéenne"
  },
  {
    "id": "203","name": "Espagnole"
  },
  {
    "id": "204","name": "Sahara Occidental"
  },
  {
    "id": "205","name": "Soudanaise"
  },
  {
    "id": "206","name": "Surinamaise"
  },
  {
    "id": "207","name": "Svalbard et Île Jan Mayen"
  },
  {
    "id": "208","name": "Swaziland"
  },
  {
    "id": "209","name": "Suédoise"
  },
  {
    "id": "210","name": "Suisse"
  },
  {
    "id": "211","name": "Syrienne"
  },
  {
    "id": "212","name": "Tadjike"
  },
  {
    "id": "213","name": "Thaïlandaise"
  },
  {
    "id": "214","name": "Togolaise"
  },
  {
    "id": "215","name": "Tokelau"
  },
  {
    "id": "216","name": "Tongienne"
  },
  {
    "id": "217","name": "Trinidadienne"
  },
  {
    "id": "218","name": "Emirienne"
  },
  {
    "id": "219","name": "Tunisienne"
  },
  {
    "id": "220","name": "Turque"
  },
  {
    "id": "221","name": "Turkmène"
  },
  {
    "id": "222","name": "Îles Turks et Caïques"
  },
  {
    "id": "223","name": "Tuvaluane"
  },
  {
    "id": "224","name": "Ougandaise"
  },
  {
    "id": "225","name": "Ukrainienne"
  },
  {
    "id": "226","name": "Macédonienne"
  },
  {
    "id": "227","name": "Egyptienne"
  },
  {
    "id": "228","name": "Britannique"
  },
  {
    "id": "229","name": "Île de Man"
  },
  {
    "id": "230","name": "Tanzanienne"
  },
  {
    "id": "231","name": "Américaine"
  },
  {
    "id": "232","name": "Îles Vierges des États-Unis"
  },
  {
    "id": "233","name": "Burkinabé"
  },
  {
    "id": "234","name": "Uruguayenne"
  },
  {
    "id": "235","name": "Ouzbeke"
  },
  {
    "id": "236","name": "Vénézuélienne"
  },
  {
    "id": "237","name": "Wallis et Futuna"
  },
  {
    "id": "238","name": "Samoane"
  },
  {
    "id": "239","name": "Yéménite"
  },
  {
    "id": "240","name": "Monténégrine"
  },
  {
    "id": "241","name": "Zambienne"
  }
]

export default Nationalities;
