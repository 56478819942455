import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  FormLabel,
  Link
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../theme';
import JobModal from './FormModal/JobModal';
import useSessionContext from '../../sessions/useSessionContext';
import FormConfirmMetierDelete from '../MetierConfirmationBox';
import MetierActivator from '../MetierActivator';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  profileMessageTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#080000',
    },
  },
  jobListHeader: {
    borderBottom: '1px solid #ebebeb',
  },
  jobListContainer: {
    borderBottom: '1px solid #ebebeb',
    position: 'relative',
  },
  jobCardActionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 25px',
  },
  jobCardActionsViewWrapper: {
    marginRight: 16,
  },
  ButtonModalStyle: {
    color: '#ffffff',
    backgroundColor: '#E5087E',
    width: 38,
    height: 38,
    padding: 10,
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    '&:focus, &.focus': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  disabledItem: {
    opacity: 0.3,
  },
  incompleteText: {
    paddingLeft: '15px',
    paddingTop: '-10px',
    color: 'red',
    fontSize: '12pt',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '15px',
      paddingTop: '10px',
      color: 'red',
      fontSize: '12pt',
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      paddingLeft: '15px',
      paddingTop: '0',
      color: 'red',
      fontSize: '11pt',
    },
  },
  cardTitle :{
    '& span': {
      fontSize: '1em',
    },
  }
}));

const PretPersonnel = (props) => {
  const { user, refreshUserData, updateUser } = useSessionContext();
  const [pretActive, setPretActive] = useState(false);
  const [userMetiers, setUserMetiers] = useState([]);
  const [metierData, setMetierData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => setShowEditModal(false);

  const [showViewMetierModal, setShowViewMetierModal] = useState(false);
  const openViewMetierModal = () => setShowViewMetierModal(true);
  const closeViewEditModal = () => setShowViewMetierModal(false);
  const { isSubscribed, subscribedTo } = props;
  const [disableItem, setDisableItem] = useState(true);
  const [activeArray, setActiveArray] = useState([]);
  const [inactiveArray, setInactiveArray] = useState([]);

  const [statusPret, setStatusPret] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleStatusChange = (event) => {
    setLoading(true);
    setStatusPret(event.target.value);
    
    const raw = {
      user: {
        enterprise_info:{
          seeking_loan : event.target.value=="seeking_loan",
          providing_loan : event.target.value=="providing_loan",
        }
      },
    };
    updateUser(raw)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.status === 400 || response.status === 401) {
            if (response.data.error) {
              setFormErrors({
                ...errors,
                verifyAll: true,
              });
            }
          }
          if (response.status === 200) {
            setPretActive(event.target.value=='not' ? false : true);
            refreshUserData();
          }
        }
        setLoading(false);
    });
    
  };

  useEffect(() => {
    if(user.type_of_user=='Enterprise' && (user.enterprise_info.seeking_loan || user.enterprise_info.providing_loan)){
        setPretActive(true);
        setStatusPret(user.enterprise_info.seeking_loan ? "seeking_loan" : "providing_loan")
    }else{
      setPretActive(false);
      setStatusPret("not")
    }
    setUserMetiers(user.pret_metiers);
  }, []);

  useEffect(() => {
    metierActiveList(user.pret_metiers);
  }, []);

  const [show, setShow] = useState(false);

  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const classes = useStyles(theme);

  const handleOpenViewMetierModal = (metierId, userMetierId, metierName) => {
    setMetierData({
      metierId,
      userMetierId,
      metierName,
    });
    openViewMetierModal();
  };

  const handleOpenEditModal = (metierId, userMetierId, metierName) => {
    setMetierData({
      metierId,
      userMetierId,
      metierName,
    });
    openEditModal();
  };

  const showIncomplete = (metierId) => {
    const allUserMetiers = user.pret_metiers;
    let countSelected = 0;

    allUserMetiers.forEach((mt) => {
      if (metierId == mt.metier_id && metierId !== null) {
        countSelected += mt.material_type.length;
      }
    });
    allUserMetiers.forEach((wt) => {
      if (metierId == wt.metier_id && metierId !== null) {
        countSelected += wt.work_type.length;
      }
    });
    allUserMetiers.forEach((os) => {
      if (metierId == os.metier_id && metierId !== null) {
        countSelected += os.other_specific.length;
      }
    });
    if (user.type_of_user === 'Enterprise') {
      if (countSelected < 3) {
        return (
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
            }}
          >
            <Typography className={classes.incompleteText}>
              *Fiche métier incomplète*
            </Typography>
          </div>
        );
      }
      return null;
    }
    return null;
  };

  const disableMetierModal = () => {
    const userType = user.type_of_user;
    const metierList = user.pret_metiers;
    let numberOfUserMetier = null;

    if (!isSubscribed) {
      numberOfUserMetier = 5;
    } else if (
      subscribedTo == 'Relève' ||
      subscribedTo == 'Veille & Stratégie'
    ) {
      numberOfUserMetier = 10;
    } else {
      numberOfUserMetier = 5;
    }

    if (numberOfUserMetier <= metierList.length) {
      return (
        <Grid container alignItems="center">
          <Grid item xs={8} style={{ padding: '16px' }}>
            <Tooltip title="Vous avez atteint le maximum de métier permis !">
              <span>
                <Fab id="addJob" disabled color="secondary" aria-label="add">
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
            <Grid item xs={12}>
              <Typography className={classes.profileMessageTitle}>
                Vous avez atteint le maximum de métiers
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={8}>
          <CardHeader
            title="Mes métiers"
            className={classes.profileTabTitle}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 24px',
          }}
        >
          <Fab
            id="addJob"
            color="secondary"
            aria-label="add"
            onClick={() =>{
              refreshUserData();
              openModal();
            }}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </>
    );
  };

  const metierActiveList = (metierData) => {
    const activeArr2 = [];
    const inactiveArr2 = [];
    metierData.forEach((metier) => {
      if (metier.is_active === true) {
        activeArr2.push(metier);
      }

      if (metier.is_active === false) {
        inactiveArr2.push(metier);
      }
    });
    setActiveArray(activeArr2);
    setInactiveArray(inactiveArr2);
  };

  const renderButtons = (metier) => {
    if (user.type_of_user === 'Enterprise' && isSubscribed === true) {
      return (
        <>
          <div className={classes.jobCardActionsViewWrapper}>
            <MetierActivator
              metier={metier}
              subscribedTo={subscribedTo}
              activeArray={activeArray}
              inactiveArray={inactiveArray}
            />
          </div>
          <div className={classes.jobCardActionsViewWrapper}>
            <FormConfirmMetierDelete metier={metier} />
        </div>
        </>
      );
    }
    if (user.type_of_user === 'Enterprise' && isSubscribed === false) {
      return (
        <div className={classes.jobCardActionsViewWrapper}>
          <FormConfirmMetierDelete metier={metier} />
        </div>
      );
    }
    return (
      <div className={classes.jobCardActionsViewWrapper}>
        <FormConfirmMetierDelete metier={metier} />
      </div>
    );
  };

  const disabledEditCondition = (metier) => {
    if (metier.is_active) {
      return (
        <>
          <Fab
            id={`editJob-${metier.user_metier_id}`}
            color="secondary"
            aria-label="edit"
            onClick={() =>{
              refreshUserData();
              handleOpenEditModal(
                metier.metier_id,
                metier.user_metier_id,
                metier.metier_name,
              )
            }}
            // disabled={disableItem}
          >
            <EditIcon />
          </Fab>
        </>
      );
    }
    return (
      <>
        <Fab
          id={`editJob-${metier.user_metier_id}`}
          color="secondary"
          aria-label="edit"
          onClick={() => {
            refreshUserData();
            handleOpenEditModal(
              metier.metier_id,
              metier.user_metier_id,
              metier.metier_name,
            )
          }}
          disabled={disableItem}
        >
          <EditIcon />
        </Fab>
      </>
    );
  };

  const CarteMetiers = (metiersArray) => {
    const carteArr = [];
    const activeArr = [];
    const inactiveArr = [];
    const userType = user.type_of_user;
    metiersArray.forEach((metier, inx) => {
      if(metier.table_type='loan'){
        carteArr.push(
          <Grid
            key={inx}
            container
            alignItems="center"
            className={`${classes.jobListContainer} job-card-container`}
          >
            {metier.is_active === true ? (
              <Grid item sm={8} xs={6}>
                <CardHeader title={metier.metier_name} />
              </Grid>
            ) : (
              <Grid item sm={8} xs={6}>
                <CardHeader
                  title={metier.metier_name}
                  style={{ opacity: '0.5' }}
                />
              </Grid>
            )}
  
            {showIncomplete(metier.metier_id)}
  
            <Grid item sm={4} xs={6} className={classes.jobCardActionsWrapper}>
              <div className={classes.jobCardActionsViewWrapper}>
                <Fab
                  color="secondary"
                  aria-label="view"
                  onClick={() => {
                    refreshUserData();
                    handleOpenViewMetierModal(
                      metier.metier_id,
                      metier.user_metier_id,
                      metier.metier_name,
                    )
                  }}
                  className={classes.jobCardActionsView}
                  // disabled={disableItem}
                >
                  <VisibilityIcon />
                </Fab>
              </div>
              <div className={classes.jobCardActionsViewWrapper}>
                {disabledEditCondition(metier)}
              </div>
              {renderButtons(metier)}
            </Grid>
          </Grid>,
        );
      }
      
    });
    return carteArr;
  };

return (
  <Grid container>
    
      <>
        <Card square className={classes.rootCard} elevation={1}>
          <Grid container>
            <Grid item xs={12}>
              <CardContent>
                <Typography variant="body2">
                  Choissisez une des options ci-dessous pour activer le module prêt de personnel :
                </Typography>
                  <RadioGroup  value={statusPret} onChange={handleStatusChange}>
                    <FormControlLabel className={classes.cardTitle} disabled={pretActive && user.pret_metiers.length>0}  value="not" control={<Radio />} label="Je souhaite ni accueillir ni proposer des prêts de personnel" />
                    <FormControlLabel className={classes.cardTitle} disabled={pretActive && user.pret_metiers.length>0} value="seeking_loan" control={<Radio />} label="Je souhaite accueillir des prêts de personnel" />
                    <FormControlLabel className={classes.cardTitle} disabled={pretActive && user.pret_metiers.length>0} value="providing_loan" control={<Radio />} label="Je souhaite proposer des prêts de personnel" />
                  </RadioGroup>
                  <Link
                      href={'https://moovejob.com/article/comment-le-pret-de-personnel-entre-entreprises-peut-renforcer-le-marche-du-travail-au-quebec'}
                      aria-label="Lire"
                      target="_blank" 
                      style={{ textDecoration: 'none', color:'#E5087E' }}
                  >
                    <Typography variant="body2">
                      Consultez notre article pour tout savoir sur le prêt de personnel, incluant un modèle de contrat.
                    </Typography>
                  </Link>
              </CardContent>
              
            </Grid>
          </Grid>
        </Card>
        
      </>
    {pretActive && (
      <>
        <Card square className={classes.rootCard} elevation={0}>
          <Grid container alignItems="center">
            {disableMetierModal()}
            {!disableMetierModal ? (
              <Grid item xs={8}>
                <CardHeader
                  title="Mes métiers"
                  className={classes.profileTabTitle}
                />
              </Grid>
            ) : null}
          </Grid>
        </Card>
      <Card className={classes.rootCard} square elevation={1}>
        {CarteMetiers(user.pret_metiers)}
        <JobModal
          closeModal={closeEditModal}
          metierData={metierData}
          show={showEditModal}
          editPret={true}
        />
        <JobModal
          closeModal={closeViewEditModal}
          metierData={metierData}
          show={showViewMetierModal}
          viewPret={true}
        />
      </Card>
      <Card style={{ width: '100%' }} square elevation={0}>
        <Grid container>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="caption">
                *Pour activer plus de métiers, veuillez modifier votre
                forfait.
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
      <JobModal
        closeModal={closeModal}
        show={show}
        metierCreated={refreshUserData}
        createPret={true}
        userMetiers={user.pret_metiers}
      />
      </>
    )}
  </Grid>
);
};

export default PretPersonnel;
