import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#e5087e',
      secondary: '#f9f9f9',
    },
  },
  typography: {
    h1: {
      fontSize: '2.6rem',
      color: '#e5087e',
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: '600',
      margin: '3vh 0 2vh',
    },
    h3: {
      fontSize: '1.8rem',
      margin: '3vh 0 2vh',
    },
    body2: {
      fontSize: '1.2rem',
    },
    caption: {
      display: 'flex',
    },
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },

  overrides: {
    MuiButton: {
      root: {
        color: '#ffffff',
        backgroundColor: '#e5087e',
        border: '2px solid #e5087e',
        borderRadius: '55px',
        minWidth: '280px',
        fontSize: '1.1em',
        margin: '3vh 0',
        '&:hover': {
          color: '#e5087e',
          backgroundColor: '#ffffff',
          textDecoration: 'none',
        },
        '&:focus, &.focus': {
          color: '#e5087e',
          backgroundColor: '#ffffff',
          textDecoration: 'none',
        },
      },

      text: {
        padding: '10px 20px 12px;',
        textTransform: 'none',
        fontSize: '1.1rem',
        justifyContent: 'space-around',
        [breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
    },

    MuiInputBase: {
      root: {
        backgroundColor: '#fff',
      },
    },
    MuiToolbar: {
      root: {},
    },
    MuiContainer: {
      root: {
        paddingLeft: '5vh!important',
        paddingRight: '5vh!important',
        '& > div': {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
          background: 'transparent',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
          ',',
        ),
        fontSize: '1.2rem',
        fontWeight: 600,
      },
      body2: {
        fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
          ',',
        ),
        fontSize: '1.1rem',
        lineBreak: 1.3,
        marginBottom: '1vh',
        width: '100%',
      },
      caption: {
        fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
          ',',
        ),
        fontSize: '1rem',
        fontWeight: 600,
      },
      h1: {
        fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
          ',',
        ),

        fontWeight: 900,
        margin: '3vh 0',
      },
      colorPrimary: {
        color: '#37383e',
      },
      colorTextSecondary: {
        color: '#37383e',
      },
    },
    MuiFormLabel: {
      root: {
        maxWidth: '83%',

        color: 'rgba(0,0,0,1)',
        '&$focused': {
          color: '#e5087e',
        },
        '&$error': {
          color: '#e5087e',
        },
        '&$asterisk ': {
          '&$error': {
            color: '#e5087e',
          },
        },
      },
      focused: {},
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },

    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#e5087e',
          borderWidth: 1,
        },
        '&$error $notchedOutline': {
          borderColor: '#e5087e',
          borderWidth: 1,
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: '#e5087e',
        height: '80px',
        position: 'fixed',
        zIndex: '1000',
        bottom: 0,
        width: '100%',
        '& svg, & img': {
          width: '3rem',
          height: 'auto',
        },
      },
    },

    MuiBottomNavigationAction: {
      root: {
        color: '#fff',
        '&$selected': {
          color: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      popper: {
        zIndex: 1000,
      },
      endAdornment: {
        display: 'flex',
        '& button': {
          flex: 'auto',
        },
      },
      clearIndicator: {
        marginRight: 10,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1rem',
        textAlign: 'center',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '999!important',
        top: '80px!important',
      },
    },
    MuiDialogTitle: {
      root: {
        margin: 0,
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiFab: {
      root: {
        width: 40,
        height: 40,
        boxShadow: 'none',
        border: '2px solid #e5087e',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          color: '#e5087e',
          backgroundColor: '#ffffff!important',
        },
      },
    },
    MuiTab: {
      textColorInherit: {
        color: '#fff',
        opacity: 1,
        '& svg': {
          fill: '#fff',
        },
        '&$selected': {
          fontWeight: 900,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: '#37383e',
        color: '#fff',
      },
      deleteIcon: {
        color: '#fff',
      },
    },
    MuiBadge: {
      badge: {
        border: '1px solid',
      },
    },
  },
});

export default responsiveFontSizes(theme);
