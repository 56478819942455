import React from 'react';
import {
  Typography,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import theme from '../../../theme';


const useStyles = makeStyles((theme) => ({

  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },


  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },

}));

const WebSite = ({
  setLanguages,
  name,
  userType,
  image,
  setImage,
  errors,
  setErrors,
  website,
  onFormChange,
  formData,
}) => {


  const classes = useStyles(theme);
  return (

          <Grid item xs={12}>
            <TextField
              type="url"
              id="outlined"
              label="Site web"
              variant="outlined"
              error={!!errors.website}
              name="website"
              value={formData.website || ''}
              placeholder="Votre site web"
              onChange={(event) => onFormChange(event)}
              onBlur={(event) => onFormChange(event)}
            />
            {errors.website && (
              <p className={`${classes.error} ${'error'}`}>{errors.website}</p>
            )}
          </Grid>
   
  );
};

export default WebSite;
