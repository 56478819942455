import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getFaqs = (categorie) => {
  let url = `${apiUrl()}/faqs`;
  const params = new URLSearchParams();

  if (categorie) {
    params.append('categorie', categorie);
  }
  if (params.toString()) {
    url += `?${params.toString()}`;
  }
  return axios.get(url)
    .then(response => response.data)
    .catch(error => errorHandling(error));
};

export default getFaqs;
