import React, { useState } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            overflowY: 'auto !important',
            paddingRight:'0px!important'
        }
    },
    navBtnAction: {
        paddingRight: '10px'
    },
    formControl: {
        margin: '0px',
        backgroundColor: 'transparent',
        borderRadius: '4px',

        '& .MuiInputBase-root': {
            top: '-50px',
            right: '22px',
            height: 0,
            zIndex: -1,
            height: 0,
            opacity: 0,
        },
        '& .MuiSelect-root': {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 0
        },
        '& .MuiInputLabel-outlined': {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 0
        },
    },
    menuPaper: {
        minWidth: '100px!important',
        backgroundColor: 'white',
        color: "white",
        boxShadow: 'none',
        margin: '0',
        borderRadius: '15px',
        textAlign: 'center',
        border: '1px solid #c8c8c8',
        '& .MuiMenuItem-root': {
            fontWeight: 500,
            fontSize: '1em',
            paddingBottom: '10px',
            textAlign: 'center',
            margin: '0',
        },
        [theme.breakpoints.up('md')]: {
            top: '65px!important',

        },
    },
    navButton: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        minWidth: 'unset',
        margin: 'unset',
        textDecoration: `none`,
        textTransform: `none`,
        color: `black`,
        whiteSpace: 'nowrap',
        fontWeight: 600,
        fontSize: "1.05em",
        '&:hover, &:focus': {
            color: 'black',
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
        },
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `none`,
        color: `black`,
        whiteSpace: 'nowrap',
        fontSize: "1.05em"
      },
    }));

export default function ServiceMenu({onSelectEvent}) {
    const classes = useStyles();
    const history = useHistory();
    const [faqType, setFaqType] = useState('');
    const [open, setOpen] = useState(false);

    const handleServiceChange = (event) => {
        setOpen(false);
        setFaqType('');
        history.push(event.target.value);
        if(typeof onSelectEvent != undefined){
            onSelectEvent(false)
        }
    };

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;


    const handleMouseEnter = () => {
        if (!isTouchDevice) setOpen(true);
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) setOpen(false);
    };

    const openServiceMenu = () => {
        setOpen(true);
    };

    const closeServiceMenu = () => {
        setOpen(false);
    };


    return (
        <>

            <FormControl
                className={classes.formControl}
                variant="outlined"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Button
                    className={classes.navButton}
                    onClick={openServiceMenu} onMouseEnter={handleMouseEnter}
                >
                    Services
                </Button>
                <Select
                    open={open}
                    onOpen={openServiceMenu}
                    onClose={closeServiceMenu}
                    value={faqType}
                    onChange={handleServiceChange}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        classes: { paper: classes.menuPaper }
                    }}
                >
                    <MenuItem value="/service/recrutement-international" className={classes.linkText}>Recrutement international de francophones</MenuItem>
                    <MenuItem value="/service/immigrant-investisseur" className={classes.linkText}>Immigrant investisseur</MenuItem>
                    <MenuItem value="/service/pret-de-personnel" className={classes.linkText}>Prêt de personnel entre entreprises</MenuItem>
                    <MenuItem value="/service/sous-traitance" className={classes.linkText}>Sous-Traitance internationale</MenuItem>
                </Select>
            </FormControl>
        </>

    );
}
