import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export default (slug) => axios.get(`${apiUrl()}/blog_data/show?q=${slug}`)
  .then((response) => {
    const { data } = response;
    return data;
  })
  .catch((error) => errorHandling(error));
