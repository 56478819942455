import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Typography, Grid, Box, ListItem, List, Button, Link, Tabs, Tab } from '@material-ui/core';
import Footer from '../UI-Component/Footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "100px",
    },
    homeContainer: {
      backgroundColor: "whitesmoke",
      paddingTop: "40px",
      paddingBottom: "50px"
    },
    boxContainer: {
      backgroundColor: '#f9f9f9 !important',
      borderRadius: 10,
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      padding: 20,
      marginBottom: 40,
    },
    bold: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    simpleText:{
      fontWeight: 'normal',
    },
    flexHeightDesktop: {
      height: 'auto',
      justifyContent: 'center',
      borderRadius: '20px',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
    listMooveContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
      display: 'flex',
      "& ul ": {
        backgroundColor: '#f9f9f9!important',
        padding: 15,
        borderRadius: 15,
        width: '100%'
      }
    },
    decimalList: {
      counterReset: 'list-counter',
    },
    listMooveDecimal: {
      position: 'relative',
      margin: '0',
      display: 'list-item',
      counterIncrement: 'list-counter',
      '&::before': {
        content: 'counter(list-counter) ". "',
        position: 'absolute',
        left: 0,
        height: '100%',
        color: theme.palette.secondary.main,
        fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
      '& p': {
        margin: '0',
        marginLeft: '20px',
        padding: '0px',
        fontWeight: 'normal',
      },
    },
    listMoove: {
      position: 'relative',
      margin: '0',
      '&::before': {
        content: '"*"',
        position: 'absolute',
        left: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '30px',
        width: '30px',
        height: '100%',
        fontSize: 45,
        color: theme.palette.secondary.main,
      },
      '& p': {
        margin: '0',
        marginLeft: '20px',
        padding: '0px',
        fontWeight: 'normal',
      },
    },
    simpleList: {
      position: 'relative',
      margin: '0',
      '&::before': {
        content: '"."',
        position: 'absolute',
        top: -20,
        height: '100%',
        fontSize: 45,
        color: theme.palette.secondary.main,
      },
      '& p': {
        margin: '0',
        marginLeft: '20px',
        padding: '0px',
        fontWeight: 'normal',
      },
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 10
      },
    },
    buttonStyle: {
      minWidth: 'unset',
      lineHeight: '18px',
      padding: '0px 12px',
      height: 44,
      margin: '7px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '15px'
      },
      '&:hover': {
        '& a': {
          color: '#E5087E',
          textTransform: 'none',
          textDecoration: 'none',
        }
      },
      '& a': {
        fontWeight: 600,
        color: '#fff',
        textDecoration: 'none',
        width: '100%',
        '&:hover': {
          color: '#E5087E',
        },
      },
    },
    reverseButtonStyle: {
      lineHeight: '18px',
      padding: '0px 12px',
      height: 44,
      margin: '7px',
      color: '#E5087E',
      backgroundColor: '#ffffff',
      fontWeight: 'bold',
      '&:hover': {
        textTransform: 'none',
        textDecoration: 'none',
        backgroundColor: '#E5087E',
        color: '#ffffff',
        '& a': {
          color: '#ffffff',
          textTransform: 'none',
          textDecoration: 'none',
        },
      },
      '&:focus, &.focus': {
        backgroundColor: '#E5087E',
        color: '#ffffff',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '20px',
      },
    },
    btnLink: {
      color: '#e5087e',
      textTransform: 'none',
      textDecoration: 'none',
      pointer: 'cursor',
      fontSize: '16px',
      '&:hover': {
        color: '#E5087E',
      },
    },
    btnLinkReverse: {
      color: '#e5087e',
      textTransform: 'none',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#E5087E',
        color: '#ffffff',
        textTransform: 'none',
        textDecoration: 'none',
      },
    },
    tabContainer: {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
        '& button': {
          color: '#888',
        },
        '& button.Mui-selected': {
          color: '#E5087E',
        }
      }
    },
    simpleLink:{
      color: '#e5077f',
      fontWeight: 'bold',
      fontFamily: 'Dosis',
      fontSize: '18px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        textTransform: 'none',
       
      },
    },
    underlineLink:{
      color: '#e5077f',
      fontWeight: 'bold',
      fontFamily: 'Dosis',
      fontSize: '18px',
      textDecoration: 'underline',
      '&:hover': {
        textTransform: 'none',
       
      },
    }
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `indicator-${index}`,
    "aria-controls": `indicator-${index}`
  };
}



const Service = (props) => {
  const { page } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    console.log(event);
  };

  return (
    <>
      <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
          <Grid container className={classes.homeContainer}>

            <Grid item xs={12}>
              <Grid item lg={12} className={classes.serviceContainer}>
                <div>
                  {page == 'recrutement-international' && (
                    <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>Recrutement international de francophones</Typography>
                  )}
                  {page == 'immigrant-investisseur' && (
                    <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>Immigrant investisseur</Typography>
                  )}
                  {page == 'pret-de-personnel' && (
                    <>
                                        <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>Prêt de personnel entre entreprises</Typography>
                                        <Typography variant="h2" style={{ textAlign: 'center', width: '100%', margin: 20, fontSize: '1.4em' }}>Découvrez une nouvelle manière de répondre à vos besoins en compétences</Typography>

                    </>
                  )}
                  {page == 'sous-traitance' && (
                    <>
                      <Typography variant="h1" style={{ textAlign: 'center', width: '100%' }}>Sous-Traitance internationale</Typography>
                      <Typography variant="h2" style={{ textAlign: 'center', width: '100%', margin: 20, fontSize: '1.4em' }}>Collaborez avec les meilleurs <label className={classes.bold}>talents francophones</label> pour votre entreprise </Typography>
                      <Typography variant="h2" style={{ textAlign: 'center', width: '100%', margin: 20, fontSize: '1.4em' }}>Ou décrochez
                        des <label className={classes.bold}>contrats à l&#39;international</label> en tant que travailleur(euse) autonome « freelance ».</Typography>
                    </>
                  )}
                </div>
                {page != 'pret-de-personnel' ? (
                  <Box sx={{ width: "100%" }} className={classes.tabBox}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        className={classes.tabContainer}
                        value={value}
                        onChange={handleChange}
                        aria-label="indicator example"
                        TabIndicatorProps={{
                          title: "indicator",
                          sx: { backgroundColor: "red", height: 4 }
                        }}
                      >
                        {page == 'recrutement-international' && (
                          <Tab onClick={handleClick} label="Entreprise" {...a11yProps(0)} />
                        )}
                        {page == 'recrutement-international' && (
                          <Tab onClick={handleClick} label="Candidat" {...a11yProps(1)} />
                        )}
                        {page == 'immigrant-investisseur' && (
                          <Tab onClick={handleClick} label="Entrepreneur" {...a11yProps(0)} />
                        )}
                        {page == 'immigrant-investisseur' && (
                          <Tab onClick={handleClick} label="Repreneur" {...a11yProps(1)} />
                        )}
                        {page == 'sous-traitance' && (
                          <Tab onClick={handleClick} label="Entreprise" {...a11yProps(0)} />
                        )}
                        {page == 'sous-traitance' && (
                          <Tab onClick={handleClick} label="Candidat « à votre compte »" {...a11yProps(1)} />
                        )}
                      </Tabs>
                    </Box>
                    {page == 'recrutement-international' && (
                      <>
                        <TabPanel value={value} index={0}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes une entreprise</label> à la recherche de nouvelles solutions pour trouver vos futurs
                                        talents francophones à l’international, moovejob vous simplifie la connexion en quelques
                                        clics.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Créer votre compte gratuitement.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Sélectionnez le ou les types de contrats proposés.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Choisissez les compétences recherchez.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Notre algorithme vous matchs uniquement avec les meilleurs candidats.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dès que vous avez des matchs, choisissez le forfait qui vous correspond.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Communiquer directement et sans intermédiaire avec vos candidats.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Réalisez vos entrevues.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Recrutez.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/entreprise')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Le monde des talents s’ouvre à vous ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className={classes.actionContainer}>
                                <Typography className={classes.bulletPoint}>
                                  Vous vous posez des questions ?
                                </Typography>
                              </div>
                              <div className={classes.actionContainer}>
                                <Button
                                  size='sm'
                                  type="submit"
                                  className={classes.reverseButtonStyle}
                                  onClick={() => navigate('/faq-entreprise')}
                                >
                                  <Link className={classes.btnLinkReverse} >
                                    Consultez notre FAQ
                                  </Link>
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes un candidat</label> francophone à la recherche d’opportunité à l’international,
                                        moovejob vous connecte aux entreprises à la recherche de vos compétences en quelques clics.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Créer votre compte gratuitement.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Choisissez les compétences qui vous représentent le mieux.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Notre algorithme travaille pour vous et vous connecte aux bonnes entreprises.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Préparez-vous aux entrevues (<Link className={classes.btnLink} href={"https://moovejob.com/blog"} target="_blank">découvrez nos conseils candidats sur notre
                                            blogue</Link>).
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Préparez vos affaires, votre projet commence maintenant.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/candidat')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Embarquement immédiat – Cliquez ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className={classes.actionContainer}>
                                <Typography className={classes.bulletPoint}>
                                  Vous vous posez des questions ?
                                </Typography>
                              </div>
                              <div className={classes.actionContainer}>
                                <Button
                                  size='sm'
                                  type="submit"
                                  className={classes.reverseButtonStyle}
                                  onClick={() => navigate('/faq-candidat')}
                                >
                                  <Link className={classes.btnLinkReverse} >
                                    Consultez notre FAQ
                                  </Link>
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    )}
                    {page == 'immigrant-investisseur' && (
                      <>
                        <TabPanel value={value} index={0}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes un entrepreneur</label> à la recherche d’un repreneur investisseur pour votre
                                        organisation et vous êtes prêt à trouver votre relève à l’international? moovejob vous
                                        donne accès à de nouveaux candidats en quelques clics.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Créer votre compte gratuitement.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Choisissez les métiers de votre entreprise.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dans type de contrat offert, sélectionnez : Vendeur / Acheteur d’Entreprise
                                          (immigrant investisseur).
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dès que vous avez des matchs, choisissez le forfait qui vous correspond.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Communiquer directement et sans intermédiaire avec vos repreneurs potentiels.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Démarrez vos échanges.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Négociez.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Vendez.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/entreprise')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Un monde de repreneurs s’ouvre à vous ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes un repreneur à la recherche d’une entreprise à reprendre</label> comme
                                        immigrant investisseur? Nous allons vous aider à trouver votre future organisation!
                                        moovejob vous connecte aux cédants actifs en quelques clics.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Créer votre compte gratuitement comme candidat (100% gratuit).
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Choisissez les métiers de l’entreprise que vous aimeriez reprendre.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dans type de contrat offert, sélectionnez : Vendeur / Acheteur d’Entreprise
                                          (immigrant investisseur).
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Notre algorithme vous matchs uniquement avec les entrepreneurs pour votre
                                          projet.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dès que vous avez des matchs, communiquer directement et sans intermédiaire
                                          avec vos chefs d’entreprises.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Démarrez vos échanges.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Négociez.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Achetez.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/candidat')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Un monde d’opportunités s’ouvre à vous ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    )}
                    {page == 'sous-traitance' && (
                      <>
                        <TabPanel value={value} index={0}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes une entreprise : </label> Trouvez de nouveaux partenaires en sous-traitance.
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        Notre système de matching avancé vous connecte directement avec les meilleurs
                                        partenaires à la recherche de contrat, réduisant ainsi le temps et les efforts nécessaires
                                        pour trouver les talents appropriés.
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        Imaginez ne plus avoir à parcourir des centaines de sites web, pages LinkedIn ou
                                        annuaires professionnels pour trouver les bons partenaires!
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Commencez sans frais. Inscrivez-vous et découvrez notre plateforme.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Sélectionnez dans contrat « Sous-traitance (Freelance / Entrepreneur
                                          indépendant / Prestataire) ».
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Choisissez les compétences recherchées : filtrez par expertise et expérience.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Dès que vous avez des matchs, choisissez le forfait qui vous correspond.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Communiquez directement et sans intermédiaire avec vos nouveaux
                                          partenaires.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Négociez.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Collaborez.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/entreprise')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Le monde des talents s’ouvre à vous ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className={classes.actionContainer}>
                                <Typography className={classes.bulletPoint}>
                                  Vous vous posez des questions ?
                                </Typography>
                              </div>
                              <div className={classes.actionContainer}>
                                <Button
                                  size='sm'
                                  type="submit"
                                  className={classes.reverseButtonStyle}
                                  onClick={() => navigate('/faq-entreprise')}
                                >
                                  <Link className={classes.btnLinkReverse} >
                                    Consultez notre FAQ
                                  </Link>
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Grid container className={classes.homeContainer}>
                            <Grid item md={2} xs={12}>
                            </Grid>
                            <Grid item md={8} xs={12} className={classes.boxContainer}>
                              <Grid item lg={12} className={classes.serviceContainer}>
                                <Grid item xs={12} md={12}>
                                  <List dense>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Vous êtes un candidat :</label> Trouvez des contrats à l&#39;international
                                        en quelques clics.
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        Vous êtes un indépendant, entrepreneur ou digital nomade à la recherche de nouveaux
                                        contrats et de projets stimulants ? moovejob.com est la passerelle vers des opportunités
                                        internationales qui vous correspondent.
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        <label className={classes.bold}>Inscrivez-vous gratuitement</label> et bénéficiez de notre système de matching par métiers et
                                        compétences.
                                      </Typography>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" className={classes.introListItem}>
                                      <Typography className={classes.bulletPoint}>
                                        Plus besoin de chercher, nous vous trouvons les meilleures offres.
                                      </Typography>
                                    </ListItem>
                                  </List>
                                  <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                                    <List className={classes.decimalList}>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Inscrivez-vous en tant que candidat (important sinon vous n’aurez pas de
                                          match).
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Créez votre profil en quelques minutes.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Profitez de notre système de match automatique: Recevez des offres
                                          correspondantes précisément à vos compétences et à vos attentes.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          C&#39;est gratuit, tout bénéfice pour vous: Aucune commission, aucun frais
                                          caché..
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Communiquez directement et sans intermédiaire avec vos nouveaux clients.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Négociez.
                                        </Typography>
                                      </ListItem>
                                      <ListItem className={classes.listMooveDecimal}>
                                        <Typography className={classes.bulletPoint}>
                                          Collaborez.
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <div className={classes.actionContainer}>
                                    <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/candidat')}
                                    >
                                      <Link className={classes.btnLink} >
                                        Commencez dès maintenant – Cliquez ici!
                                      </Link>
                                    </Button>
                                  </div>
                                  <br />
                                  <br />

                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className={classes.actionContainer}>
                                <Typography className={classes.bulletPoint}>
                                  Vous vous posez des questions ?
                                </Typography>
                              </div>
                              <div className={classes.actionContainer}>
                                <Button
                                  size='sm'
                                  type="submit"
                                  className={classes.reverseButtonStyle}
                                  onClick={() => navigate('/faq-candidat')}
                                >
                                  <Link className={classes.btnLinkReverse} >
                                    Consultez notre FAQ
                                  </Link>
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    )}
                  </Box>
                ) : (
                  <Grid container className={classes.homeContainer}>
                    <Grid item md={2} xs={12}>
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.boxContainer}>
                      <Grid item lg={12} className={classes.serviceContainer}>
                        <Grid item xs={12} md={12}>
                          <List dense>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.bulletPoint}>
                              Dans un monde en constante évolution, votre entreprise doit rester <label className={classes.bold}>agile</label>!
                              </Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.bulletPoint}>
                              Imaginez avoir la possibilité de combler rapidement vos besoins en personnel sans
                              passer par les processus <label className={classes.bold}>longs</label> et <label className={classes.bold}>coûteux</label> des agences de placement traditionnelles.
                              </Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.bulletPoint}>
                              Grâce à <label className={classes.bold}>moovejob</label>, c’est désormais possible ! Notre <label className={classes.bold}>solution</label> vous offre une autonomie
totale dans la gestion de vos échanges.
                              </Typography>
                            </ListItem>
                            
                          </List>
                          <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                            <List className={classes.decimalList}>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.bulletPoint}>
                                Les avantages de notre service de <label className={classes.bold}>prêt de personnel</label> :
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMoove}>
                                <Typography className={classes.bulletPoint}>
                                <label className={classes.bold}>Flexibilité</label> : Empruntez ou prêtez du personnel pour des périodes courtes ou
                                prolongées.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMoove}>
                                <Typography className={classes.bulletPoint}>
                                <label className={classes.bold}>Autonomie</label> : Gérez directement les modalités de prêt et d&#39;emprunt.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMoove}>
                                <Typography className={classes.bulletPoint}>
                                <label className={classes.bold}>Économies</label> : Réduisez les coûts liés à l’embauche et à la formation.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMoove}>
                                <Typography className={classes.bulletPoint}>
                                <label className={classes.bold}>Qualité</label> : Accédez à des professionnels francophones qualifiés et expérimentés.
                                </Typography>
                              </ListItem>
                            </List>
                          </Box>
                          <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>

                            <List className={classes.decimalList}>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.bulletPoint}>
                                Comment ça marche ? Rien de plus simple !
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMooveDecimal}>
                                <Typography className={classes.bulletPoint}>
                                Créez votre profil entreprise sur moovejob.com.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMooveDecimal}>
                                <Typography className={classes.bulletPoint}>
                                Activez l&#39;option [Prêt de personnel].
                                </Typography>
                              </ListItem>
                              <ListItem  className={classes.listMooveDecimal}>
                                <Typography className={classes.bulletPoint}>
                                Choisissez les métiers et compétences recherchées.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.listMooveDecimal}>
                                <Typography className={classes.bulletPoint}>
                                Découvrez gratuitement vos connexions “matchs” avant de vous engager.
                                </Typography>
                              </ListItem>
                            </List>
                          </Box>
                          <List dense>
                          <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.simpleText} style={{ fontWeight: 'bold'}}>
                                Curieux de voir comment cela fonctionne ?                            
                              </Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.simpleText}>
                              Demandez une démo pour en savoir plus et
                              découvrir comment notre service peut transformer votre gestion des ressources
                              humaines.                              
                              </Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                            <Button
                                      size='sm'
                                      type="submit"
                                      className={classes.buttonStyle}
                                      onClick={() => navigate('/entreprise')}
                                    >
                                      <Link className={classes.btnLink} href={"https://calendly.com/moovejob/demo"} target="_blank" >
                                        Choisissez la date de votre démo
                                      </Link>
                                    </Button>
                              
                            </ListItem>
                            
                          </List>
                          <List dense>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography>Exemples de talents disponibles</Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start" className={classes.introListItem}>
                              <Typography className={classes.simpleText}>
                              Chargé(e) de communication :
                              </Typography>
                            </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Expérience de 2 à 5 ans dans le graphisme et le marketing.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Création de supports visuels et gestion de sites internet.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Maîtrise de Canva, LinkedIn, WordPress.
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Capacité à animer des réseaux sociaux et à produire des vidéos.
                                </Typography>
                              </ListItem>
                              <br/>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                Transformez votre gestion RH dès aujourd&#39;hui !
                                </Typography>
                              </ListItem>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                Pour toute question ou pour débuter avec notre nouvelle option, contactez-nous à <a href="mailto:communication@moovejob.com" className={classes.simpleLink} >communication@moovejob.com</a>.
                                </Typography>
                              </ListItem>
                              </List>
                              <br/>
                              <List dense>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                  <Link className={classes.underlineLink} onClick={() => navigate('/article/comment-le-pret-de-personnel-entre-entreprises-peut-renforcer-le-marche-du-travail-au-quebec')} >
                                    Téléchargez le modèle de contrat type
                                  </Link>
                                </Typography>
                              </ListItem>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                Téléchargez notre modèle de contrat de prêt de main d&#39;oeuvre pour vous assurer que toutes les formalités sont respectées.
                                </Typography>
                              </ListItem>
                              <br/>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                  <Link className={classes.underlineLink} href={"https://www.youtube.com/watch?v=bI_WbSNwLJE"}  target="_blank" >
                                  Visionner notre interview : Possibilités de prêt de personnel entre entreprises au Québec                                 
                                  </Link>
                                </Typography>
                              </ListItem>
                              <ListItem alignItems="flex-start" className={classes.introListItem}>
                                <Typography className={classes.simpleText}>
                                Découvrez les études de cas, les risques potentiels et les témoignages de nos experts :                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Micheline St-Jean : Coordonnatrice RH chez Zenika Montréal
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Pascal Nataf : Enseignant et fondateur de L&#39;Asylum, promoteur de la
collaboration technologique
                                </Typography>
                              </ListItem>
                              <ListItem className={classes.simpleList}>
                                <Typography className={classes.bulletPoint}>
                                Philippe Zinser : Expert RH avec plus de 30 ans d&#39;expérience
                                </Typography>
                              </ListItem>
                          </List>
                          <br />
                          <br />

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className={classes.actionContainer}>
                        <Typography className={classes.bulletPoint}>
                          Vous vous posez des questions ?
                        </Typography>
                      </div>
                      <div className={classes.actionContainer}>
                        <Button
                          size='sm'
                          type="submit"
                          className={classes.reverseButtonStyle}
                          onClick={() => navigate('/faq-entreprise')}
                        >
                          <Link className={classes.btnLinkReverse} >
                            Consultez notre FAQ
                          </Link>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Service;
