import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Card, CardHeader, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  reverselinkEntreprise: {
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#e5087e',
    borderRadius: '55px',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    textDecoration: 'none',
    padding: '0 20px',
    width: 'fit-content',
    margin: '16px 0 40px 16px!important',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '& span': {
      fontSize: '1.3rem!important',
    },

    '&:hover': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      boxShadow: ' 2px 1px 1px 0 rgba(0, 0, 0, 0.1)',
      textDecoration: 'none',
    },
    '&:focus, &.focus': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      borderColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
      textDecoration: 'none',
    },
  },
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
  checkboxElement:{
    '& .MuiFormControlLabel-label ':{
      fontWeight: 'normal',
      fontSize: '1.1rem',
    }
  }
  
}));

const Contracts = (props) => {
  const { typeOfUser } = props;
  const [error, setError] = useState(null);
  const classes = useStyles(theme);
  const { SnackBar, refreshUserData } = useSessionContext();
  const {
    informSuccess,
    informError
  } = SnackBar;
  const [contractList, setContractList] = useState([]);
  const [userContracts, setUserContracts] = useState([]);


  useEffect(() => {
    refreshUserData();
    fetchData();
  }, []);

  const updateContracts = (data) => {
    setContractList(data.contract_types);
    setUserContracts(data.user_contract_types);
  };

  const fetchData = () => {
    fetch('/contract/types')
      .then(response => response.json())
      .then(data => updateContracts(data))
      .catch(() => informError('Une erreur est survenue'));
  };


  const renderCheckList = () => {
    return contractList.map((listItem) => <FormControlLabel className={classes.checkboxElement} key={listItem.id}
      control={<Checkbox checked={userContracts.some(e => e.id === listItem.id)}
        onChange={(e) => saveContractData(e, listItem)}
        value={listItem.id} />}
      label={listItem.label} />);
  };

  const saveContractData = (e, listItem) => {
    let url = '/contract/user/create';

    if (userContracts.some(e => e.id === listItem.id)) {
      url = '/contract/user/delete';
    }
    refreshUserData();
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contract_type_id: e.target.value
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          return Promise.reject({
            errorMessage: data.error_message,
            errorStatus: response.status,
          });
        }
        setUserContracts(data.data);
        informSuccess('Succès!');
      })
      .catch((error) => {
        setError(error.errorMessage);
        informError(error.errorMessage);
      });
  };

  return (
    <Card square className={classes.rootCard} elevation={0} variant="outlined">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          {typeOfUser === 'Candidate' ? (
            <CardHeader
              title="Type de contrat désiré"
              subheader="Choisissez les types de contrat qui vous intéresse"
              className={classes.profileTabTitle}
            />
          ) : (
            <CardHeader
              title="Type de contrat offert"
              subheader="Sélectionner les types de contrat proposer par votre entreprise"
              className={classes.profileTabTitle}
            />
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <FormGroup style={{ paddingLeft: 16 }}>
            {renderCheckList()}
          </FormGroup>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Contracts;
