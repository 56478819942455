import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { List, Card, CardContent, ListItem, CardHeader } from '@material-ui/core';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';
import validator from 'validator/es';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  rootCard: {
    width: '100%',
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
  },
  fitRight: {
    marginRight: '5%',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
    },
  },
  typography: {
    margin: '16px 0 10px',
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
  },
  submitButtonRow: {
    marginTop: '20px!important',
    '& span': {
      padding: '0 16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
    },
  },
}));

const PersonalityTest = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { informError, informSuccess } = SnackBar;
  const [formData, setFormData] = useState({
    linkToResult: user.test_result_link || '',
    portfolioLink: user.portfolio_link || '',
    linkedInLink: user.linkedin_link || '',
    presentationLink: user.presentation_link || '',
  });

  const [formErrors, setFormErrors] = useState({});


  const handleSubmit = () => {
    const raw = {
      user: {
        linkToResult: formData.linkToResult,
        portfolioLink: formData.portfolioLink,
        linkedInLink: formData.linkedInLink,
        presentationLink: formData.presentationLink,
      },
    };
    if (validateForm(true)) {
      updateUser(raw).then((response) => {
        if (response !== null && response !== undefined) {
          if (response.status === 200) {
            refreshUserData();
            informSuccess('Succès!');
          } else {
            informError('Une erreur est survenue');
          }
        }
      });
    }
  };

  const onFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.linkToResult != null && formData.linkToResult.trim() != '') {
      if (!validator.isURL(formData.linkToResult)) {
        errors.linkToResult = 'L\'adresse du lien n\'est pas valide.';
      }
    }
    if (formData.linkedInLink != null && formData.linkedInLink.trim() != '') {
      if (!validator.isURL(formData.linkedInLink)) {
        errors.linkedInLink = 'L\'adresse du lien n\'est pas valide.';
      }
    }
    if (formData.portfolioLink != null && formData.portfolioLink.trim() != '') {
      if (!validator.isURL(formData.portfolioLink)) {
        errors.portfolioLink = 'L\'adresse du lien n\'est pas valide.';
      }
    }
    if (formData.presentationLink != null && formData.presentationLink.trim() != '') {
      if (!validator.isURL(formData.presentationLink)) {
        errors.presentationLink = 'L\'adresse du lien n\'est pas valide.';
      }
    }

    setFormErrors({
      ...errors,
      verifyAll
    });

    return Object.keys(errors).length < 1;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <List className={classes.root}>
        <ListItem divider>
          <Grid container className={classes.root}>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <CardHeader
                    title='Réseaux sociaux'
                    className={classes.profileTabTitle}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    1. Réalisez votre test de personnalité à cette addresse
                  </Typography>
                  <a
                    href="https://www.16personalities.com/fr/test-de-personnalite"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.16personalities.com/fr/test-de-personnalite
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    2. Collez votre résultat ci-dessous
                  </Typography>
                  <TextField
                    className={classes.fitRight}
                    type="url"
                    id="outlined"
                    pattern="^https?://(www\.)?16personalities\.com"
                    label="Collez votre résultat ici"
                    variant="outlined"
                    name="linkToResult"
                    value={formData.linkToResult || ''}
                    placeholder="Collez votre résultat ici"
                    onChange={(e) => onFormChange(e)}
                  />
                  {formErrors.linkToResult && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.linkToResult}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Collez le lien vers votre profil LinkedIn ci-dessous
                  </Typography>
                  <TextField
                    className={classes.fitRight}
                    type="url"
                    id="outlined"
                    pattern="^https?://(www\.)?linkedin\.com"
                    label="Collez le lien vers votre profil LinkedIn ici"
                    variant="outlined"
                    name="linkedInLink"
                    value={formData.linkedInLink || ''}
                    placeholder="Collez le lien vers votre profil LinkedIn ici"
                    onChange={(e) => onFormChange(e)}
                  />
                  {formErrors.linkedInLink && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.linkedInLink}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Collez le lien de votre site web / portfolio en ligne ci-dessous
                  </Typography>
                  <TextField
                    className={classes.fitRight}
                    type="url"
                    id="outlined"
                    label="Collez le lien de votre site web / portfolio en ligne ici"
                    variant="outlined"
                    name="portfolioLink"
                    value={formData.portfolioLink || ''}
                    placeholder="Collez le lien de votre site web / portfolio en ligne ci-dessous"
                    onChange={(e) => onFormChange(e)}
                  />
                  {formErrors.portfolioLink && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.portfolioLink}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Collez le lien vers une vidéo de présentation de vous ci-dessous
                  </Typography>
                  <TextField
                    className={classes.fitRight}
                    type="url"
                    id="outlined"
                    pattern="^https?://(www\.)?youtube\.com"
                    label="Collez le lien vers une vidéo de présentation de vous ici"
                    variant="outlined"
                    name="presentationLink"
                    value={formData.presentationLink || ''}
                    placeholder="Collez le lien vers une vidéo de présentation de vous ici"
                    onChange={(e) => onFormChange(e)}
                  />
                  {formErrors.presentationLink && (
                    <p className={`${classes.error} ${'error'}`}>
                      {formErrors.presentationLink}
                    </p>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.submitButtonRow}>
                  <Button size="small" onClick={handleSubmit}>Enregistrer</Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  );
};

export default PersonalityTest;
