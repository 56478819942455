import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';
import {
  List, ListItem, Card, CardContent
} from '@material-ui/core';
import useSessionContext from '../../sessions/useSessionContext';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
  },
  fitRight: {
    marginRight: '5%',
    maxWidth: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
    },
  },
  typography: {
    margin: '16px 0 10px',
    fontWeight: 600,
  },
  alignRight: {
    textAlign: 'left',
    width: 'auto',
  },
  submitButton: {
    marginTop: '20px!important',
    height: '40px',
    '& span': {
      padding: '0 16px',
    },
  },
  submitButtonRow: {

    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
    },
  },
}));

const UserPreference = () => {
  const classes = useStyles(theme);
  const { user, SnackBar, updateUser, refreshUserData } = useSessionContext();
  const { informError, informSuccess } = SnackBar;

  const [formData, setFormData] = useState({
    unsubscribe_all: !user.preferences.receive_notifications && !user.preferences.receive_reports && !user.preferences.receive_communications && !user.preferences.receive_newsletters,
    ...user.preferences
  });


  const onCheckBoxChange = ({ target }) => {
    const { name, checked } = target;
    if(name!='unsubscribe_all'){
      setFormData((formData) => ({
        ...formData,
        [name]: checked,
        unsubscribe_all: false
      }));
    }else{
      setFormData({
        unsubscribe_all: true,
        receive_notifications: false,
        receive_communications: false,
        receive_reports: false,
        receive_newsletters: false,
      });
    }
    
    

  };


  const handleSubmit = () => {
    const raw = {
      user: {
        preferences: formData
      },
    };
    updateUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status === 200) {
          refreshUserData();
          informSuccess('Vos préférences ont été enregistrés !');
        } else {
          informError('Une erreur est survenue');
        }
      }
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <List className={classes.root}>
        <ListItem divider>
          <Grid container className={classes.root}>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <CardHeader
                    title="Préférences d'emailing"
                    className={classes.profileTabTitle}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Choisissez quel email vous souhaitez recevoir : 
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.receive_notifications}
                          onChange={onCheckBoxChange}
                          name="receive_notifications"
                        />
                      }
                      label="Notifications et alertes"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.receive_reports}
                          onChange={onCheckBoxChange}
                          name="receive_reports"
                        />
                      }
                      label="Rapports et suivis"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.receive_communications}
                          onChange={onCheckBoxChange}
                          name="receive_communications"
                        />
                      }
                      label="Nos communications et offres promotionelles"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.receive_newsletters}
                          onChange={onCheckBoxChange}
                          name="receive_newsletters"
                        />
                      }
                      label="Newsletters"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.unsubscribe_all}
                          onChange={onCheckBoxChange}
                          name="unsubscribe_all"
                        />
                      }
                      label="Se désinscrire de tous les e-mails"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button size="small" className={classes.submitButton} onClick={handleSubmit}>
                    Enregistrer
                  </Button>
                </Grid>
                </CardContent>
            </Card>
          </Grid>
        </ListItem>
      </List>
    </Grid>

  );
};

export default UserPreference;
