import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Grid,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import theme from '../theme';
import useSessionContext from '../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: 'flex',
    },
  },

  shareMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '0!important',
    borderRadius: '50px!important',
    border: 0,
    background: 0,
    '&:focus': {
      boxShadow: '0 0 0 2pt #999',
      outline: 0,
    },
    '&  svg': {
      width: '30px',
      height: '30px',
      borderRadius: '50px',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    textAlign: 'left',
    backgroundColor: theme.palette.primary.main,
    color: '#37383e',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    boxShadow: 0,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  ButtonModalStyle: {
    color: '#ffffff',
    backgroundColor: '#E5087E',
    width: 38,
    height: 38,
    padding: 10,
    borderRadius: 50,

    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    '&:focus, &.focus': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
}));

export default function FormConfirmServiceDelete(props) {
  const { service } = props;
  const classes = useStyles(theme);
  const { user, refreshUserData, updateUser } = useSessionContext();
  const [userServices, setUserServices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [formErrors, setFormError] = useState({});

  useEffect(() => {
    setUserServices(user.user_services);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteService = async (userServiceId) => {
    updateUser({
      user: {
        service_delete: userServiceId,
      },
    }).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.log('error deleting service');
        }
      }

      if (response.status === 200) {
        refreshUserData();
        setOpen(false);
      }
    });
  };

  //
  return (
    <div className={classes.connexionContainer}>
      <Fab id={`deleteService-${service.id}`} aria-label="Delete" color="secondary" onClick={handleClickOpen}>
        <DeleteIcon />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 0',
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <DialogTitle style={{ textAlign: 'center' }}>
                  Désirez-vous supprimer ce service ?
                </DialogTitle>
              </Grid>
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Button
                  className={classes.reverseButtonStyle}
                  color="primary"
                  onClick={() => {
                    const filteredServices = userServices.filter(
                      (l) => l.id == service.id
                    );
                    if(filteredServices.length>0){
                      deleteService(filteredServices[0]);
                    }
                    
                  }}
                >
                  Confirmer
                </Button>
                <Button
                  onClick={() => handleClose()}
                >
                  Annuler
                </Button>
              </DialogActions>
            </Grid>
          </form>
          <div style={{ position: 'relative', top: 20, textAlign: 'center' }}>
            {formErrors.global && (
              <p className={`${classes.error} ${'error'}`}>
                {formErrors.global}
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
