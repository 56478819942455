import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem,
  Button,
} from '@material-ui/core';
import useSessionContext from '../sessions/useSessionContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  linkSubscribe: {
    backgroundColor: '#e5087e',
    textDecoration: 'none',
    padding: '0 20px',
    borderRadius: '20px',
    color: 'white',
    cursor: "pointer",
    border: '2px solid #e5087e',
    '&:hover': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      textDecoration: 'none',
    },
    '&:focus, &.focus': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      borderColor: '#e5087e',
      textDecoration: 'none',
    },
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
  },
  selectButton: {
    height: '44px',
    color: 'white',
    borderRadius: '30px',
    margin: 0,
    minWidth: '150px',
    fontWeight: 700,
    fontSize: '15px',
  },
}));
export default function bannerButton() {
  const classes = useStyles();

  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const history = useHistory();
  function navigate(path) {
    history.push(path);
  }
  const { user, setNavId } = useSessionContext();
  const navigateToSubscription = () => {
    
    toggleDrawer();
    if(user.type_of_user === 'Enterprise'){
      navigate('/forfaits-entreprise')
    }else if(user.type_of_user === 'Partner'){
      navigate('/forfaits-partenaire')
    }
    
  };

  const displayBannerButton = () => {
    if (user === undefined) {
      return null;
    }
    if (user.type_of_user === 'Candidate') {
      return null;
    } else if (
      (user.type_of_user === 'Enterprise' || user.type_of_user === 'Partner') &&
      (user.is_subscribed === false || user.is_subscribed ===  null)
    ) {
      return (
        <ListItem
          style={{
            width: 'auto',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Button  color="secondary" className={classes.selectButton} onClick={navigateToSubscription} size="small">
            Vous abonner
          </Button>
        </ListItem>
      );
    }
    else return null;
  };

  return <>{user && displayBannerButton()}</>;
}
