import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PasswordField from 'material-ui-password-field';
import {
  makeStyles,
  Button,
  TextField,
  Grid,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import validator from 'validator/es';
import theme from '../theme';
import useSessionContext from '../sessions/useSessionContext';
import LoaderSpinner from './LoaderSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: 'flex',
    },
  },
  form: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
    },
  },
  iconAction:{
    marginRight: 5,
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    color: '#e5087e',
    fontSize: '15px',
    backgroundColor: "white"
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#e5087e',
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  linkCandidat: {
    color: '#ffffff',
    backgroundColor: '#e5087e',
    borderRadius: '55px',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    textDecoration: 'none',
    padding: '0 20px',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      boxShadow: ' 2px 1px 1px 0 rgba(0, 0, 0, 0.1)',
    },
    '&:focus, &.focus': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    connexionIcon:{
      marginRight : 15,
    }
  },
}));

const Connexion = ({ defaultOpen = false,onSelectEvent=null }) => {
  const classes = useStyles(theme);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [stateOpen, setStateOpen] = useState(false);
  const [formErrors, setFormError] = useState({});
  const { SnackBar } = useSessionContext();
  const { informError } = SnackBar;
  const [formData, setFormData] = useState({
    email: null,
    password: null,
    showSpinner: false,
  });
  const location = useLocation();
  const { modifyUser, loginUser, resetPassword } = useSessionContext();

  const changeButtonLayout = () => {
    return (
      <Fab 
      variant="extended" 
      size="medium"
      color="secondary"
      title="Connexion"
      className={classes.iconAction}
      onClick={handleClickOpen}
      >
        Se connecter
      </Fab>
    );
  };

  const history = useHistory();
  useEffect(() => {
    if (defaultOpen) setStateOpen(true);
  });
  function navigate(path) {
    history.push(path);
    setStateOpen(false);
  }
  const forgotPassword = () => {
    const errors = {};
    setFormError({});
    if (formData.email === null || !validator.isEmail(formData.email)) {
      errors.email =
        "L'adresse courriel est requise. (votrecourriel@email.com)";
      setFormError({ ...errors });
    } else {
      resetPassword(formData.email).then(() =>
        navigate('/mot-de-passe-oublie'),
      );
    }
  };
  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.password || verifyAll) {
      if (formData.password === null) {
        errors.password = 'Mot de passe requis';
      } else if (!validator.isLength(formData.password, { min: 6 })) {
        errors.password = 'Mot de passe requis (plus de 5 caractères)';
      }
    }
    if (formData.email || verifyAll) {
      if (formData.email === null) {
        errors.email =
          "L'adresse courriel est requise. (votrecourriel@email.com)";
      } else if (!validator.isEmail(formData.email)) {
        errors.email =
          "L'adresse courriel n'est pas valide. (votrecourriel@email.com)";
      }
    }

    setFormError({
      ...errors,
      verifyAll,
    });
    return Object.keys(errors).length < 1;
  };
  const onFormChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    validateForm();
  };
  const handleClickOpen = () => {
    if(typeof onSelectEvent === 'function'){
      onSelectEvent(false);
      navigate('/mon-compte');
    }else{
      setStateOpen(true);
    }
    
  };
  const handleClose = () => {
    setFormData({ ...formData, showSpinner: false });
    setStateOpen(false);
  };

  const handleConnexion = (e) => {
    e.preventDefault();
    if (!validateForm(true)) {
      return;
    }
    setFormData({ ...formData, showSpinner: true });
    const raw = {
      user: {
        email: formData.email,
        password: formData.password,
      },
    };

    loginUser(raw).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.status) {
          if (response.status === 400 || response.status === 401) {
            const errors = {};
            errors.global = response.data.message;
            setFormError({ ...errors });
          } else if (response.status === 200) {
            if (response.data.user) {
              modifyUser(response.data.user);
              navigate('/mon-compte');
            } else {
              navigate('/accueil');
            }
          }
        }
        setFormData({
          ...formData,
          showSpinner: false,
        });
      } else {
        informError('La connexion a échouée, veuillez réessayer plus tard !');
      }
    });
  };

  return (
    <div className={classes.connexionContainer}>
      {changeButtonLayout()}
      {formData.showSpinner && (
        <LoaderSpinner sizeOfSpinner={150} text="Connexion en cours" />
      )}
      <Dialog
        fullScreen={fullScreen}
        open={stateOpen}
        onClose={handleClose}
        aria-labelledby="connexion"
      >
        <DialogContent>
          <form className={classes.form} onSubmit={handleConnexion}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 0',
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <DialogTitle>Se connecter</DialogTitle>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  placeholder="Votre adresse courriel"
                  autoFocus
                  error={!!formErrors.email}
                  type="email"
                  name="email"
                  label="Adresse Courriel"
                  onChange={(event) => onFormChange(event)}
                  onBlur={(event) => onFormChange(event)}
                  value={formData.email || ''}
                  // onChange={this.handleChange}
                  fullWidth
                />
                {formErrors.email && (
                  <p className={`${classes.error} ${'error'}`}>
                    {formErrors.email}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  className={classes.passwordField}
                  placeholder="Votre mot de passe"
                  autoFocus
                  error={!!formErrors.password}
                  name="password"
                  min={6}
                  label="Mot de passe"
                  onChange={(event) => onFormChange(event)}
                  onBlur={(event) => onFormChange(event)}
                  fullWidth
                />
                {formErrors.password && (
                  <p className={`${classes.error} ${'error'}`}>
                    {formErrors.password}
                  </p>
                )}
              </Grid>
            </Grid>
            <div style={{ position: 'relative', top: 20, textAlign: 'center' }}>
              {formErrors.global && (
                <p className={`${classes.error} ${'error'}`}>
                  {formErrors.global}
                </p>
              )}
            </div>
            <DialogActions>
              <Button onClick={handleConnexion} color="primary" type="submit">
                Connexion
              </Button>
            </DialogActions>
          </form>
          <Button
            onClick={forgotPassword}
            disableElevation
            className={classes.forgotPasswordBtn}
          >
            Mot de passe oublié?
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Connexion;
