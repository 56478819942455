import React, { useState } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: '0px',
        backgroundColor: 'transparent',
        borderRadius: '4px',

        '& .MuiInputBase-root': {
            top: '-50px',
            right: '22px',
            height: 0,
            zIndex: -1,
            height: 0,
            opacity: 0,
        },
        '& .MuiSelect-root': {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 0
        },
        '& .MuiInputLabel-outlined': {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: 0
        },
    },
    selectButton: {
        height: '44px',
        color: 'white',
        borderRadius: '30px',
        margin: 0,
        minWidth: '150px',
        fontWeight: 700,
        fontSize: '15px',
        padding: '0 16px'
    },
    menuPaper: {
        top: '25px',
        minWidth: '150px!important',
        backgroundColor: '#e5077f',
        color: "white",
        boxShadow: 'none',
        margin: '0',
        borderRadius: '15px',
        border: '2px solid #e5077f',
        '& .MuiMenuItem-root': {
            color: 'white',
            backgroundColor: '#e5077f',
            fontWeight: 500,
            fontSize: '1em',
            paddingBottom: '10px',
            textAlign: 'center',
            margin: '0',
            '&:hover': {
                color: '#e5077f',
                backgroundColor: 'white',
            },
            '&.Mui-selected': {
                backgroundColor: 'white',
                color: '#e5077f',
            }
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SignupMenu({onSelectEvent}) {
    const classes = useStyles();
    const [registrationType, setRegistrationType] = useState('');
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const handleRegistrationChange = (event) => {
        setOpen(false);
        setRegistrationType('');
        history.push(event.target.value);
        if(typeof onSelectEvent != undefined){
            onSelectEvent(false)
        }
    };

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;


    const handleMouseEnter = () => {
        if (!isTouchDevice) setOpen(true);
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>

            <FormControl
                variant="outlined"
                className={`${classes.menuButton} ${classes.formControl}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Button  color="secondary" className={classes.selectButton} onClick={handleOpen} onMouseEnter={handleMouseEnter} size="small">
                    S'inscrire gratuitement
                </Button>
                <Select
                    id="registration-type-select"
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    value={registrationType}
                    onChange={handleRegistrationChange}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        classes: { paper: classes.menuPaper }
                    }}
                >
                    <MenuItem value="/candidat">Je suis un candidat</MenuItem>
                    <MenuItem value="/entreprise">Je suis une entreprise</MenuItem>
                    <MenuItem value="/partenaire">Je deviens partenaire</MenuItem>
                </Select>
            </FormControl>
        </>

    );
}
