import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getCitiesSearch = (value) => (
  axios.get(`${apiUrl()}/world_cities?query=${value}*`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getCitiesSearch;
