import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Card, CardHeader, Checkbox, FormControlLabel, List, FormGroup, ListItem
} from '@material-ui/core';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    width: '100%',
  },
  reverselinkEntreprise: {
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#e5087e',
    borderRadius: '55px',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',
    textDecoration: 'none',
    padding: '0 20px',
    width: 'fit-content',
    margin: '16px 0 40px 16px!important',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '& span': {
      fontSize: '1.3rem!important',
    },

    '&:hover': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      boxShadow: ' 2px 1px 1px 0 rgba(0, 0, 0, 0.1)',
      textDecoration: 'none',
    },
    '&:focus, &.focus': {
      color: '#e5087e',
      backgroundColor: '#ffffff',
      borderColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
      textDecoration: 'none',
    },
  },
  profileTabTitle: {
    '& span:first-child': {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: '#E5087E',
    },
    '& span:last-child': {
      fontWeight: 500,
      fontSize: '1.05rem',
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
  checkboxElement: {
    '& .MuiFormControlLabel-label ': {
      fontWeight: 'normal',
      fontSize: '1.1rem',
    }
  }
}));

const ServicesSelection = (props) => {

  const {
    locationInfo,
  } = props;

  const classes = useStyles(theme);
  const [offeredServices, setOfferedServices] = useState([]);
  const [userRequestedService, setUserRequestedService] = useState([]);
  const { user, refreshUserData } = useSessionContext();
  const { SnackBar } = useSessionContext();
  const {
    informSuccess,
    informError
  } = SnackBar;

  const fetchData = () => {
    fetch('/get/services/list')
      .then(response => response.json())
      .then(data => setOfferedServices(data.name))
      .catch((error) => {
        informError('Une erreur est survenue');
        console.error(
          `Une erreur est survenue: ${error.errorMessage}`,
          error.errorStatus,
        );
      });
  };

  const fetchUserRequestedServices = () => {
    fetch('/get/user/requested/service/list')
      .then(response => response.json())
      .then(data => setUserRequestedService(data.data))
      .catch((error) => {
        informError('Une erreur est survenue');
        console.error(
          `Une erreur est survenue: ${error.errorMessage}`,
          error.errorStatus,
        );
      });
  };

  useEffect(() => {
    refreshUserData();
    fetchData();
    fetchUserRequestedServices();
  }, []);

  const saveRequestedServiceData = (e, listItem) => {
    let url = '/service/user/create';
    if (userRequestedService.some(e => e.requested_service_id === listItem.id)) {
      url = '/service/user/delete';
    }
    refreshUserData();
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        world_city_id: locationInfo[0].id,
        requested_service_id: e.target.value,

      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          return Promise.reject({
            errorMessage: data.error_message,
            errorStatus: response.status,
          });
        }
        setUserRequestedService(data.data);
        informSuccess('Succès!');
      })
      .catch((error) => {
        informError('Une erreur est survenue');
        console.error(
          `Une erreur est survenue: ${error.errorMessage}`,
          error.errorStatus,
        );
      });
  };

  const renderCheckList = () => {
    return offeredServices.map((listItem) => <FormControlLabel className={classes.checkboxElement} key={listItem.id}
      control={<Checkbox
        checked={userRequestedService.some(e => e.requested_service_id === listItem.id)}
        onChange={(e) => saveRequestedServiceData(e, listItem)}
        value={listItem.id} />}
      label={listItem.name} />);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      component="form"
    >
      <List component="nav" className={classes.root}>
        <ListItem>
          <Card square className={classes.rootCard} elevation={0} variant="outlined">
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <CardHeader
                  title="Services partenaire"
                  subheader="Sélectionner les services qui vous intéressent"
                  className={classes.profileTabTitle}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <FormGroup style={{ paddingLeft: 16 }}>
                  {renderCheckList()}
                </FormGroup>
              </Grid>
            </Grid>
          </Card>
        </ListItem>
      </List>
    </Grid>
  );
};

export default ServicesSelection;
