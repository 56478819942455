import React, { useEffect } from "react";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "../../packs/ga-utils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  cookie: {
    "& *": {
      fontSize: "1.2rem",
    },
    "& > div": {
      minHeight: "10vh",
      alignItems: "center!important",
      boxSizing: "border-box",
      padding: "0 5vh",
    },
    "& div": {
      fontFamily: ["Dosis", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    "& button": {
      padding: "15px 20px!important",
      borderRadius: "55px!important",
      boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.3)",
      minWidth: "280px",
      "&:first-of-type": {
        backgroundColor: "#a23062!important",
      },
      "&:nth-of-type(2)": {
        backgroundColor: "#50ea99!important",
      },
    },
  },
}));

const Cookie = () => {
  const classes = useStyles();
  const theme = useTheme();
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className={classes.cookie}>
      <CookieConsent
        enableDeclineButton
        declineButtonText="Je refuse"
        onAccept={handleAcceptCookie}
        buttonText="J'accepte   "
        onDecline={handleDeclineCookie}
      >
        <Typography>
          Ce site utilise des cookies pour améliorer l’expérience utilisateur.
        </Typography>
      </CookieConsent>
    </div>
  );
};

export default Cookie;
