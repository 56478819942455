import React, { useEffect, useState } from 'react';
import {
  Grid, Button, Fab, TextField, makeStyles, Select, FormControl, MenuItem,Checkbox, FormControlLabel
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import validator from 'validator/es';
import theme from '../../../theme';
import useSessionContext from '../../../sessions/useSessionContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },

  form: {
    width: 'auto',
    marginTop: theme.spacing(4),
  },
  '& > *': {
    zIndex: 100,
    position: 'relative',
  },

  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  checkboxLabel:{
    '& .MuiFormControlLabel-label':{
      fontWeight: 'normal',
      fontSize: '0.85em'
    }
    
  },
  containerClass:{
    '& .special-label':{
      fontFamily: 'Dosis!important'
    }
  },
  phoneInputClass:{
    width: '100%!important',
    fontFamily: 'Dosis!important',
    '&:focus':{
      borderColor: '#e5087e!important',
      boxShadow: 'none!important',
    }
  }
}));

const ModifyInfo = (props) => {
  const { userInfo, entepriseEdition } = props;
  const [open, setOpen] = React.useState(false);
  const {
    refreshUserData,
    updateUser
  } = useSessionContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    companyWebsite: '',
    phoneNumber: null,
    global: null
  });

  const validateForm = (verifyAll = false) => {
    const errors = {};

    if(!entepriseEdition){
      if (formData.firstName || verifyAll) {
        if (formData.firstName === null) {
          errors.firstName = 'Le prénom est requis. (entre 2 et 15 caractères)';
        } else if (!validator.isLength(formData.firstName, {
          min: 2,
          max: 15
        })) {
          errors.firstName = 'Le prénom n\'est pas valide. (entre 2 et 15 caractères)';
        }
      }
  
      if (formData.lastName || verifyAll) {
        if (formData.lastName === null) {
          errors.lastName = 'Le nom de famille est requis. (entre 2 et 15 caractères)';
        } else if (!validator.isLength(formData.lastName, {
          min: 2,
          max: 15
        })) {
          errors.lastName = 'Le nom de famille n\'est pas valide. (entre 2 et 15 caractères)';
        }
      }
  
      if (formData.email || verifyAll) {
        if (formData.email === null) {
          errors.email = 'L\'adresse courriel est requise. (votrecourriel@email.com)';
        } else if (!validator.isEmail(formData.email)) {
          errors.email = 'L\'adresse courriel n\'est pas valide. (votrecourriel@email.com)';
        }
      }
    }else{
      if (userInfo.companyName || verifyAll) {
        if (formData.companyName === null) {
          errors.companyName = 'Le nom de votre entreprise est requis.';
        } else if (!validator.isLength(formData.companyName, {
          min: 2,
          max: 30
        })) {
          errors.companyName = 'Le nom de votre entreprise n\'est pas valide. (entre 2 et 30 caractères)';
        }
      }
  
      if (formData.companyWebsite != null && formData.companyWebsite.trim() != '') {
        if (!validator.isURL(formData.companyWebsite)) {
          errors.companyWebsite = 'L\'adresse de votre site web n\'est pas valide. . (https://votresite.com)';
        }
      }
      if (formData.phoneNumber!=null && formData.phoneNumber.trim()!='') {
        if (!validator.isLength(formData.phoneNumber, {
          min: 10,
          max: 15,
        })) {
          errors.phoneNumber = 'Votre numéro de téléphone n\'est pas valide.';
        }
      }
    }
   

    

    setFormErrors({
      ...errors,
      verifyAll
    });

    return Object.keys(errors).length < 1;
  };

  const onFormChange = (event) => {
    if(event.target.name=='global'){
      setFormData(
        {
          ...formData,
          [event.target.name]: event.target.checked
        },
      );
    }else{
      setFormData(
        {
          ...formData,
          [event.target.name]: event.target.value
        },
      );
    }
    
    validateForm();
  };

  useEffect(() => {
    setFormData({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      companyName: userInfo.enterpriseName,
      companyWebsite: userInfo.website,
      employeeCount: userInfo.employeeCount,
      global: userInfo.global
    });
  }, [userInfo]);

  const handleSubmit = () => {

    const raw = {
      user: {},
    };


    if (validateForm(true)) {
      if(!entepriseEdition){
        raw.user = {
          email: formData.email,
          first_name: formData.firstName,
          last_name: formData.lastName,
        }
      }else{
        raw.user = {
          website: formData.companyWebsite,
          phone_number: formData.phoneNumber,
          enterprise_name: formData.companyName,
          employee_count: formData.employeeCount,
          is_global: (typeof formData.global != 'undefined' && formData.global != '') ? (formData.global ? 1 : 0) : undefined
        }
      }
      updateUser(raw)
        .then((response) => {
          if (response.status === 400) {
            const errors = {};
            if (response.data.error) {
              setFormErrors({
                ...errors,
                verifyAll: true
              });
            }
          }
          if (response.status === 200) {
            refreshUserData();
            handleClose();
          }
        });
    }
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);

  const specificUserTypeFields = (uInfo) => {
    switch (uInfo.typeOfUser) {
      case 'Candidate':
        return null;
      case 'Partner':
        return (
          <>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                error={!!formErrors.companyName}
                required
                fullWidth
                id="companyName"
                value={formData.companyName || ''}
                label="Nom de l'entreprise"
                autoComplete="company-name"
                onChange={(event) => onFormChange(event)}
                onBlur={(event) => onFormChange(event)}
                name="companyName"
              />
              {formErrors.companyName && (
                <p className={`${classes.error} ${'error'}`}>
                  {formErrors.companyName}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                error={!!formErrors.companyWebsite}
                required
                fullWidth
                value={formData.companyWebsite || ''}
                label="Site web"
                name="companyWebsite"
                autoComplete="company-site"
                onChange={(event) => onFormChange(event)}
                onBlur={(event) => onFormChange(event)}
              />
              {formErrors.companyWebsite && (
                <p className={`${classes.error} ${'error'}`}>
                  {formErrors.companyWebsite}
                </p>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
                        <PhoneInput
                          country={'ca'}
                          placeholder='Votre numéro de téléphone'
                          specialLabel='Numéro de téléphone'
                          value={formData.phoneNumber}
                          containerClass={`'MuiFormControl-root' ${classes.containerClass}`}
                          inputClass={classes.phoneInputClass}
                          onChange={phone =>  setFormData(
                            {
                              ...formData,
                              phoneNumber: phone,
                            })}
                        />
                      {formErrors.phoneNumber && (
                          <p className={`${classes.error} ${'error'}`}>
                            {formErrors.phoneNumber}
                          </p>
                        )}
                    </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(event) => onFormChange(event)}
                    name="global"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    error={formErrors.global}
                    value={formData.global}
                    defaultChecked={formData.global}
                    required
                  />
                )}
                label={
                  <p>
                    Service disponible partout dans le monde
                  </p>

                }
                className={classes.checkboxLabel}
              />
            </Grid>
            
          </>
        );
      case 'Enterprise':
        return (
          <>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={!!formErrors.companyName}
                required
                fullWidth
                id="companyName"
                value={formData.companyName || ''}
                label="Nom de l'entreprise"
                autoComplete="company-name"
                onChange={(event) => onFormChange(event)}
                onBlur={(event) => onFormChange(event)}
                name="companyName"
              />
              {formErrors.companyName && (
                <p className={`${classes.error} ${'error'}`}>
                  {formErrors.companyName}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                error={!!formErrors.companyWebsite}
                fullWidth
                value={formData.companyWebsite || ''}
                label="Site web"
                name="companyWebsite"
                autoComplete="company-site"
                onChange={(event) => onFormChange(event)}
                onBlur={(event) => onFormChange(event)}
              />
              {formErrors.companyWebsite && (
                <p className={`${classes.error} ${'error'}`}>
                  {formErrors.companyWebsite}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
                        <PhoneInput
                          country={'ca'}
                          placeholder='Votre numéro de téléphone'
                          specialLabel='Numéro de téléphone'
                          value={formData.phoneNumber}
                          containerClass={`'MuiFormControl-root' ${classes.containerClass}`}
                          inputClass={classes.phoneInputClass}
                          onChange={phone =>  setFormData(
                            {
                              ...formData,
                              phoneNumber: phone,
                            })}
                        />
                      {formErrors.phoneNumber && (
                          <p className={`${classes.error} ${'error'}`}>
                            {formErrors.phoneNumber}
                          </p>
                        )}
                    </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl variant="outlined">
                <Select
                  value={formData.employeeCount || ''}
                  name="employeeCount"
                  onChange={(event) => onFormChange(event)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Veuillez choisir le nombre d'employés
                  </MenuItem>
                  <MenuItem value={'Moins de 10 employés'}>Moins de 10 employés</MenuItem>
                  <MenuItem value={'10 à 49 employés'}>10 à 49 employés</MenuItem>
                  <MenuItem value={'50 à 249 employés'}>50 à 249 employés</MenuItem>
                  <MenuItem value={'250 à 499 employés'}>250 à 499 employés</MenuItem>
                  <MenuItem value={'Plus de 500 employés'}>Plus de 500 employés</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Fab color="secondary" aria-label="modify" onClick={handleClickOpen}>
        <EditIcon fontSize="small" id="editProfileInfo" />
      </Fab>
      <Dialog
        className={classes.root}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modifier profil"
      >
        <DialogContent>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 0',
            }}
          >
            <Fab
              color="secondary"
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <DialogTitle>Modifier les informations</DialogTitle>
          </Grid>

          <form className={classes.form}>
            <Grid container spacing={2}>
              {!entepriseEdition ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.firstName}
                      required
                      fullWidth
                      id="firstName"
                      value={formData.firstName}
                      label="Prénom"
                      autoComplete="given-name"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                      name="firstName"
                    />
                    {formErrors.firstName && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.firstName}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.lastName}
                      required
                      fullWidth
                      value={formData.lastName || ''}
                      label="Nom de famille"
                      name="lastName"
                      autoComplete="family-name"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                    />
                    {formErrors.lastName && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.lastName}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.email}
                      required
                      fullWidth
                      value={formData.email || ''}
                      label="Courriel"
                      name="email"
                      onChange={(event) => onFormChange(event)}
                      onBlur={(event) => onFormChange(event)}
                      autoComplete="email"
                    />
                    {formErrors.email && (
                      <p className={`${classes.error} ${'error'}`}>
                        {formErrors.email}
                      </p>
                    )}
                  </Grid>
                </>
              ) : (
                specificUserTypeFields(userInfo)
              )}

            </Grid>
          </form>

          <DialogActions>
            <Button onClick={handleSubmit}>Valider</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModifyInfo;
