import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Footer from '../UI-Component/Footer';
import CorporateQuizForm from '../Form/CorporateQuizForm';

const useStyles = makeStyles((theme) => ({
  root:{},
  bgHero: {
    height: '20vh',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right, #7063798a, #7063798a, rgb(232 22 129 / 43%), rgb(232 22 129 / 32%), rgb(232 22 129 / 28%))',
    marginTop: 80,
  },
  homeContainer: {
    position: 'absolute',
    top: '30%',
    left: 0,
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    display:'flex',
    justifyContent: 'center',
    display: 'inline-grid',
    
    '& h1': {
      color: theme.palette.primary.main,
      marginTop: 0
    },
  },
  contentContainer: {
    paddingTop: "40px",
    paddingBottom: "50px"
  },
}));

const CorporateInfoForm = () => {
  const classes = useStyles(theme);

  return (
    <>
    <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
            <div className={classes.bgHero}>
                <Container maxWidth={false} className={classes.homeContainer}>
                  <Typography variant="h1">Offre Corporative pour Regroupements d’Entreprises</Typography>
                </Container>
            </div>
            <Grid container className={classes.contentContainer}>
              <Grid item md={2} xs={12}>
              </Grid>
              <Grid item md={8} xs={12}>
                <Grid item lg={12} className={classes.referralContainer}>
                    <CorporateQuizForm/>
                </Grid>
              </Grid>
            </Grid>
        </div>
      <Footer />
    </main>
    </>
  );
};

export default CorporateInfoForm;
