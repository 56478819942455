import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import { StateProvider } from './providers/StateProvider';
import ContextReducer from './reducer/ContextReducer';
import SessionContext from './sessions/SessionContext';
import useUser from './users/useUser';
import AppBody from './AppBody';
import useSnackBar from './useSnackBar';

export default () => {
  const initialState = {
    logged_in: false,
    authToken: null,
    userInfo: null,
  };

  const [showNewsLetterMaster, setShowNewsLetterMaster] = useState(true);
  const SnackBar = useSnackBar();

  const userUsages = useUser();
  const sessionContext = {
    ...userUsages,
    showNewsLetterMaster,
    setShowNewsLetterMaster,
    SnackBar
  };

  return (
    <StateProvider reducer={ContextReducer} initialState={initialState}>
      <SessionContext.Provider value={sessionContext}>
        <MuiThemeProvider theme={theme}>
          <AppBody />
        </MuiThemeProvider>
      </SessionContext.Provider>
    </StateProvider>
  );
};
