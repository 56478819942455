import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getJobRequirements = (job_id) => (
  axios.get(`${apiUrl()}/jobs?requirements=${job_id}`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getJobRequirements;
