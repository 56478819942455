import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';
import theme from '../../theme';
import useSessionContext from '../../sessions/useSessionContext';
import { TabPanel } from './TabPanel';
import Profile from './Profile';
import PretPersonnel from './PretPersonnel';
import ServicesSelection from './ServicesSelection';
import PersonalityTest from './Personality';
import PersonalInformation from './PersonalInformation';
import ProfileProgress from './ProfileProgress';
import InfoCompletion from './InfoCompletion';
import AccountInfo from './AccountInfo';


function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& span': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
  },

  profilTypo: {
    textAlign: 'left',
  },
  profilCard: {
    padding: '15px',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      '&:first-of-type': {
        order: 0,
        alignItems: 'flex-start',
        paddingLeft: 20,
      },
      '&:nth-of-type(2)': {
        order: 2,
        padding: 20,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-of-type(2)': {
        order: 3,
      },
      '&:nth-of-type(3)': {
        order: 2,
      },
    },
    '& a': {
      color: '#000!important',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  expTabs: {
    '& button': {
      color: '#000',
      width: 'fit-content',
    },
    '& > span': {
      height: 5,
    },
  },
  infoIcon: {
    alignSelf: 'flex-start',
    marginTop: '10px',
  },
  infoLink: {
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  player: {
    height: '100%',
    width: '100%',
  },
  modalCard: {
    marginTop: '5vw',
    aspectRatio: '16/9',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  infoJobsBox: {
    width: '100%',
    marginTop: '2vh',
  },
  cVBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 4,
    textAlign: 'center',
  },
  infoJobsCard: {
    backgroundColor: theme.palette.secondary.main,
  },
  connexionCard: {
    backgroundColor: 'white',
  },
  infoConnexionCardText: {
    color: 'black',
  },
  infoJobsText: {
    color: theme.palette.secondary.secondary,
  },
  submitCurriculumButton: {
    marginTop: '1rem !important',
    marginBottom: '3vh !important',
  },
  disabledButton: {
    backgroundColor: 'grey',
  },
}));

const UserProfile = () => {
  const classes = useStyles(theme);
  const { user } = useSessionContext();
  const [value, setValue] = React.useState(0);
  const [profileInfo, setProfileInfo] = React.useState({
    typeOfUser: '',
    profilePic: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    enterpriseName: '',
    jobs: [],
    languages: [],
    baseCountry: [],
    destinations: [],
    website: '',
    baseLocation: [],
    partner_locations: [],
    partner_services: [],
    isJobImported: false,
    numberCandidatesOrEnterprises: 0,
    metierWithMostCandidatesOrEnterprises: '',
    isSubscribed: 0,
    subscribedTo: {},
    linkedCv: [],
    cvFilename: null,
    phoneNumber: null,
  });

  const handleChange = function (event, newValue) {
    setValue(newValue);
  };

  useEffect(() => {
    const otherUserData = () => {
      let otherData = {};
      switch (user.type_of_user) {
        case 'Candidate':
          otherData = {
            baseLocation: user.candidate_location,
            jobs: user.job_experience,
            destinations: user.candidate_destinations,
            metiers: user.metiers,
            matches: user.matches,
            linkedCv: user.linked_cv ?? [],
            cvFilename: user.cv_filename,
          };
          break;
        case 'Enterprise':
          otherData = {
            enterpriseName: user.enterprise_name,
            phoneNumber: user.phone_number,
            website: user.website,
            jobs: user.job_locations,
            destinations: user.job_locations,
            metiers: user.metiers,
            matches: user.matches,
            isSubscribed: user.is_subscribed,
            subscribedTo: user.subscribed_to,
          };
          break;
        case 'Partner':
          otherData = {
            enterpriseName: user.enterprise_name,
            phoneNumber: user.phone_number,
            website: user.website,
            partner_locations: user.partner_locations,
            partner_services: user.user_services,
            global: user.global
          };
      }
      return otherData;
    };
    const userInfo = {
      id: user.id,
      typeOfUser: user.type_of_user,
      profilePic: user.profile_pic,
      fullName: user.full_name,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      languages: user.languages,
      isJobImported: user.is_job_imported,
      numberCandidatesOrEnterprises: user.number_candidates_or_enterprises,
      metierWithMostCandidatesOrEnterprises:
        user.metier_with_most_candidates_or_enterprises,
      ...otherUserData(),
    };
    
    setProfileInfo(userInfo);
  }, [user]);


  return (
    <Grid container>
      <AppBar
        position="relative"
        className={classes.menuTabs}
        style={{ backgroundColor: '#f9f9f9' }}
      >
        <Grid
          container
          style={{
            padding: '20px 15px',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.captionValue}>Information profil</Typography>
          </Grid>
          <Grid item sm={2}>
            <ProfileProgress progressValue={user.profile.current} />
          </Grid>
          <Grid item sm={10}>
            <InfoCompletion data={user.profile.data} />
          </Grid>
        </Grid>
        <Tabs
          className={classes.expTabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          <Tab
            className="editProfile"
            aria-label="Profil"
            label="Profil"
            {...a11yProps(0)}
          />
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Tab
              className="editContract"
              aria-label="Informations personnelles"
              label="Informations personnelles"
              {...a11yProps(1)}
            />
          ) :
            (profileInfo.typeOfUser === 'Enterprise' ? (
              <Tab
                className="editProfile"
                aria-label="Profil"
                label="Prêt de personnel"
                {...a11yProps(1)}
              />
            ) : null)}
          {profileInfo.typeOfUser === 'Candidate' ? (
            <Tab
              className="candidatePersonality"
              aria-label="candidatePersonality"
              label="Réseaux sociaux"
              {...a11yProps(2)}
            />
          ) :
            (profileInfo.typeOfUser === 'Enterprise' ? (
              <Tab
              className="editServicesSelection"
              aria-label="ServicesSelect"
              label="Services"
              {...a11yProps(2)}
            />
            ) : null)}
          {profileInfo.typeOfUser === 'Candidate' ? (
             <Tab
             className="editServicesSelection"
             aria-label="ServicesSelect"
             label="Services"
             {...a11yProps(3)}
           />
          ) :
            (profileInfo.typeOfUser === 'Enterprise' ? (
              <Tab
                className="listOfValues"
                label="Paramètre compte"
                {...a11yProps(3)}
              />
            ) : null)}
          {profileInfo.typeOfUser === 'Candidate' && (
            <Tab
            className="listOfValues"
            label="Paramètre compte"
            {...a11yProps(4)}
          />
          )}
          {profileInfo.typeOfUser === 'Candidate' ? (
            null
          ) :
            (profileInfo.typeOfUser === 'Enterprise' ? (
              null
            ) : (
              <Tab
                className="listOfValues"
                label="Paramètre compte"
                {...a11yProps(1)}
              />
            ))}
        </Tabs>
      </AppBar>
      <AppBar position="relative" className={classes.menuTabs} square>
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        {profileInfo.typeOfUser === 'Candidate' && (
          <>
            <TabPanel value={value} index={1}>
              <PersonalInformation />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PersonalityTest />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ServicesSelection
                typeOfUser={profileInfo.typeOfUser}
                locationInfo={profileInfo.destinations}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AccountInfo
                profileInfo={profileInfo}
              />
            </TabPanel>
          </>
        )}

        {profileInfo.typeOfUser === 'Enterprise' && (
          <>
            <TabPanel value={value} index={1}>
              <PretPersonnel
                metiers={profileInfo.metiers}
                isImported={profileInfo.isJobImported}
                jobs={profileInfo.jobs}
                typeOfUser="Enterprise"
                isSubscribed={profileInfo.isSubscribed}
                subscribedTo={profileInfo.subscribedTo.name}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ServicesSelection
                typeOfUser={profileInfo.typeOfUser}
                locationInfo={profileInfo.destinations}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AccountInfo
                profileInfo={profileInfo}
              />
            </TabPanel>

          </>
        )}
        {profileInfo.typeOfUser == 'Partner' && (
          <TabPanel value={value} index={1}>
            <AccountInfo
              profileInfo={profileInfo}
            />
          </TabPanel>
        )}
      </AppBar>
    </Grid>
  );
};

export default UserProfile;
