import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getMetiersSearch = (value,type="metier") => (
  axios.get(`${apiUrl()}/metiers?query=${value}*&type=${type}`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getMetiersSearch;
