import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Grid,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import theme from '../theme';
import useSessionContext from '../sessions/useSessionContext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoaderSpinner from './LoaderSpinner';
import TouchAppIcon from '@material-ui/icons/TouchApp';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: 'flex',
    },
  },

  shareMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '0!important',
    borderRadius: '50px!important',
    border: 0,
    background: 0,
    '&:focus': {
      boxShadow: '0 0 0 2pt #999',
      outline: 0,
    },
    '&  svg': {
      width: '30px',
      height: '30px',
      borderRadius: '50px',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    textAlign: 'left',
    backgroundColor: theme.palette.primary.main,
    color: '#37383e',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    boxShadow: 0,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  ButtonModalStyle: {
    color: '#ffffff',
    backgroundColor: '#E5087E',
    width: 38,
    height: 38,
    padding: 10,
    borderRadius: 50,

    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    '&:focus, &.focus': {
      backgroundColor: '#ffffff',
      color: '#E5087E',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
}));

export default function MetierActivator(props) {
  const { metier, subscribedTo, activeArray } = props;
  const classes = useStyles(theme);
  const { refreshUserData, updateUser } = useSessionContext();
  const [open, setOpen] = React.useState(false);
  const [formErrors, setFormError] = useState({});
  const [activationLimit, setActivationLimit] = useState(0);


  const refreshPage = () =>  {
    window.location.reload();
    return(
      <LoaderSpinner sizeOfSpinner={150} text="Activation du métier en cours"/>
    )
  }

  useEffect(() => {
    if (subscribedTo != null || ''){
      if(subscribedTo === 'Plan Start-up' || subscribedTo === 'Start-up'){
        setActivationLimit(2)
      }
      if(subscribedTo === 'Plan croissance' || subscribedTo === 'Croissance') {
        setActivationLimit(3)
      }
      if(subscribedTo === 'Maturité'){
        setActivationLimit(5)
      }
      if(subscribedTo === 'Veille & Stratégie'){
        setActivationLimit(10)
      }
    }
  }, [activationLimit]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const inactiveMetier = (metier) => {
    let umid = metier.user_metier_id

    const raw = {
      user: {
        user_metier_id: umid,
        active_status: false,
        update_status: true,
      },
    };
    updateUser(raw).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.log(' error deactivating metier');
        }
      }

      if (response.status === 200) {
        refreshUserData();
        refreshPage();
        setOpen(false);
      }
    });
  };

  const reactivateMetier = (metier) => {
    let umid = metier.user_metier_id
    const raw = {
      user: {
        user_metier_id: umid,
        active_status: true,
        update_status: true
      },
    };
    updateUser(raw).then((response) => {
      if (response.status === 400) {
        if (response.data.error) {
          console.error(response.data.error);
        }
      }

      if (response.status === 200) {
        refreshUserData();
        refreshPage();
        setOpen(false);

      }
    });
  };

  const renderStatusButton = () => {
    if (metier.is_active === true) {
      return (
        <Fab id={`activateJob-${metier.user_metier_id}`} color="secondary" onClick={handleClickOpen}>
          <CheckCircleIcon/>
        </Fab>
      )
    } else {
      if(activeArray.length === activationLimit){
        return(
          <Fab id={`activateJobDisabled-${metier.user_metier_id}`} color="secondary" onClick={handleClickOpen} disabled={true}>
          </Fab>
        )
      }
      else {
        return(
          <Fab id={`activateJob-${metier.user_metier_id}`} color="secondary" onClick={handleClickOpen}>
            <TouchAppIcon/>
          </Fab>
        )
      }
    }
  }


  const renderDialogStatus = (metier) => {
    if (metier.is_active === true) {
      return (
        <DialogContent>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 0',
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                  disabled
                >
                  <CloseIcon/>
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <DialogTitle style={{ textAlign: 'center' }}>
                  Désirez-vous rendre ce métier inactif ?
                </DialogTitle>
              </Grid>
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Button
                  className={classes.reverseButtonStyle}
                  color="primary"
                  onClick={() => inactiveMetier(metier)}
                >
                  Confirmer
                </Button>
                <Button
                  onClick={handleClose}
                >
                  Annuler
                </Button>
              </DialogActions>
            </Grid>
          </form>
          <div style={{
            position: 'relative',
            top: 20,
            textAlign: 'center'
          }}>
            {formErrors.global && (
              <p className={`${classes.error} ${'error'}`}>
                {formErrors.global}
              </p>
            )}
          </div>
        </DialogContent>
      );
    } else {
      return (
        <DialogContent>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 0',
                }}
              >
                <Fab
                  color="secondary"
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon/>
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <DialogTitle style={{ textAlign: 'center' }}>
                  Désirez-vous réactiver ce métier ?
                </DialogTitle>
              </Grid>
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Button
                  className={classes.reverseButtonStyle}
                  color="primary"
                  onClick={() => reactivateMetier(metier)}
                >
                  Confirmer
                </Button>
                <Button
                  onClick={handleClose}
                >
                  Annuler
                </Button>
              </DialogActions>
            </Grid>
          </form>
          <div style={{
            position: 'relative',
            top: 20,
            textAlign: 'center'
          }}>
            {formErrors.global && (
              <p className={`${classes.error} ${'error'}`}>
                {formErrors.global}
              </p>
            )}
          </div>
        </DialogContent>
      );
    }
  }


  return (
    <div className={classes.connexionContainer}>
      {renderStatusButton()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {renderDialogStatus(metier)}
      </Dialog>
    </div>
  );
}
