import React, {useState, useEffect} from "react";
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  Hidden,
  Grid,
  Link,
} from '@material-ui/core';
import NavMobile from './NavMobile';
import DisplayBannerButton from './DisplayBannerButton';
import ConnexionBox from './ConnexionBox';
import SignupMenu from './SignupMenu';
import FaqMenu from './FaqMenu';
import useSessionContext from '../sessions/useSessionContext';
import logo from "../../images/Logo-Moovejob.png";
import logoRecrutement from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-recrutement.png";
import logoSousTraitance from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-sous-traitance.png";
import logoPretPersonnel from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-pret-de-personnel.png";
import logoImmigrantInvestisseur from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-investisseur.png";
import theme from '../theme';
import PricingMenu from "./PricingMenu";
import ReferenceMenu from "./ReferenceMenu";
import ServiceMenu from "./ServiceMenu";

const logos = {
  '/accueil': logo,
  '/service/recrutement-international': logoRecrutement,
  '/service/sous-traitance': logoSousTraitance,
  '/service/pret-de-personnel': logoPretPersonnel,
  '/service/immigrant-investisseur': logoImmigrantInvestisseur
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  navStyle:{
    '& .MuiGrid-root':{
      height: '100px',
    }
  },
  iconAction:{
    marginRight: 5,
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
    '& a:hover': {
      textDecoration: 'none'
    },
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `center`,
    margin: '0px',
    height: '100%',
    '& li': {
      justifyContent: 'center',
      margin: '0px',
      padding: '0px',
      width: '100%!important',
      textAlign: 'center'
    },
  },
  headerLink: {
    color: 'white',
    width: "100%",
  },
  linkCandidat: {
    backgroundColor: '#e5087e',
    textDecoration: 'none',
    padding: '0 20px',
  },
  linkDemo: {
      color: '#e5087e',
      textTransform: 'none',
      fontSize: '15px'
    
  },
  linkEntreprise: {
    backgroundColor: 'white',
    textDecoration: 'none',
    padding: '0 20px',
    borderRadius: '20px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    color: '#e5087e',
  },
  linkPartner: {
    backgroundColor: 'white',
    textDecoration: 'none',
    padding: '0 20px',
    borderRadius: '20px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    color: '#e5087e',
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
    whiteSpace: 'nowrap',
    fontSize: "1.05em"
  },
  logoStyle: {
    maxWidth: "230px"
  },
  navBtnAction:{
    paddingRight: '10px'
  },
  navActionMenu:{
    justifyContent: 'right',
    '& li': {
      width: 'fit-content!important',
      margin: '0px 5px'
    },
  }
}));
export default function NavBar() {
  const classes = useStyles();
  const activeStyle = { color: '#000' };
  const history = useHistory();
  const location = useLocation();
  const { user, signout, SnackBar } = useSessionContext();
  const { informSuccess } = SnackBar;
  const [showScroll, setShowScroll] = useState(false)
  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    const path = location.pathname;
    setCurrentLogo(logos[path] || logo);
  }, [location.pathname]);

  const mySignout = () => {
    signout().then(() => {
      informSuccess('Déconnecté avec succès');
      history.push('/');
    });
  };


  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 100){
      setShowScroll(false)
    }
  };
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;



  const scrollTop = () =>{
    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  };


  window.addEventListener('scroll', checkScrollTop)

  return (
    <div className={classes.navStyle} >
      <Hidden mdDown>
        <Grid container className={classes.root}>
          <Grid item lg={7} md={7}>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navDisplayFlex}
            >
                  <ListItem>
                    <div>
                      <Grid container justifyContent="center" alignItems="center">
                          <NavLink to="/accueil" className="scrollTop" onClick={scrollTop} >
                            <img src={currentLogo} alt="logo" className={classes.logoStyle} />
                          </NavLink>
                      </Grid>
                    </div>
                  </ListItem>
               
              {user && user.is_admin && (
                <>
                  <ListItem alignItems="center">
                    <Link
                      href="/admin"
                      className={classes.linkText}
                    >
                      <ListItemText>Admin</ListItemText>
                    </Link>
                  </ListItem>

                  <ListItem alignItems="center">
                    <Link
                      href="/admin-comfy"
                      className={classes.linkText}
                    >
                      <ListItemText>Blog-admin</ListItemText>
                    </Link>
                  </ListItem>
                </>
              )}
              <ListItem alignItems="center">
                <NavLink
                  to="/blog"
                  activeStyle={activeStyle}
                  className={classes.linkText}
                >
                  <ListItemText>Blog</ListItemText>
                </NavLink>
              </ListItem>
              <ListItem>
                  <PricingMenu/>
              </ListItem>
              <ListItem>
                  <FaqMenu/>
              </ListItem>
              <ListItem>
                <ReferenceMenu/>
              </ListItem>
              <ListItem>
                  <ServiceMenu/>
              </ListItem>
            </List>
          </Grid>
          <Grid item lg={5} md={5} className={classes.navBtnAction}>
            <List  component="nav" className={`${classes.navDisplayFlex} ${classes.navActionMenu}`}>
              {!user ? (
                <>
                  <ListItem
                  >
                     <Fab 
                      variant="extended" 
                      size="medium"
                      title="demo"
                      className={classes.iconAction}
                      >
                      <Link className={classes.linkDemo} href={"https://calendly.com/moovejob/demo"} target="_blank" color={theme.palette.primary.main}>
                        Demander une démo
                      </Link>
                    </Fab>
                  </ListItem>
                  <ListItem>
                    <ConnexionBox/>
                  </ListItem>
                  <ListItem>
                    <SignupMenu/>
                  </ListItem>
                </>
              ):(
                <>
                  <ListItem
                  >
                    <DisplayBannerButton />
                  </ListItem>
                  <ListItem
                  >
                    <Fab 
                    variant="extended" 
                    size="medium"
                    color="secondary"
                    title="Connexion"
                    className={classes.iconAction}
                    onClick={() => history.push('/mon-compte')}
                  >
                    <AccountCircleIcon />
                    Mon compte
                  </Fab>
                  </ListItem>
                  <ListItem>
                  <Fab 
                    variant="extended" 
                    size="medium"
                    color="secondary"
                    title="Déconnexion"
                    className={classes.iconAction}
                    onClick={mySignout}
                  >
                    <ExitToApp />
                    Se déconnecter
                  </Fab>
                  </ListItem>
                </>
              )}
              
            </List>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <NavMobile />
      </Hidden>
    </div>
  );
}
