export function errorHandling(error) {
  let mistake;
  if (error.response) {
    // Request made and server responded
    mistake = {
      data: error.response.data,
      status: error.response.status,
    };
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  return mistake;
}
