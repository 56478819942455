import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey'; // Importer la couleur grise

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    alignItems: 'center',
  },
  captionValue: {
    width: '102px',
    height: '102px',
    fontSize: '2em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  orange: {
    color: '#ff9800'
  },
  red: {
    color: '#f44336'
  },
  green: {
    color: '#4caf50'
  },
  backgroundCircle: {
    color: grey[300], // Couleur de fond grise
    position: 'absolute',
    top: 0,
    left: 0,
  }
}));

const ProfileProgress = ({ progressValue }) => {
  const classes = useStyles();

  const getColor = () => {
    if (progressValue < 25) {
      return classes.red;
    } else if (progressValue >= 25 && progressValue < 75) {
      return classes.orange;
    } else if (progressValue >= 75) {
      return classes.green;
    }
  };

  return (
    <Box className={classes.root}>
      <Typography variant="caption" className={classes.captionValue} component="div" color="textSecondary">
        {`${progressValue}%`}
      </Typography>
      
      <CircularProgress
        variant="determinate"
        value={100}
        size={102}
        thickness={4}
        className={classes.backgroundCircle}
      />
      
      <CircularProgress
        variant="determinate"
        value={progressValue}
        size={102}
        thickness={4}
        className={`${classes.progress} ${getColor()}`}
      />
    </Box>
  );
};

export default ProfileProgress;
