import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getUserMetier = (user_metier_id) => (
  axios.get(`${apiUrl()}/user_metiers/${user_metier_id}`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getUserMetier;
