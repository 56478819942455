import React from 'react';
import {
  Typography, Table, TableHead, TableBody, TableRow, TableCell, Chip, Fab, Select, MenuItem,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table:{
    '& .MuiTableCell-head':{
      fontSize: '1.1em'
    },
    '& .MuiTableCell-body':{
      fontSize: '1em'
    },
  },
  actionCell: {
    '& .MuiInputBase-root': {
      right: '22px',
      height: 0,
      zIndex: -1,
      opacity: 0,
    },
    '& .MuiSelect-root': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .MuiInputLabel-outlined': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  menuPaper: {
    minWidth: '150px!important',
    backgroundColor: 'white',
    color: "white",
    boxShadow: 'none',
    margin: '0',
    borderRadius: '15px',
    textAlign: 'center',
    border: '1px solid #c8c8c8',
    '& .MuiMenuItem-root': {
      fontWeight: 500,
      fontSize: '1em',
      paddingBottom: '10px',
      textAlign: 'center',
      margin: '0',
    },
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
    whiteSpace: 'nowrap',
    fontSize: "1.05em"
  },
  title: {
    fontSize: "1.4em",
    textAlign: 'left',
    marginLeft: '16px'
  },
  activeChip:{
    backgroundColor: '#e5087e',
    color: "white",
    fontSize: "1em"
  },
  defaultChip:{
    backgroundColor: '#555e68',
    color: "white",
    fontSize: "0.9em"
  }
}));

const SubscriptionTable = ({ subscriptions, openActionMenu, closeActionMenu, handleActionChange, modalOpenId, action, formatDate }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant='h2' className={classes.title} color="textSecondary" gutterBottom>
        Mes abonnements
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Tarif</TableCell>
            <TableCell align="center">Facturation</TableCell>
            <TableCell align="center">Période</TableCell>
            <TableCell align="center">État</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((sub) => (
            <TableRow key={sub.id}>
              <TableCell align="center">{sub.product} - ({sub.duration} mois)</TableCell>
              <TableCell align="center">{sub.allow_renew ? 'Renouv. auto' : 'Manuelle'}</TableCell>
              <TableCell align="center">{formatDate(sub.current_period_start)} - {formatDate(sub.current_period_end)}</TableCell>
              <TableCell align='center'><Chip label={sub.label_status} className={sub.status === 'active' ? classes.activeChip : classes.defaultChip} /></TableCell>
              <TableCell className={classes.actionCell} align="center">
                <>
                  <Fab
                    color="secondary"
                    onClick={() => openActionMenu(sub.id)}
                  >
                    <MoreHorizIcon />
                  </Fab>
                  <Select
                    open={modalOpenId === sub.id}
                    onOpen={() => openActionMenu(sub.id)}
                    onClose={() => closeActionMenu(sub.id)}
                    value={action}
                    onChange={(e) => handleActionChange(e, sub)}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                        classes: { paper: classes.menuPaper }
                      }
                    }}
                  >
                    <MenuItem value="invoice" className={classes.linkText}>Télécharger dernière facture</MenuItem>
                    {sub.status == 'active' &&
                    (
                      sub.allow_renew ?
                      <MenuItem value="renew" className={classes.linkText}>Annuler le renouvellement automatique</MenuItem>
                      : <MenuItem value="renew" className={classes.linkText}>Activer le renouvellement automatique</MenuItem>
                      )}
                  </Select>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default SubscriptionTable;
