import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Fab,
  Link,
} from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ExitToApp from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Instagram from '@material-ui/icons/Instagram';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from "../../images/Logo-Moovejob.png";
import logoRecrutement from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-recrutement.png";
import logoSousTraitance from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-sous-traitance.png";
import logoPretPersonnel from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-pret-de-personnel.png";
import logoImmigrantInvestisseur from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-investisseur.png";
import DisplayBannerButton from './DisplayBannerButton';
import ConnexionBox from './ConnexionBox';
import useSessionContext from '../sessions/useSessionContext';
import SignupMenu from './SignupMenu';
import FaqMenu from './FaqMenu';
import PricingMenu from './PricingMenu';
import ReferenceMenu from './ReferenceMenu';
import ServiceMenu from './ServiceMenu';

const logos = {
  '/accueil': logo,
  '/service/recrutement-international': logoRecrutement,
  '/service/sous-traitance': logoSousTraitance,
  '/service/pret-de-personnel': logoPretPersonnel,
  '/service/immigrant-investisseur': logoImmigrantInvestisseur
};

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
  },
  drawerFull: {
    '& div': {
      width: '100%',
      textAlign: 'right',
    },

    '& > div > div': {
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      padding: '0 16px',
      '& img': {
        width: '250px',
        padding: '15px'
      },
    },
    '& nav': {
      marginTop: '2vh',
    },
    '& li': {
      justifyContent: 'center',
      textAlign: 'center',
      paddingBottom: 14,
    },
    '& button': {
      boxShadow: 'none',
      width: 40,
    },
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      boxShadow: 'none',
    },
  },
  list: {
    width: 250,
  },
  socialMedia: {
    backgroundColor: '#e5087e',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    '& svg': {
      color: '#fff',
    },
    '& li': {
      width: '60px',
      justifyContent: 'space-around',
    },
  },
  listAction: {
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '& button': {
      marginRight: '0!important',
    },
  },

  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
  },
  dropDown :{
    paddingBottom: 14,
    display: 'flex',
    alignItems: 'center',
    
    '& div':{
      textAlign: 'center!important',
    },
    '& .MuiFormControl-root':{
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiButtonBase-root': {
      width: 'fit-content',
      padding: '0px 12px',
      
    },
    '& .MuiButton-label':{
fontSize: '19px'
    }
  },
  navBtnAction: {
    display: 'flex',
    alignItems: 'center',
    
    '& div':{
      textAlign: 'center!important',
    },
    '& .MuiFormControl-root':{
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiButtonBase-root': {
      width: 'fit-content',
      padding: '0px 12px',
      
    },
  },
  iconAction:{
    marginRight: 5,
    boxShadow: 'none',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '2px solid #e5087e',
    color: '#e5087e',
    backgroundColor: "white",
  },
});

const SideDrawer = () => {
  const classes = useStyles();
  const location = useLocation();
  const activeStyle = { color: '#000' };
  const [state, setState] = useState(false);
  const { user, signout } = useSessionContext();
  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    const path = location.pathname;
    setCurrentLogo(logos[path] || logo);
  }, [location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };
  return (
    <>
      <Grid container className={classes.root}>
        <List
          component="nav"
          aria-labelledby="main navigation"
          className={classes.navDisplayFlex}
        >
          <ListItem
            className={classes.listAction}
          >
            
            <div className={classes.menuButton}>
              <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
                <Menu fontSize="large" style={{ color: `black` }} />
              </IconButton>
            </div>
          </ListItem>
        </List>

        <Drawer
          className={classes.drawerFull}
          anchor="right"
          open={state}
          onClose={toggleDrawer(false)}
        >
          <div className={classes.drawerHeader}>
            <Link to="/accueil">
              <img src={currentLogo} alt="moovejob" className={classes.logoStyle} />
            </Link>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon fontSize="large" style={{ color: `black` }} />
            </IconButton>
          </div>
          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
          >
            
            {user && user.is_admin && (
              <>
                <ListItem>
                  <Link
                    href="/admin"
                    activeStyle={activeStyle}
                    className={classes.linkText}
                  >
                    <ListItemText>Admin</ListItemText>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link
                    href="/admin-comfy"
                    activeStyle={activeStyle}
                    className={classes.linkText}
                  >
                    <ListItemText>Blog-admin</ListItemText>
                  </Link>
                </ListItem>
              </>
            )}
            <span onClick={toggleDrawer(false)}>
              <DisplayBannerButton></DisplayBannerButton>
            </span>
            
            <ListItem>
              <HashLink
                smooth
                to="/accueil#about"
                className={classes.linkText}
                onClick={toggleDrawer(false)}
              >
                <ListItemText>À propos</ListItemText>
              </HashLink>
            </ListItem>
            
            <ListItem>
              <NavLink
                to="/blog"
                className={classes.linkText}
                onClick={toggleDrawer(false)}
              >
                <ListItemText>Blog</ListItemText>
              </NavLink>
            </ListItem>
            <div className={classes.dropDown}>
                <PricingMenu onSelectEvent={toggleDrawer()}/>
            </div>
            <div className={classes.dropDown}>
                  <FaqMenu onSelectEvent={toggleDrawer()}/>
            </div>
            <div className={classes.dropDown}>
                <ReferenceMenu onSelectEvent={toggleDrawer()}/>
            </div>
            <div className={classes.dropDown}>
                <ServiceMenu onSelectEvent={toggleDrawer()}/>
            </div>
            {user ? (
              <>
               <ListItem
                  >
                    <NavLink
                      to="/mon-compte"
                      className={classes.linkText}
                      onClick={toggleDrawer(false)}
                    >
                      <Fab 
                        variant="extended" 
                        size="medium"
                        color="secondary"
                        title="Connexion"
                        className={classes.iconAction}
                      >
                        <AccountCircleIcon />
                        Mon compte
                      </Fab>
                    </NavLink>
                    
                  </ListItem>
                  <ListItem>
                  <Fab 
                    variant="extended" 
                    size="medium"
                    color="secondary"
                    title="Déconnexion"
                    className={classes.iconAction}
                    onClick={signout}
                  >
                    <ExitToApp />
                    Se déconnecter
                  </Fab>
                  </ListItem>
              </>
            ):(
              <>
                <ListItem>
                  <div className={classes.navBtnAction}>
                    <SignupMenu onSelectEvent={toggleDrawer()}/>
                  </div>
                    
                </ListItem>
                <ListItem>
                  <div className={classes.navBtnAction}>
                    <ConnexionBox onSelectEvent={toggleDrawer()} />
                  </div>
                </ListItem>
              </>
            )}
          </List>
          
          <List className={classes.socialMedia}>
            <ListItem>
              <a
                href="https://www.facebook.com/Moovejob-101051321734106"
                target="_blank"
              >
                <FacebookIcon fontSize="large" />
              </a>
            </ListItem>
            <ListItem>
              <a
                href="https://www.linkedin.com/company/moovejob/"
                target="_blank"
              >
                <LinkedInIcon fontSize="large" />
              </a>
            </ListItem>
            <ListItem>
              <a
                href="https://www.youtube.com/channel/UCcDGma2mZYQAshTojQ4hAnA"
                target="_blank"
              >
                <YouTubeIcon fontSize="large" />
              </a>
            </ListItem>
            <ListItem>
              <a href="https://instagram.com/moovejob/" target="_blank">
                <Instagram fontSize="large" />
              </a>
            </ListItem>
          </List>
        </Drawer>
      </Grid>
    </>
  );
};

export default SideDrawer;
