import React, { useState } from 'react';
import { css } from '@emotion/react';
import PuffLoader from 'react-spinners/PuffLoader';
import { makeStyles, Typography } from '@material-ui/core';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 25,
  },
  text: {
    color: "#e5087e",
  }
}));

function LoaderSpinner(props) {
  const { sizeOfSpinner, text } = props;
  const [loading] = useState(true);
  const [color] = useState('#e5087e');
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <PuffLoader color={color} loading={loading} css={override} size={sizeOfSpinner} />
      <Typography variant="h2" className={styles.text}>{text}</Typography>
    </div>
  );
}


export default LoaderSpinner;
