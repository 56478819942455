import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { getServicesSearch } from '../Functions/Get-query/getServicesSearch';

const useStyles = makeStyles(() => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const parseFromElasticSearchResults = (results) => {
  const parsedServicesArray = [];
  results.forEach((res) => {
    parsedServicesArray.push({
      id: res._source.id,
      name: res._source.name,
    });
  });
  return parsedServicesArray;
};

const ServicesAutoComplete = ({
  name,
  label,
  onFormChange,
  formData,
  formErrors,
  errors,
  userServices,
  serviceModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) setServicesList([]);
  }, [isOpen]);

  const onTextChanged = (event) => {
    const serviceToRemove = [];
    let listDestinationsWithOutAlreadySelected = null;
    let services;
    if (serviceModal) {
      services = userServices;
    } else {
      services = formData.user_services;
    }

    const query = event.target.value;
    if (query === null) {
      setServicesList([]);
      setIsOpen(false);
      return;
    }

    setLoading(true);
    getServicesSearch(query).then((response) => {
      if (services !== null) {
        services.forEach((element) => {
          serviceToRemove.push(element.id);
        });
        listDestinationsWithOutAlreadySelected = response.results
          .filter((service) => !serviceToRemove.includes(service._source.id));
      }

      if (response.total > 0) {
        const listToParse = listDestinationsWithOutAlreadySelected || response.results;
        const newList = parseFromElasticSearchResults(listToParse);
        setServicesList(newList);
        setIsOpen(true);
      }
      setLoading(false);
    });
  };

  const handleServiceSelection = (event, data) => {
    if (data === null) {
      return onFormChange({
        target: {
          name,
          value: []
        }
      });
    }

    const serviceArray = data.map((d) => ({
      id: d.id,
      name: d.name
    }));

    return onFormChange({
      target: {
        name: 'services',
        value: serviceArray
      }
    });
  };
  const classes = useStyles();

  const close = () => {
    setServicesList([]);
  };

  return (
    <>
      <Autocomplete
        id={name}
        multiple
        noOptionsText="En chargement..."
        onOpen={onTextChanged}
        autoSelect
        onClose={close}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        options={servicesList}
        loading={isLoading}
        key={(option) => option.id}
        onChange={handleServiceSelection}
        PopperComponent={(props) => (
          <Popper
            {...props}
            placement="bottom-start"
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onTextChanged}
            onBlur={onTextChanged}
            error={!!errors[name]}
            variant="outlined"
            label={label}
            placeholder=""
          />
        )}
      />
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

export default ServicesAutoComplete;
