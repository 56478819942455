import React, { Fragment } from 'react';
import { Typography, Grid, FormControl, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';
import MetiersAutoComplete from '../../MetiersAutoComplete';
import MetierSelect from '../../MetierSelect';
import WorkTypeAutoComplete from '../../WorkTypeAutoComplete';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  reverseButtonStyle: {
    color: '#e5087e',
    backgroundColor: '#ffffff',
    borderRadius: '55px',
    border: 'none',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.3)',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#e5087e',
    },
    '&:focus, &.focus': {
      color: '#fff',
      backgroundColor: '#e5087e',
      boxShadow: '0 0 0 .2rem rgba(#FF1C96, 0.2)',
      borderWidth: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  smallTitle: {
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    textShadow: 'none',
    color: 'black',
    fontSize: '1.3rem',
    fontWeight: '300',
    margin: '2vh 0',
  },
  orLine: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'abosolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '1px',
      backgroundColor: '#000',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
  },
  html: {
    height: '100%',
    margin: 0,
  },
  switch: {
    '& .MuiIconButton-root': {
      padding: '10px!important'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '1em',
      fontWeight: 'normal',
    }
    
  }
}));

const JobsStep = ({
  userType,
  errors,
  onFormChange,
  formData
}) => {
  const classes = useStyles(theme);

  const handleChange = (event) => {
    onFormChange({
      target: {
        name: 'byWorkType',
        value: event.target.checked,
      },
    });
  };

  return (
    <Fragment>
      
      <Grid item xs={12}>
      {userType != 'Referrer' && (
        <Typography variant="h2" className={classes.smallTitle}>
        {userType === 'Enterprise' && (
          <>
          Sélectionnez le/les métiers que vous recherchez (max 5)
          </>
        )}
        {userType === 'Candidate' && (
          <>
          Sélectionnez votre (vos) profession(s) (3 max)
          </>
        )}
        </Typography>
      )}
        {userType === 'Enterprise' && (
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                className={classes.switch}
                control={<Switch checked={formData.byWorkType} disabled={formData.jobs!=null && formData.jobs.length>0} onChange={handleChange} />}
                label="Filtrer la liste des métiers selon une compétence"
              />
            </FormGroup>
          </FormControl>
        )}
        {formData.byWorkType && (
          <>
            <WorkTypeAutoComplete
              name="workType"
              onFormChange={onFormChange}
              errors={errors}
              formData={formData}
            />
            <br/>
          </>
        )}

        {userType === 'Referrer' ? (
          <MetierSelect
            userType={userType}
            name="jobs"
            onFormChange={onFormChange}
            errors={errors}
            formData={formData}
          />
        ):(
          <MetiersAutoComplete
            userType={userType}
            limit={userType === 'Enterprise' ? 5 : 3}
            name="jobs"
            multiple
            onFormChange={onFormChange}
            errors={errors}
            formData={formData}
          />
        )}
       
      </Grid>
    </Fragment>
  );
};

export default JobsStep;
