import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getMetiersSearchByWorkType = (work_type_id,value,type="metier") => (
  axios.get(`${apiUrl()}/metier/${work_type_id}?query=${value}*&type=${type}`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getMetiersSearchByWorkType;
