import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CardHeader,
    Typography,
    Grid,
    Card,
    CardContent,
    List,
    ListItem
} from '@material-ui/core';
import theme from '../../theme';
import ModifyInfo from './FormModal/modifyInfo';

const useStyles = makeStyles((theme) => ({
    rootCard: {
        width: '100%',
    },
    profileTabTitle: {
        '& span': {
            fontWeight: 600,
            fontSize: '1.25rem',
            color: '#E5087E',
        },
    },
    labelSubtitle: {
        fontWeight: 600
    },
    textValue: {
        fontWeight: '400 !important'
    }
}));

const AccountInfo = (props) => {
    const classes = useStyles(theme);
    const { profileInfo } = props;
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={classes.root}
            component="form"
        >
            <List className={classes.root}>
                <ListItem divider>
                    <Grid container className={classes.root}>
                        <Card square className={classes.rootCard} elevation={0} variant="outlined">
                            <Grid container alignItems="center">
                                <Grid item xs={10}>
                                    <CardHeader
                                        title='Paramètre compte'
                                        className={classes.profileTabTitle}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '20px 0',
                                    }}
                                >
                                    <ModifyInfo userInfo={profileInfo} entepriseEdition={false} />

                                </Grid>
                            </Grid>
                        </Card>
                        <Card square className={classes.rootCard} elevation={0} variant="outlined">
                            <Grid item xs={12}>
                                <CardContent>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary" className={classes.labelSubtitle}>
                                                Nom
                                            </Typography>
                                            <Typography className={classes.textValue}>
                                                {profileInfo.lastName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary" className={classes.labelSubtitle}>
                                                Prénom
                                            </Typography>
                                            <Typography variant="body1" color="textPrimary" className={classes.textValue}>
                                                {profileInfo.firstName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="subtitle2" color="textSecondary" className={classes.labelSubtitle}>
                                                Email
                                            </Typography>
                                            <Typography variant="body1" color="textPrimary" className={classes.textValue}>
                                                {profileInfo.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </Grid>
                        </Card>
                    </Grid>
                </ListItem>
            </List>
        </Grid>
    );
};

export default AccountInfo;
