import { Typography } from '@material-ui/core';
import React from 'react';

export default () => (
  <div style={{ width: '100%', textAlign: 'center' }}>
    <Typography variant="body2" style={{ fontSize: '14px' }}>
      Copyright &copy; {new Date().getFullYear()} moovejob.
    </Typography>
  </div>
);
