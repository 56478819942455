import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Link as LinkComp
} from '@material-ui/core';
import Pdf from '../../images/presentation_plateforme.pdf';
import PdfInvestisseur from '../../images/presentation_immagration_entrepreneur_quebec.pdf';
import ProgrammeAmbassadeur from '../../images/programme_ambassadeur.pdf';
import { HashLink } from 'react-router-hash-link';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Instagram from '@material-ui/icons/Instagram';
import logo from '../../images/Logo-Moovejob.png';
import FormNewsletter from './NewsletterConfirmation';
import Copyright from './Copyright';
import useSessionContext from '../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  flexHeightDesktop: {
    height: 'auto',
    justifyContent: 'center',
    padding: '10vh 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  flexAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        color: '#E5087E',
      },
    },
  },
  reverseButtonStyle: {
    color: '#E5087E',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
    '& a': {
      color: '#E5087E',
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        color: '#ffffff',
      },
    },
  },

  bgGrey: {
    backgroundColor: theme.palette.secondary.secondary,
    overflowX: 'hidden',
  },
  copyRight: {
    '& > *': {
      fontSize: '14px',
      textDecoration: 'none',
      textTransform: 'lowercase',
      color: 'black',
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& p': {
      textAlign: 'center',
      margin: '0 auto',
    },
  },
  logoStyle: {
    maxWidth: '300px',
  },
  linkText: {
    color: '#000',
    fontSize: '1.2em',
    fontWeight: 600,
    fontFamily: 'Dosis',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'flex-end',
    lineHeight: '1.5em'
  },
  linkTextMail: {
    color: '#000',
    fontSize: '1.3rem',
    fontWeight: 600,
    fontFamily: 'Dosis',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'flex-end',
    paddingTop: '16px',
  },
  linkTextHidden: {
    color: '#000',
    fontSize: '1.2em',
    fontWeight: 600,
    fontFamily: 'Dosis',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'flex-end',
    lineHeight: '1.5em',
    display: 'none'
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '3vh',
    '& svg': {
      color: '#e5087e',
    },
    '& li': {
      width: '80px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '16.6%',
      },
    },
  },
  footerLink: {
    color: '#000',
    fontSize: '1.2em',
    fontWeight: 'normal',
    fontFamily: 'Dosis',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'flex-end',
    lineHeight: '1.5em'
  },
}));

export default () => {
  const classes = useStyles();

  const { showNewsLetterMaster, user } = useSessionContext();
  const [showNewsLetterSlave, setShowNewsLetterSlave] = useState(false);

  useEffect(() => {
    setShowNewsLetterSlave(showNewsLetterMaster);
    return () => setShowNewsLetterSlave(false);
  }, [showNewsLetterMaster]);

  const showNewsLetter = () => {
    const location = useLocation();
    if (
      user ||
      location.pathname === '/candidat' ||
      location.pathname === '/enterprise' ||
      location.pathname === '/partenaire'
    ) {
      return null;
    }
    return showNewsLetterSlave && <FormNewsletter />;
  };

  return (
    <footer className={classes.bgGrey}>
      <Grid container>
        <Box className={classes.flexHeightDesktop}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <div className={classes.logoContainer}>
                <Link to="/accueil">
                  <img src={logo} alt="logo" className={classes.logoStyle} />
                </Link>
                <Link
                  to="/politique-de-confidentialite"
                  className={classes.linkText}
                  target="_blank"
                >
                  <Typography variant="body1">
                    Politique de confidentialité
                  </Typography>
                </Link>
                <Link
                  to="/forfaits-entreprise"
                  without="true"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.linkText}
                >
                  <Typography variant="body1">
                   Forfaits pour entreprises
                  </Typography>
                </Link>
                <Link
                  to="/forfaits-partenaire"
                  without="true"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.linkText}
                >
                  <Typography variant="body1">
                    Forfaits pour partenaires
                  </Typography>
                </Link>
                <Link to={ProgrammeAmbassadeur}
                      href={ProgrammeAmbassadeur}
                      without="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.linkText}
                >
                  <Typography variant="body1">
                    Programme Ambassadeur
                  </Typography>
                </Link>
                <LinkComp
                        href={Pdf}
                        without="true"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.linkText}
                      >
                          Télécharger notre brochure (PDF)
                      </LinkComp>
                      <LinkComp
                        href={PdfInvestisseur}
                        without="true"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.linkTextHidden}
                      >
                          Télécharger presentation programme investisseur
                      </LinkComp>
              </div>
              <div>
                <List className={classes.socialMedia}>
                  <ListItem>
                    <a
                      href="https://www.facebook.com/Moovejob-101051321734106"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon fontSize="large" />
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="https://www.linkedin.com/company/moovejob/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedInIcon fontSize="large" />
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="https://www.youtube.com/channel/UCcDGma2mZYQAshTojQ4hAnA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YouTubeIcon fontSize="large" />
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      href="https://instagram.com/moovejob/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram fontSize="large" />
                    </a>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.flexAround}>
                <List
                  component="nav"
                  aria-labelledby="main navigation"
                  className={classes.navDisplayFlex}
                >
                  <ListItem>
                    <HashLink
                      smooth
                      to="/accueil#about"
                      className={classes.linkText}
                    >
                      <ListItemText>À propos</ListItemText>
                    </HashLink>
                  </ListItem>
                  <ListItem>
                    <HashLink
                      smooth
                      to="/accueil#avantages"
                      className={classes.linkText}
                    >
                      <ListItemText>Avantages</ListItemText>
                    </HashLink>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                    primary="FAQ"
                    secondary={
                      <React.Fragment>
                        <Typography variant="subtitle1">
                            <HashLink
                              smooth
                              to="/faq-candidat"
                              className={classes.footerLink}
                            >
                              Candidat
                            </HashLink>
                        </Typography>
                        <Typography variant="subtitle1">
                            <HashLink
                              smooth
                              to="/faq-entreprise"
                              className={classes.footerLink}
                            >
                              Entreprise
                            </HashLink>
                        </Typography>
                        <Typography variant="subtitle1">
                            <HashLink
                              smooth
                              to="/faq-partenaire"
                              className={classes.footerLink}
                            >
                              Partenaire
                            </HashLink>
                        </Typography>
                        <Typography variant="subtitle1">
                            <HashLink
                              smooth
                              to="/faq-parrainage"
                              className={classes.footerLink}
                            >
                              Parrainage
                            </HashLink>
                        </Typography>
                      </React.Fragment>
                    }
                    />
                    
                     
                  </ListItem>
                  <ListItem>
                    <HashLink
                      smooth
                      to="/parrainage-candidat"
                      className={classes.linkText}
                    >
                      <ListItemText>Parrainage</ListItemText>
                    </HashLink>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              {showNewsLetter()}
              <a
                href="mailto:communication@moovejob.com"
                className={classes.linkTextMail}
              >
                <EmailIcon color="secondary" fontSize="small" />
                communication@moovejob.com
              </a>
            </Grid>
          </Grid>
        </Box>
        <Copyright />
      </Grid>
    </footer>
  );
};
