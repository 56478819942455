import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({

  mainDiv: {
    marginTop: '100px',
    position: 'relative',
  },
  boxSize: {
    overflow: 'hidden',
    height: '50px',
    position: 'relative',
    display: 'flex',
    width: '100%!important',

  },
  legendText: {
    bottom: 'auto!important',
    top: '25%',
    fontSize: '20px!important',
    fontFamily: 'Dosis',
    opacity: '1!important',
    color: '#FFFFFF',

    [theme.breakpoints.down('xl')]: {
      bottom: 'auto!important',
      top: '60%!important',
      fontFamily: 'Dosis',
      fontSize: '20px!important',
      opacity: '1!important',

    },
    [theme.breakpoints.down('sm')]: {
      bottom: 'auto!important',
      top: '45%!important',
      fontFamily: 'Dosis',
      fontSize: '18px!important',
      opacity: '1!important',

    },
    [theme.breakpoints.down('xs')]: {
      bottom: 'auto!important',
      top: '35%!important',
      fontFamily: 'Dosis',
      fontSize: '18px!important',
      opacity: '1!important',

    },
  },
  carousel: {
    height: 'fit-content!important'
  },
  carouselImage: {
    height: '10vh',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

}));

const SLIDE_DELAY = 6000;

const NewsCarousel = () => {

  const classes = useStyles();
  const [slideIn, setSlideIn] = useState(true);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [count, setCount] = useState(0);
  const [listAllMessages, setListAllMessages] = useState([]);
  const [hover, setHover] = useState(false);
  const changeToSlide1Timer = useRef();
  const changeToSlide2Timer = useRef();
  const slide1Text1Timer = useRef();
  const startDate = useRef(0);
  const endDate = useRef(1);

  const getNewMetier = () => {
    fetch('/get_new_metier_list')
    .then((res) => res.json())
    .then(data => {
      if (data !== null && data.length > 0) {
        setListAllMessages(listAllMessages => ([...listAllMessages, ...data.map(({
          name: message,
          ...rest
        }) => ({
          message: `Nouveau métier et compétences mises à jour - ${message}`,
          ...rest
        }))]));
      }
    });
  };

  const getNewBlogPosts = () => {
    fetch('/get_new_posts_list')
    .then((res) => res.json())
    .then(data => {
      if (data !== null && data.length > 0) {
        setListAllMessages(listAllMessages => ([...listAllMessages, ...data.map(({
          title: message,
          ...rest
        }) => ({
          message: `Dernier article du Blog - ${message}`,
          ...rest
        }))]));
      }
    });
  };

  const getNewBannerMessages = () => {
    fetch('/get_new_messages_list')
    .then((res) => res.json())
    .then(data => {
      if (data !== null && data.length > 0) {
        setListAllMessages(listAllMessages => ([...listAllMessages, ...data]));
      }
    });
  };

  const cleanTimers = () => {
    clearTimeout(changeToSlide2Timer.current);
    clearTimeout(slide1Text1Timer.current);
    clearTimeout(changeToSlide1Timer.current);

  };

  useEffect(() => {
    getNewMetier();
    getNewBlogPosts();
    getNewBannerMessages();
  }, []);

  useEffect(() => {
    let timeLeft;
    let diference;
    if (hover) {
      clearTimeout(changeToSlide2Timer.current),
        clearTimeout(changeToSlide1Timer.current),
        endDate.current = Date.now();
    } else {
      clearTimeout(changeToSlide2Timer.current),
        clearTimeout(changeToSlide1Timer.current),
        diference = endDate.current - startDate.current,
        diference > SLIDE_DELAY ? diference = (SLIDE_DELAY - 1000) : null,
        timeLeft = SLIDE_DELAY - diference,
        slideIn ? changeToSlide2Timer.current = setTimeout(() => {
          setSelectedSlide('b');
        }, timeLeft) : changeToSlide1Timer.current = setTimeout(() => {
          setSelectedSlide('a');
        }, timeLeft);
    }
  }, [hover]);

  useEffect(() => {
    setSelectedSlide('a');
  }, []);

  useEffect(() => {

    const handleSlide1 = () => {
      if (listAllMessages.length > 0) {
        if (count == listAllMessages.length - 1) {
          setCount(0);
        } else {
          setCount(count => (count + 1));
        }
      } else {
        setCount(0);
      }
      setSlideIn(true);
      changeToSlide2Timer.current = setTimeout(() => {
        setSelectedSlide('b');
      }, SLIDE_DELAY);
    };
    const handleSlide2 = () => {
      setSlideIn(false);
      changeToSlide1Timer.current = setTimeout(() => {
        setSelectedSlide('a');
      }, 500);
    };
    const changeSlide = () => {
      startDate.current = Date.now();
      if (selectedSlide === 'a') {
        handleSlide1();
      }
      if (selectedSlide === 'b') {
        handleSlide2();
      }
    };
    changeSlide();

    return function cleanup() {
      cleanTimers();
    };
  }, [selectedSlide]);

  return (
    <>
      <div className={classes.mainDiv}>
        <Box className={classes.boxSize} style={{ backgroundColor: '#3F3F3FFF' }}
             onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <Slide in={slideIn} direction="right" className={classes.carouselImage}>
            <p style={{
              color: '#FFFFFF',
              textAlign: 'center',
              fontFamily: 'Dosis',
              textOverflow: 'ellipsis',
              width: '100%',
              fontSize: '1rem'
            }}>{listAllMessages.length > 0 ? listAllMessages[count].message : ''}</p>
          </Slide>
        </Box>
      </div>
    </>
  );
};

export default NewsCarousel;
