import React from 'react';
import { Container, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavProfile from '../UI-Component/Profile/BottomNavProfile';
import Footer from '../UI-Component/Footer';
import MainUserTabs from '../UI-Component/Profile/MainUserTabs';
import useSessionContext from '../sessions/useSessionContext';
import NewsCarousel from '../UI-Component/MainPageCarousel';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
      position: 'relative',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      paddingLeft: '2vh!important',
      paddingRight: '2vh!important',
    },
  },
  cardButton: {
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0 8px',

    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.secondary.main,
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { user } = useSessionContext();
  const { page } = useParams();

  if (!user) {
    return 'Loading...';
  }

  const typeOfUser = user.type_of_user;

  return (
    <main style={{ overflowX: 'hidden' }}>
      {NewsCarousel()}
      <Container maxWidth="lg" className={classes.profileContainer}>
        <Hidden smDown>
          <MainUserTabs activePage={page}/>
        </Hidden>
        <Hidden mdUp>
          <BottomNavProfile typeOfUser={typeOfUser} />
        </Hidden>
      </Container>
      <Hidden mdDown>
        <Footer />
      </Hidden>
    </main>
  );
};

export default Profile;
