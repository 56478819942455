import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
} from 'react-share';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { apiUrl } from '../Functions/apiUrl';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: 'flex',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
    },
  },
  logoStyle: {
    maxWidth: '15vw',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
    },
  },
  share: {
    minWidth: '50px',
    width: '50px',
    height: '50px',
    padding: 0,
    margin: 0,
  },
  shareContainer: {
    marginRight: '15px',
  },
  shareMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '0!important',
    borderRadius: '50px!important',
    border: 0,
    background: 0,
    '&:focus': {
      boxShadow: '0 0 0 2pt #999',
      outline: 0,
    },
    '&  svg': {
      width: '30px',
      height: '30px',
      borderRadius: '50px',
    },
  },
}));

export default function Share() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = function (event) {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let shareUrl = String(window.location.href);
  let mediaPin = `${apiUrl()}/logoMoovejob.png`;
 //
  function popitup() {
    let url = "https://www.facebook.com/sharer.php?u=" + shareUrl;
    let newwindow = window.open(url,'name','height=200,width=150');
    if (window.focus) {
      newwindow.focus()
    }
    return false;
  }

  return (
    <div className={classes.shareContainer}>
      <Fab
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
      >
        <ShareIcon />
      </Fab>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} >
        <Fab onClick={popitup} className={classes.shareButton}>
            <FacebookIcon />
        </Fab>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkedinShareButton url={shareUrl} className={classes.shareButton}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <TwitterShareButton url={shareUrl} className={classes.shareButton}>
            <TwitterIcon />
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <PinterestShareButton
            media={mediaPin}
            url={shareUrl}
            className={classes.shareButton}
          >
            <PinterestIcon />
          </PinterestShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <EmailShareButton url={shareUrl} className={classes.shareButton}>
            <EmailIcon />
          </EmailShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CopyToClipboard text={shareUrl}>
            <button className={classes.shareButton}>
              <FileCopyIcon />
            </button>
          </CopyToClipboard>
        </MenuItem>
      </Menu>
    </div>
  );
}
