import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/Logo-Moovejob.png';
import theme from '../theme';
import Footer from '../UI-Component/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    flexShrink: 0,
    flexBasis: '90%',
    marginLeft: '2vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(20),
      textAlign: 'left',
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  details: {
    flexDirection: 'column',
    textAlign: 'left',
    '&  *': {
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    '& span': {
      fontWeight: 400,
    },
    '& svg': {
      color: '#e5087e',
      fontSize: '1rem',
    },
    '& li': {
      alignItems: 'baseline',
    },
    '& h4': {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 600,
      margin: '2vh 0',
    },
  },
  homeContainer: {
    paddingTop: '15vh',
    paddingBottom: '10vh',
    '& p, & span': {
      textAlign: 'left',
    },
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '375px',
    paddingTop: '60px',
    '& p': {
      textAlign: 'left',
      margin: '0 0 0 30%',
    },
  },
  logoStyle: {
    maxWidth: '300px',
    margin: '0 auto',
  },
  logoStyleSmall: {
    maxWidth: '275px',
    margin: '0 auto',
  },
  slogan: {
    fontWeight: 'bold',
  },

  html: {
    height: '100%',
    margin: 0,
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
}));

const Politique = () => {
  const [expanded, setExpanded] = useState('' ? false : 'panel1');

  const handleChange = function (panel) {
    return function (event, isExpanded) {
      setExpanded(isExpanded ? panel : false);
    };
  };

  const classes = useStyles(theme);
  return (
    <main>
      <div>
        <Container maxWidth="lg" className={classes.homeContainer}>
          <Box>
            <div className={classes.logoContainer}>
              <img src={logo} alt="logo" className={classes.logoStyleSmall} />
            </div>
            <Typography variant="h1" color="secondary">
              Votre confiance est précieuse et nous en prenons soin!
            </Typography>
            <Typography variant="h2" className={classes.slogan}>
              Notre engagement
            </Typography>
            <Typography variant="body2">
              Pour nous chez moovejob, votre confidentialité est une priorité
              non négociable. Votre confidentialité est au cœur de nos
              pratiques, nous concevons, développons et offrons des services et
              des produits que vous appréciez et qui vous permettent de créer
              des connexions en totale confiance. Nous sommes conscients que
              vous nous partagez des informations importantes vous concernant et
              nous prenons cette confiance très au sérieux.
            </Typography>
            <Typography variant="body2">
              Pour vous assurer un service parfait, nous faisons appel à des
              experts en ressources humaines, des experts juridiques, des
              experts en sécurité, en conception de produits et en relation de
              travail afin de nous assurer qu'aucune décision n'est prise sans
              respecter votre vie privée. Nous nous efforçons à être toujours le
              plus transparents possible dans la façon dont nous traitons vos
              données. Chez moovejob nous voulons utiliser des termes simples et
              compréhensibles pour vous concernant nos politiques d’utilisation.
            </Typography>
            <Typography variant="body2">
              Notre objectif est que vous lisiez vraiment nos politiques, tout
              simplement! Sachez que nous mettons régulièrement nos stratégies
              de protection à jour pour nous permettre de rester toujours au top
              et nous vous conseillons de consulter l’évolution de nos
              politiques concernant la confidentialité, la sécurité et l'éthique
              de moovejob.
            </Typography>
            <Typography variant="h2" className={classes.slogan}>
              Politique de confidentialité
            </Typography>
            <Typography variant="h3">
              Bienvenue dans la section politique de confidentialité de
              moovejob.
            </Typography>
            <Typography variant="body2">
              Internet est un outil extraordinaire qui simplifie la connexion
              entre les humains. Il brise les frontières, les statuts et vous
              allez pouvoir le constater en utilisant notre plateforme.
            </Typography>
            <Typography variant="body2">
              Notre « Charte sur la protection de la vie privée » s’adresse à
              tous les utilisateurs internautes et se veut complémentaire aux «
              Conditions d’utilisation » de moovejob. Nous appliquons les
              différents principes en accord avec les bonnes pratiques mondiales
              en matière de protection de la vie privée et des documents
              électroniques de nos clients.
            </Typography>
            <Typography variant="body2">
              La présente Politique décrit nos pratiques de confidentialité en
              des termes simples, et en maintenant le jargon juridique et
              technique au minimum.
            </Typography>
            <Typography variant="body2">
              Notre politique évolue constamment en lien avec les pratiques les
              plus communes au niveau international.
            </Typography>
            <Typography variant="h3">Voici les grands chapitres :</Typography>
            <div className={classes.root}>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    1.
                  </Typography>
                  <Typography className={classes.heading}>
                    Qui sommes-nous?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    moovejob est une entreprise québécoise (Canada)
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    2.
                  </Typography>
                  <Typography className={classes.heading}>
                    Les informations que nous recueillons
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Il est obligatoire pour nous de récupérer un maximum
                    d’informations pour nous permettre de vous aider à réaliser
                    des connexions significatives vous concernant, telles que
                    les informations de base sur votre profil et les types de
                    postes, compétences et pays que vous recherchez.
                  </Typography>
                  <Typography>
                    Lorsque vous utilisez nos services, vous choisissez de nous
                    donner certaines informations. Cela inclut :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Lorsque vous créez un compte, vous nous livrez vos
                        identifiants de connexion, ainsi que certaines
                        informations de base nécessaires au fonctionnement du
                        service, telles que votre identité
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Informations personnelles du curriculum vitae
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Lors de l’enregistrement en ligne de votre profil sur
                        moovejob, nous exigerons la plus grande honnête et
                        transparence concernant les informations. Dans le cas de
                        mensonges, falsification d’informations nous suspendrons
                        votre compte immédiatement et sans remboursement si vous
                        avez acheté un forfait.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous pouvez en tout temps retirer votre profil en ligne.
                        Les informations personnelles recueillies resteront
                        stockées dans nos serveurs pendant une certaine période;
                        advenant le cas d’une éventuelle réutilisation de votre
                        part, ainsi que pour notre protection en cas de litiges
                        pouvant survenir.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Lors de l'inscription de vos informations personnelles,
                        vous avez la liberté de répondre ou non dans les champs
                        « non obligatoires ». Cependant, les champs identifiés «
                        non obligatoires » et non remplis peuvent restreindre
                        les services personnalisés que nous pourrions vous
                        proposer.
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    3.
                  </Typography>
                  <Typography className={classes.heading}>
                    Comment nous utilisons vos informations?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Vos informations personnelles servent principalement à :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Cibler les meilleures opportunités d’emploi pour les
                        candidats ou les meilleurs profils vers les entreprises
                        en lien avec les attentes de chacun
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous aider à trouver et recevoir plus facilement les
                        bons matchs, services et les informations adéquates
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Rendre l’application moovejob la plus intuitive et
                        facile possible à utiliser parce que vous n’aurez pas
                        besoin d’entrer vos informations une fois que vous serez
                        connecté
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous informer des nouvelles opportunités mises à jour et
                        des nouveaux services de nos partenaires
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Modifier ou mettre à jour votre profil
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous permettre de réaliser votre transaction lorsque
                        vous achetez un service
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous permettre éventuellement de participer à nos
                        enquêtes, sondages ou vous inscrire à un concours
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Vous permettre d’effectuer des vérifications ainsi que
                        différents tests de personnalité
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography>
                    À la suite de votre inscription sur moovejob, vous recevrez
                    un message dans votre boîte de réception afin de confirmer
                    votre demande. Pour activer votre compte, vous devrez
                    cliquer sur le lien que vous recevrez dans ce courriel. Vous
                    serez automatiquement redirigé vers notre site. Nous ne vous
                    demanderons, en aucun cas, une confirmation de votre mot de
                    passe par courrier électronique. Si toutefois vous receviez
                    une telle demande, ne répondez pas à ce message pour votre
                    protection, car ce courriel pourrait être une tentative de
                    hameçonnage. Nous n’avons aucun contrôle sur la
                    confidentialité de vos données personnelles diffusées par
                    vous ou par de tierces parties auprès d’autres sites
                    Internet.
                  </Typography>
                  <Typography>
                    Lorsque vous souscrivez à un service payé ou effectuez un
                    achat directement chez nous, vous nous fournissez, ou
                    fournissez à notre prestataire de services de paiement des
                    informations, telles que votre numéro de carte de débit ou
                    de crédit ou d'autres informations financières.
                  </Typography>
                  <Typography>
                    Lorsque vous choisissez de participer à nos événements ou
                    concours, nous recueillons les informations que vous
                    utilisez pour vous enregistrer ou pour vous inscrire.
                  </Typography>
                  <Typography>
                    Lorsque vous contactez notre service client, nous
                    recueillons les informations que vous nous donnez dans le
                    cadre de cette interaction. Parfois, nous surveillons ou
                    enregistrons ces interactions à des fins d’amélioration de
                    nos services et pour nous garantir une qualité de service
                    élevée.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    4.
                  </Typography>
                  <Typography className={classes.heading}>
                    Comment et à qui nous partageons vos informations?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Lorsque vous utilisez nos services, nous recueillons des
                    informations sur les fonctionnalités dont vous vous servez,
                    sur la façon dont vous les utilisez et sur les périphériques
                    grâce auxquels vous accédez à nos services. Voir ci-dessous
                    pour plus d'informations :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Informations sur votre utilisation nous recueillons des
                        informations à propos de votre activité sur nos services
                        et la manière dont vous les utilisez (par exemple, la
                        date et l'heure de votre connexion, les fonctions que
                        vous avez utilisées, les recherches, les clics et les
                        pages qui vous ont été présentées, l'adresse de la page
                        web de référence, les publicités sur lesquelles vous
                        cliquez) ainsi que sur la manière dont vous interagissez
                        avec les autres utilisateurs (par exemple, les
                        utilisateurs avec lesquels vous vous connectez et
                        interagissez, l'heure et la date de vos échanges, le
                        nombre de messages que vous envoyez et recevez)
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Informations sur votre appareil
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Autres informations avec votre consentement Si vous nous
                        en donnez l'autorisation, nous pouvons recueillir votre
                        géolocalisation précise (latitude et longitude) par
                        différents moyens, selon le service et l'appareil que
                        vous utilisez, notamment par GPS, Bluetooth ou connexion
                        Wi-F
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    5.
                  </Typography>
                  <Typography className={classes.heading}>
                    Cookies et autres technologies similaires de collecte de
                    données
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Nous utilisons et pouvons autoriser d'autres personnes à
                    utiliser des cookies et des technologies similaires (p. ex.,
                    des balises web, des pixels) afin de vous reconnaître et/ou
                    de reconnaître votre ou vos périphériques.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    6.
                  </Typography>
                  <Typography className={classes.heading}>
                    Transferts de données transfrontaliers
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Le partage des renseignements énoncé implique parfois des
                    transferts de données transfrontaliers, par exemple vers les
                    États-Unis d'Amérique et d'autres juridictions. À titre
                    d'exemple, lorsque le service permet aux utilisateurs d'être
                    localisés dans l'Espace Économique Européen (« E.E.E. »),
                    leurs informations personnelles sont transférées vers des
                    pays situés en dehors de l'E.E.E. Nous utilisons des clauses
                    contractuelles types approuvées par la Commission européenne
                    ou d'autres clauses de sauvegarde appropriées pour autoriser
                    des transferts de données depuis l'E.E.E. vers d'autres
                    pays. Les clauses contractuelles types sont des engagements
                    entre les sociétés transférant les données personnelles, les
                    liant pour protéger la confidentialité et la sécurité de vos
                    données.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    7.
                  </Typography>
                  <Typography className={classes.heading}>
                    Vos droits
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Nous voulons que vous contrôliez vos informations, et c'est
                    pour cela que nous avons fourni les outils suivants :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Outils d'accès et de mise à jour du service. Outils et
                        paramètres de compte qui vous aident à accéder,
                        rectifier ou supprimer les informations que vous nous
                        avez fournies et qui sont associées à votre compte
                        directement dans le service. Si vous avez des questions
                        sur ces outils et paramètres, veuillez contacter notre
                        service client pour obtenir de l'aide
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Suppression. Vous pouvez supprimer votre compte en
                        utilisant la fonctionnalité correspondante directement
                        sur le service
                      </ListItemText>
                    </ListItem>
                  </List>
                  <Typography>
                    Nous voulons que vous soyez informés de vos droits en
                    matière de confidentialité. Voici quelques points clés à
                    retenir :
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Consultation de vos informations. Les lois applicables
                        en matière de protection de la vie privée peuvent vous
                        donner le droit d'examiner les informations personnelles
                        que nous conservons à votre sujet (selon la juridiction,
                        cela peut être appelé le droit d'accès, le droit de
                        portabilité ou des variations de ces termes). Vous
                        pouvez obtenir une copie de vos informations
                        personnelles en nous écrivant à{' '}
                        <a hrer="mailto:communication@moovejob.com">
                          communication@moovejob.com
                        </a>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Pour votre protection et la protection de tous nos
                        utilisateurs, il se peut que nous vous demandions de
                        fournir une preuve d'identité avant que nous puissions
                        répondre aux demandes ci-dessus
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Important, il se peut que nous refusions les demandes
                        pour certaines raisons, et notamment si la demande est
                        illégale ou si elle peut enfreindre des secrets
                        commerciaux ou une propriété intellectuelle ou la
                        confidentialité d'un utilisateur. Si vous souhaitez
                        recevoir des informations relatives à un autre
                        utilisateur, telles qu'une copie de tous les messages
                        que vous avez reçus de sa part ou par l'intermédiaire de
                        notre service, l'autre utilisateur devra contacter notre
                        responsable de la protection de la vie privée pour lui
                        fournir son consentement écrit avant que les
                        informations ne soient publiées
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        Responsabilité. Dans certains pays, y compris dans
                        l'Union européenne, vous avez le droit de déposer une
                        plainte auprès de l'autorité compétente en matière de
                        protection des données en cas de doute sur la manière
                        dont nous traitons vos informations personnelles.
                        L'autorité de protection des données auprès de laquelle
                        vous pouvez déposer une plainte peut notamment être
                        celle de votre résidence habituelle, du lieu où vous
                        travaillez ou du lieu où nous sommes établis
                      </ListItemText>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.secondaryHeading}>
                    8.
                  </Typography>
                  <Typography className={classes.heading}>
                    Comment nous contacter?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography>
                    Si vous avez des questions sur la présente Politique de
                    confidentialité, vous pouvez nous contacter par courriel :{' '}
                    <a hrer="mailto:communication@moovejob.com">
                      communication@moovejob.com
                    </a>
                  </Typography>
                  <Typography variant="h4">Profils des utilisateurs</Typography>
                  <Typography>
                    Comme mentionné, chaque utilisateur inscrit a un profil
                    unique. Un numéro unique d’identification personnelle est
                    attribué à chaque profil, ce qui nous permet de nous assurer
                    qu’il n’y a que vous qui pouvez accéder à votre profil.{' '}
                  </Typography>
                  <Typography>
                    Dans le cas où moovejob fasse l’objet de vente ou de
                    transfert ou de fusion avec d’autres sociétés, vous nous
                    autorisez à partager et traiter vos informations
                    personnelles pour assurer le bon fonctionnement des
                    opérations sans interrompre la continuité des services.
                    Advenant qu’un tel évènement se produise, moovejob s’engage
                    à vous en informer dans un délai jugé raisonnable par
                    courrier électronique advenant qu’un tel évènement se
                    produise.
                  </Typography>
                  <Typography variant="h4">
                    Amélioration de notre plateforme
                  </Typography>
                  <Typography>
                    Vous voyez une erreur, fautes, oublie, manque (compétences,
                    villes, etc.) merci de nous envoyer immédiatement
                    l’information à{' '}
                    <a hrer="mailto:communication@moovejob.com">
                      communication@moovejob.com
                    </a>
                  </Typography>
                  <Typography>
                    Pour vous remercier, chaque année nous réaliserons un tirage
                    au sort pour les personnes nous ayant partager une
                    amélioration les prix vont de 200$ à 1000$ cad!
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        </Container>
      </div>
      <Footer />
    </main>
  );
};

export default Politique;
