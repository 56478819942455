import React, { useState,useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Button,
  ButtonGroup,
  Tooltip,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Footer from '../UI-Component/Footer';
import CheckIcon from '@material-ui/icons/Check';
import useSessionContext from '../sessions/useSessionContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: '#f0f4f8'
  },
  priceContainer:{
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    justifyContent: 'space-evenly',
    paddingBottom: '50px',

  },
  container:{
    marginTop: "10px",
    justifyContent: 'center'
  },
  pricingItem:{
    border: "2px dashed #e5087e",
    borderRadius: "20px",
    padding: '10px',
  },
  pricingHeader: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      '& ul':{
        paddingLeft: 15
      }
    },
  },
  pricingDescription: {
    borderRadius: '20px',
    marginTop: '15px',
    paddingBottom: '15px',
    backgroundColor: 'white'
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `none`,
    color: `black`,
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: '30px',
    textAlign: 'center',
    '& .MuiListItemAvatar-root':{
      minWidth: '30px'
    },
    '& .MuiListItemAvatar-root':{
      minWidth: '40px'
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      padding: '0px!important',
      '& .MuiListItemAvatar-root':{
        marginTop: '12px'
      }
    },
  },
  listItemText:{
    '& .MuiListItemText-primary':{
      fontSize: "1em",
      color: "#555e68"
    }
  },
  iconStyle:{
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    "& .MuiAvatar-img":{
      height: "unset"
    }
  },
  pricingAmount:{
    textAlign: "left",
    margin: "0",
    paddingLeft: "16px",
    fontWeight: "700",
    fontSize: "2em"
  },
  pricingAmountDesc: {
    color: "#636b74",
    fontSize: "1.2rem"
  },
  pricingTitle:{
    textAlign: 'left',
    padding: "10px",
    paddingLeft: "16px",
    color: "#636b74",
    fontSize: '0.9em',
    fontWeight: '600'
  },
  listOffer:{
    margin: '0px 15px',
    '& li:not(:last-child)':{
      borderBottom: '1px solid #eee'
    }
  },
  selectButton: {
    height: '44px',
    color: 'white',
    borderRadius: '30px',
    margin: 0,
    padding: '15px',
    minWidth: 'unset',
    width: 'auto',
    fontWeight: 700,
    fontSize: '15px',
  },
  offerName:{
    textAlign: 'left'
  },
  remarks: {
    textAlign: 'left',
    fontSize: '1em'
  },
  tooltip:{
    fontSize: "1em"
  },
  freePlanContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  freePlan: {
    display: 'flex',
    flexDirection: 'row',
    border: "2px dashed #e5087e",
    borderRadius: "20px",
    padding: '10px',
  },
  freePricingHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      paddingLeft: 15
    },
  },
  freePricingDescription: {
    backgroundColor: 'white',
  },
  freePricingAction: {
    backgroundColor: 'white',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      paddingBottom: 15
    },
  },
  buttonGroup: {
    '& button':{
      border: 0,
      fontWeight: 700,
      fontSize: '1.2em'
    },
    '& button.inactive':{
      backgroundColor: '#919191'
    },
    '& button:focus, & button:hover': {
      backgroundColor: '#e5087e',
      color: 'white'
    }
    
    
  }
}));

const Pricing = (props) => {
  const { type } = props;
  const classes = useStyles(theme);
  const [products, setProducts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState(null);
  const { user } = useSessionContext();
  const history = useHistory();


  const getData = () => fetch('/products/'+type).then((res) => res.json());
  const getCurrencies = () => fetch('/currencies/').then((res) => res.json());

  useEffect(() => {
    getCurrencies()
    .then((data) => {
      setCurrencies(data.list);
      setCurrency(data.defaultCurrency)
    });
    getData()
      .then((data) => {
        setProducts(data)
      });
  }, []);

  const changeCurrency = (currency_id) => {
    setCurrency(currencies.find(x=>x.id ==currency_id));
  };

  const getPrice = (priceList) => {
    return priceList.find(x=>x.currency == currency.name)
  }

  const changePage = (new_page) => {
    history.push(new_page);
  };

  return (
    <main className={classes.root}>
        <Container maxWidth="lg" className={classes.priceContainer}>
          <Box>
            { type == "Enterprise" ? (
              <Typography variant="h1" color="secondary" className={classes.subTitle}>
                Nos forfaits pour les entreprises
              </Typography>
            ) : (
              <Typography variant="h1" color="secondary" className={classes.subTitle}>
                Nos forfaits pour les partenaires
              </Typography>
            )}
            <Typography variant="body2" className={classes.subTitle}>
              Sélectionnez votre devise et l'abonnement adapté à vos besoins
            </Typography>
            <ButtonGroup size="large" className={classes.buttonGroup}>
            {currencies.map((item) => (
              <Button className={currency==null || item.id!=currency.id ? 'inactive' : 'active'} onClick={()=>changeCurrency(item.id)}>{item.name}</Button>
            ))}
            </ButtonGroup>
              {currency!=null && products.length > 0 && type === 'Enterprise' && (
                <Grid container spacing={2} className={classes.freePlanContainer}>
                  <Grid item xs={12} lg={12} md={12} >
                    <Grid  container className={classes.freePlan}>
                      <Grid item md={3} xs={12} className={classes.freePricingHeader}>
                        <List>
                          <ListItem className={classes.listItemIcon}>
                            <ListItemText
                              className={classes.offerName}
                              primary={<Chip label="GRATUIT" color="secondary" />}
                            />
                          </ListItem>
                        </List>
                        <Typography variant="h3" color="secondary" className={classes.pricingAmount}>
                          0 {currency.symbol}
                        </Typography>
                        <Typography variant="body2" color="secondary" className={classes.pricingTitle}>
                          Parfait pour tester avant de vous engager!
                        </Typography>
                        
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.freePricingDescription}>
                        <List dense className={classes.listOffer}>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary="5 MÉTIERS" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary="Matchs illimités" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary="Visibilité du nombre de candidats potentiel" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary="Rapport mensuel" />
                          </ListItem>
                        </List>
                      </Grid>
                      
                      <Grid item md={3} xs={12} className={classes.freePricingAction}>
                        {!user && (
                          <Button onClick={() => changePage('/entreprise')} color="secondary" className={classes.selectButton} size="small">
                            COMMENCER
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} className={classes.container}>
                  {currency!=null && products.map((product) => {
                    const price = getPrice(product.prices)
                    return (
                      <Grid item lg={3} xs={12} md={3}  >
                        <Grid className={classes.pricingItem}>
                          <div className={classes.pricingHeader}>
                            <List>
                              <ListItem className={classes.listItemIcon}>
                                  <ListItemText
                                    className={classes.offerName}
                                    primary={
                                      <React.Fragment>
                                        <Chip label={product.name.toUpperCase()}  color='secondary'/>
                                      </React.Fragment>
                                    }
                                  />
                              </ListItem>
                            </List>
                            <Typography variant="h3" color='secondary' className={classes.pricingAmount}>
                                {price.price_cents/100} {currency.symbol}  <span className={classes.pricingAmountDesc}>/ {product.duration} mois</span>
                            </Typography>
                            <Typography variant="body2" color='secondary' className={classes.pricingTitle}>
                              soit {price.monthly_price} {currency.symbol} / mois
                            </Typography>
                          </div>
                          <div className={classes.pricingDescription}>
                            <List dense={true} className={classes.listOffer}>
                                {product.stripe_product_options
                                .map((option) => (
                                    <ListItem>
                                      <ListItemIcon>
                                        <CheckIcon color='secondary'/>
                                      </ListItemIcon>
                                      {option.name=="minimum_guaranteed_connexion" ? (
                                        <Tooltip classes={{ tooltip: classes.tooltip }} title="Sinon prolongation gratuite de la durée de l'offre initiale" placement="top-start">
                                          <ListItemText
                                            className={classes.listItemText}
                                            primary={option.title}
                                          />
                                        </Tooltip>
                                      )
                                      :(
                                        <ListItemText
                                          className={classes.listItemText}
                                          primary={option.title}
                                        />
                                      )}
                                    </ListItem>
                                ))}
                            </List>
                            {user && user.type_of_user==type && !user.is_subscribed && (
                              <Button onClick={()=>changePage('/paiement/'+product.product_id+'/'+price.price_id)}  color="secondary" className={classes.selectButton} size="small">
                                S'ABONNER
                              </Button>
                            )}
                            {!user && type=='Enterprise' &&(
                                <Button onClick={ () => changePage('/entreprise') } color="secondary" className={classes.selectButton} size="small">
                                    COMMENCER
                                </Button>
                            )}
                            {!user && type=='Partner'&& (
                                <Button onClick={ () => changePage('/partenaire') } color="secondary" className={classes.selectButton} size="small">
                                    COMMENCER
                                </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )
                  })}
                  {currency!=null && products.length> 0 && (
                    type == 'Enterprise' && (
                      <Grid item lg={3} xs={12} md={3}  >
                        <Grid className={classes.pricingItem}>
                          <div className={classes.pricingHeader}>
                            <List>
                              <ListItem className={classes.listItemIcon}>
                                  
                                  <ListItemText
                                    className={classes.offerName}
                                    primary={
                                      <React.Fragment>
                                        <Chip label="CORPORATIF" color='secondary' />
                                      </React.Fragment>
                                    }
                                  />
                              </ListItem>
                            </List>
                            <Typography variant="h3" color='secondary' className={classes.pricingAmount}>
                              Sur devis
                            </Typography>
                            <Typography variant="body2" color='secondary' className={classes.pricingTitle}>
                              Personnalisé
                            </Typography>
                          </div>
                          <div className={classes.pricingDescription}>
                            <List dense={true} className={classes.listOffer}>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Tous les avantages du programme Maturité"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Tarifs adaptés et dégressifs selon volume d’entreprises"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Une ressource en communication dédiée"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Un chargé de projet dédié"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Sourcing auprès de centres de formation ciblés"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Webinaires et articles pour présenter votre organisation"
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <CheckIcon color='secondary'/>
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.listItemText}
                                    primary="Bilan mensuel avec indicateurs"
                                  />
                                </ListItem>
                            </List>
                            {!user || !user.is_subscribed && (
                                <Button onClick={ () => changePage('/programme-corporatif/formulaire') }  color="secondary" className={classes.selectButton} size="small">
                                    NOUS CONTACTER
                                </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )
                  )}
              </Grid>
              
          </Box>
        </Container>
      <Footer />
    </main>
  );
};

export default Pricing;
