import React, { useState } from 'react';
import {
  Fab, Grid, makeStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import JobSelectionSteps from '../../../Form/FormSteps/Shared/JobSelectionSteps';
import PretSelectionSteps from '../../../Form/FormSteps/Shared/PretSelectionSteps';
import theme from '../../../theme';
import EditMetier from '../../../Form/FormSteps/Shared/EditMetier';
import ViewMetier from '../../../Form/FormSteps/Shared/ViewMetier';
import EditPret from '../../../Form/FormSteps/Shared/EditPret';
import ViewPret from '../../../Form/FormSteps/Shared/ViewPret';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
    },
  },

}));

const AddJob = (props) => {
  const {
    show, closeModal, editMetier, metierData, metierCreated, createMetier, viewMetier, userMetiers, viewProfile, viewPret, editPret, createPret, userType
  } = props;

  
  const [open, setOpen] = React.useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    jobs: null,
    byWorkType: false,
    workType: null,
  });

  const handleClose = () => {
    closeModal(true);
  };
  const errors = {};

  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.jobs || verifyAll) {
      if (formData.jobs === null) {
        errors.jobs = "Vous devez choisir au moins un emploi ainsi que le nombre d'années d'expérience relatif à l'emploi sélectionné";
      }
    }
    setFormErrors({ ...errors, verifyAll });

    return Object.keys(errors).length < 1;
  };

  const onFormChange = (event) => {
    setFormData(
      { ...formData, [event.target.name]: event.target.value },
      validateForm(),
    );
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);

  return (
    <div>
      <Dialog
        className={classes.root}
        open={show ? open === false : open === true}
        onClose={handleClose}
        aria-labelledby="Emploi"
        id="openJobModal"
        fullScreen={fullScreen}
        large="500px"
      >
        <Grid container>
          <DialogContent>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 0',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </Fab>
            </Grid>
            <Grid item xs={12}>
              {editMetier || ViewMetier ? null : <DialogTitle>Choisissez l'emploi dans la liste </DialogTitle>}
            </Grid>
            {editMetier && (
              <EditMetier
                handleClose={handleClose}
                jobLimit={1}
                metierId={metierData.metierId}
                userMetierId={metierData.userMetierId}
                metierName={metierData.metierName}
                userType={userType}
                formData={formData}
                onFormChange={onFormChange}
              />
            )}
            {editPret && (
              <EditPret
                handleClose={handleClose}
                jobLimit={1}
                metierId={metierData.metierId}
                userMetierId={metierData.userMetierId}
                metierName={metierData.metierName}
                userType={userType}
                formData={formData}
                onFormChange={onFormChange}
              />
            )}
            {viewMetier && (
              <ViewMetier
                handleClose={handleClose}
                jobLimit={1}
                metierId={metierData.metierId}
                userMetierId={metierData.userMetierId}
                metierName={metierData.metierName}
                userType={userType}
                formData={formData}
                onFormChange={onFormChange}
                viewProfile={viewProfile}
              />
            )}
            {viewPret && (
              <ViewPret
                handleClose={handleClose}
                jobLimit={1}
                metierId={metierData.metierId}
                userMetierId={metierData.userMetierId}
                metierName={metierData.metierName}
                userType={userType}
                formData={formData}
                onFormChange={onFormChange}
                viewProfile={viewProfile}
              />
            )}
            {createMetier
              && (
                <JobSelectionSteps
                  metierCreated={metierCreated}
                  handleClose={closeModal}
                  userType={userType}
                  jobLimit={1}
                  formData={formData}
                  onFormChange={onFormChange}
                  allUserMetier={userMetiers}
                />
              )}
            {createPret
              && (
                <PretSelectionSteps
                  metierCreated={metierCreated}
                  handleClose={closeModal}
                  userType={userType}
                  jobLimit={1}
                  formData={formData}
                  onFormChange={onFormChange}
                  allUserMetier={userMetiers}
                />
              )}
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AddJob;
