import React, { Fragment, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getLanguageSearch } from '../Functions/Get-query/getLanguagesSearch';
import useSessionContext from '../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
}));

const parseFromElasticSearchResults = (results) => {
  let parsedCitiesArray = [];
  results.forEach(res=>{
    parsedCitiesArray.push({
      id: res._source.id,
      name:  res._source.name,
      nativeName: res._source.nativeName,
      code:  res._source.code
    })
  });
  return parsedCitiesArray;
}

const LanguageAutoComplete = ({
  name,
  multiple = true,
  max = 3,
  onFormChange,
  errors,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [loadingLang, setLoadingLang] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [ languageDisabled, setLanguageDisabled ] = useState(false);
  const [ languageData, setLanguageData ] = useState([]);
  const { SnackBar } = useSessionContext();
  const { informError } = SnackBar;

  useEffect(() => {
    if (!isOpen) {
      setLanguagesList([]);
    }
    return () => setLanguagesList([]);
  }, [isOpen]);

  const onTextChanged = (event) => {
    if (!loadingLang) {
      setLoadingLang(true);
        const query = event.target.value;
        if (query === null) {
          setLanguagesList([]);
        } else {
        getLanguageSearch(query)
          .then((result) => {
            if (result) {
              if (result.total > 0) {
                const parsedRes = parseFromElasticSearchResults(result.results);
                setLanguagesList(parsedRes);
              }
            } else {
              informError('Une erreur est survenue');
            }
          });
        }
        setLoadingLang(false);
      }
  };

  const handleLanguageSelection = (event, data) => {
    if (data.length <= max ) {
      if (data === null) {
        return onFormChange({ target: { name, value: [] } });
      }
      const langArray = data.map((d) =>({id: d.id, name: d.name}));
      setLanguageData(data)
      return onFormChange({ target: { name, value: langArray } });
    } else {
      setLanguageDisabled(true)
    }
  };

  const classes = useStyles();
  return (
    <>
      <Autocomplete
        multiple={multiple}
        noOptionsText="En chargement..."
        limitTags={3}
        onOpen={onTextChanged}
        onClose={() => setLanguagesList([])}
        id="languages"
        name="languages"
        options={languagesList}
        getOptionDisabled={() => languageData.length >= max ? true : false}
        getOptionLabel={(option) => `${option.nativeName} (${option.name})`}
        getOptionSelected={(option, value) => option.id === value.id}
        key={(option) => option.code}
        onChange={handleLanguageSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={ languageDisabled }
            onChange={onTextChanged}
            onBlur={onTextChanged}
            variant="outlined"
            error={!!errors[name]}
            label="Choisir une langue *"
            placeholder="Langue"
          />
        )}
      />
      {errors[name] && (
        <p className={`${classes.error} ${'error'}`}>{errors[name]}</p>
      )}
    </>
  );
};

LanguageAutoComplete.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

LanguageAutoComplete.defaultProps = {
  max: 3,
  multiple: true,
};

export default LanguageAutoComplete;
