const ContextReducer = (state, action) => {
  switch (action.type) {
    case 'changeUserInfo':
      return {
        ...state,
        userInfo: action.newUserInfo,
      };
    case 'changeAuthToken':
      return {
        ...state,
        authToken: action.newAuthToken,
      };
    case 'changeLoggedIn':
      return {
        ...state,
        logged_in: action.newLoggedIn,
      };
    default:
      return state;
  }
};

export default ContextReducer;
