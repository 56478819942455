import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getWorkTypeSearch = (value) => (
  axios.get(`${apiUrl()}/work_type?query=${value}*`)
    .then((response) => response.data)
    .catch((error) => errorHandling(error))
);

export default getWorkTypeSearch;
