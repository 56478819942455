import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CardHeader,
    Typography,
    Grid,
    Card,
    CardContent
} from '@material-ui/core';
import theme from '../../theme';
import ModifyInfo from './FormModal/modifyInfo';
import PhoneInput from 'react-phone-input-2'

const useStyles = makeStyles((theme) => ({
    rootCard: {
        width: '100%',
    },
    profileTabTitle: {
        '& span': {
            fontWeight: 600,
            fontSize: '1.25rem',
            color: '#E5087E',
        },
    },
    labelSubtitle:{
        fontWeight: 600
    },
    textValue: {
        fontWeight: '400 !important'
    },
    containerClass:{
        '& .special-label':{
          display: 'none!important'
        }
      },
      phoneInputClass:{
        width: 'auto!important',
        fontFamily: 'Dosis!important',
        padding: '10px 1px 10px 58px!important',
        border: 'none!important',
        '&:disabled':{
            cursor: 'auto!important',
            color: '#37383e!important'
        }
      }
}));

const AccountInfo = (props) => {
    const classes = useStyles(theme);
    const { profileInfo } = props;
    return (
        <>
            <Card square className={classes.rootCard} elevation={0} variant="outlined">
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <CardHeader
                            title="Information entreprise"
                            className={classes.profileTabTitle}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >

                        <ModifyInfo userInfo={profileInfo} entepriseEdition={true}/>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CardContent>
                    <Grid container spacing={4}>
                        { profileInfo.typeOfUser != 'Candidate' && (
                            <>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" color="textSecondary"  className={classes.labelSubtitle}>
                                        Entreprise
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.textValue}>
                                    { profileInfo.enterpriseName }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" color="textSecondary"  className={classes.labelSubtitle}>
                                        Site web
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.textValue}>
                                        { profileInfo.website }
                                    </Typography>
                                </Grid>
                            </>
                         )}
                         { profileInfo.typeOfUser == 'Enterprise' && (
                            <>
                                
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" color="textSecondary"  className={classes.labelSubtitle}>
                                        Nombre d'employés
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary">
                                        {profileInfo.employeeCount}
                                    </Typography>
                                </Grid>
                            </>
                         )}
                            <>
                                
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary"  className={classes.labelSubtitle}>
                                        Numéro de téléphone
                                    </Typography>
                                    {profileInfo.phoneNumber!=null && profileInfo.phoneNumber!='' && profileInfo.phoneNumber!='null' && (
                                        <PhoneInput
                                            disabled
                                            specialLabel='Numéro de téléphone'
                                            value={profileInfo.phoneNumber}
                                            containerClass={`'MuiFormControl-root' ${classes.containerClass}`}
                                            inputClass={classes.phoneInputClass}
                                            disableDropdown
                                            disableCountryCode
                                            inputProps={{
                                                readonly: true
                                              }}
                                        />
                                    )}
                                        
                                </Grid>
                            </>
                         { profileInfo.typeOfUser == 'Partner' && (
                            <>
                                
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary"  className={classes.labelSubtitle}>
                                        Service disponible partout dans le monde
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary">
                                        { profileInfo.global ? 'Oui' : 'Non'}
                                    </Typography>
                                </Grid>
                            </>
                         )}
                         
                    </Grid>
                    </CardContent>

                </Grid>
            </Card>


        </>
    );
};

export default AccountInfo;
