import React, { useRef, useEffect } from 'react';
import { CountUp } from 'countup.js';

const CountUpAnimation = ({ end, duration = 2 }) => {
    const countUpRef = useRef(null);

    useEffect(() => {
        const countUp = new CountUp(countUpRef.current, end, {
            duration,
        });

        if (!countUp.error) {
            countUp.start();
        } else {
            console.error(countUp.error);
        }

        return () => countUp.reset();
    }, [end, duration]);
    return <span ref={countUpRef} />;
};

export default CountUpAnimation;