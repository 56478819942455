import axios from 'axios';
import { errorHandling } from '../errorHandling';
import { apiUrl } from '../apiUrl';

export const getBlogData = (searchTerm, category) => {
  let url = `${apiUrl()}/blog_data`;
  const params = new URLSearchParams();

  // Ajouter searchTerm à la requête seulement s'il est défini et non vide
  if (searchTerm) {
    params.append('searchTerm', searchTerm);
  }

  // Ajouter category à la requête seulement si elle est définie et non vide
  if (category) {
    params.append('category', category);
  }

  // Construire l'URL avec les paramètres de recherche conditionnels
  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  return axios.get(url)
    .then(response => response.data)
    .catch(error => errorHandling(error));
};

export default getBlogData;
