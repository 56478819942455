import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useRecaptcha } from 'react-hook-recaptcha';

const useStyles = makeStyles((theme) => ({
  gRecaptcha: {
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        width: '100%!important',
      },
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    '& iframe': {
      width: '100%',
      height: '85px',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      '& div': {
        width: '100%',
      },
    },
  },
}));

const containerId = 'recaptcha';
const sitekey = '6LdhjYIpAAAAAOktpr7kHTIpV8rRmneapSc9ek_m';

export default ({ handleRecaptchaResponse }) => {
  const classes = useStyles();

  const successCallback = (response) => {
    handleRecaptchaResponse(response);
  };
  const expiredCallback = (e) => {
    handleRecaptchaResponse(null);
  };

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    size: 'normal',
    sitekey,
  });

  return <div id={containerId} className={classes.gRecaptcha} />;
};
