import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../Functions/apiUrl';
import { errorHandling } from '../Functions/errorHandling';
import ConnexionBox from '../UI-Component/ConnexionBox';

export default () => {
  const [user, setUser] = useState();
  const [navId, setNavId] = useState(0);
  const bearer = () => {
    try {
      localStorage.getItem('jwt-token');
    } catch {
      alert("Localstorage et/ou les cookies sont désactivés ce qui peut occasioner dans la navigation du site. Veuillez consulter les paramètres de votre navigateur.")
    }
  }

  const authorized_header = (multipart) => ({
    Authorization: `${bearer()}`,
    'Content-Type': (multipart ? 'multipart/form' : 'application/json'),
  });

  const modifyUser = (u) => {

    setUser(u);
  };

  const fetchUser = () => {
    const options = {
      url: `${apiUrl()}/users/me`,
      method: 'GET',
      headers: authorized_header(),
    };

    const isObject = (obj) => {
      return Object.prototype.toString.call(obj) === '[object Object]';
    };

    axios(options)
      .then((response) => {
        modifyUser(response.data.user);
        return response;
      })
      .catch((error) => {
        if ( error && isObject(error.response) && error.response.status === 401) {
          modifyUser(null);
        }
        return errorHandling(error);
      });
  };



  const refreshUserData = () => {
    fetchUser();
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const signout = () => {
    const options = {
      url: `${apiUrl()}/users/sign_out`,
      method: 'DELETE',
      headers: authorized_header(),
    };

    return axios(options)
      .then((response) => {
        modifyUser(null);

        return response;
      })
      .catch((error) => errorHandling(error))
      .finally(() => localStorage.removeItem('jwt-token'));
  };

  const objectToForm = (object, formData, prefix) => {
    if (Object.entries !== null && Object.entries !== undefined) {
      Object.entries(object)
        .forEach(([key, value]) => {
          if (value instanceof Array) {
            if (value.length === 0) {
              formData.append(`${prefix}[${key}]`, [].toString());
            } else {
              value.forEach((e) => {
                if (typeof e === 'object' && e !== null && e !== undefined) {
                  Object.entries(e)
                    .forEach(([k, v]) => {
                      formData.append(`${prefix}[${key}][][${k}]`, v);
                    });
                } else {
                  formData.append(`${prefix}[${key}][]`, e);
                }
              });
            }
          } else if (value instanceof Object && !(value instanceof File)) {
            objectToForm(value, formData, `${prefix}[${key}]`);
          } else {
            formData.append(`${prefix}[${key}]`, value);
          }
        });
    } else {
      console.error("Object is null or undefined in useUser")
    }
  };

  const createUser = (raw) => {
    const bodyFormData = new FormData();
    objectToForm(raw.user, bodyFormData, 'user');
    const options = {
      url: `${apiUrl()}/users`,
      method: 'POST',
      headers: authorized_header(true),
      data: bodyFormData,
    };

    return axios(options)
      .then((response) => response)
       .catch((error) => {
         return errorHandling(error);
       });
  };

  const updateUser = (raw) => {
    const bodyFormData = new FormData();
    objectToForm(raw.user, bodyFormData, 'user');
    const options = {
      url: `${apiUrl()}/users`,
      method: 'PATCH',
      headers: authorized_header(true),
      data: bodyFormData,
    };

    return axios(options)
      .then((response) => (response))
      .catch((error) => {
        if (error.response.status === 401) {
          modifyUser(null);
          return (<ConnexionBox defaultOpen={true}/>)
        }
        return errorHandling(error);
      });
  };

  const loginUser = (raw) => {
    const options = {
      url: `${apiUrl()}/users/sign_in`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data: raw,
    };

    return axios(options)
      .then((response) => {
        const { authorization } = response.headers;
        localStorage.setItem('jwt-token', authorization);
        return response;
      })
      .catch((error) => errorHandling(error));
  };

  const deleteUser = (raw) => {
    return axios.delete(`${apiUrl()}/users/destroy`, raw)
      .then((response) => {
        modifyUser(null);
        return response;
      })
      .catch((error) => errorHandling(error));
  };

  const unsubscribeUser = (raw) => {
    return axios.post(`${apiUrl()}/users`, raw)
      .then((response) => {
        return response;
      })
      .catch((error) => errorHandling(error));
  };

  const resetPassword = (email) => {
    return axios.post(`${apiUrl()}/users/password`, {
      user:
        {
          email,
        },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => errorHandling(error));
  };

  const getMessages = async () => {
    const options = {
      url: `${apiUrl()}/messages`,
      method: 'GET',
      headers: authorized_header(),
    };
  
    try {
      const response = await axios(options);
      return response.data; // Assurez-vous que l'API retourne les données directement ici
    } catch (error) {
      return errorHandling(error); // Assurez-vous que errorHandling renvoie quelque chose d'utile ou modifie l'état de l'application
    }
  };

  return {
    fetchUser,
    user,
    refreshUserData,
    modifyUser,
    signout,
    navId,
    setNavId,
    createUser,
    updateUser,
    resetPassword,
    unsubscribeUser,
    deleteUser,
    loginUser,
    getMessages
  };
}
;
