import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, LinearProgress, SvgIcon, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

// Définir les styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiListItem-root':{
        padding: 0
    },
    '& .MuiLinearProgress-colorPrimary' :{
        backgroundColor: '#ccc'
    }
  },
  listItemText:{
    width: 250,
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  progressGrey: {
    backgroundColor: theme.palette.grey[300],
  },
  progressOrange: {
    backgroundColor: theme.palette.warning.main,
  },
  progressGreen: {
    backgroundColor: theme.palette.success.main,
  },
  iconGrey: {
    color: theme.palette.grey[500],
  },
  iconOrange: {
    color: theme.palette.warning.main,
  },
  iconGreen: {
    color: theme.palette.success.main,
  },
  text: {
    fontSize: '1rem', 
  },
}));

const getColor = (current, target) => {
  if (current === target) return 'green';
  if (current > 0 && current < target) return 'orange';
  return 'grey';
};

const InfoCompletion = ({ data }) => {
  const classes = useStyles();

  const sortedData = Object.keys(data)
    .map((key) => data[key]);

  return (
    <List className={classes.root}>
      {sortedData.map(({ target, current, title }, index) => {
        const progress = (current / target) * 100;
        const color = getColor(current, target);

        return (
          <ListItem key={index}>
            <ListItemIcon>
              {color === 'green' ? (
                <CheckIcon className={classes.iconGreen} />
              ) : color === 'orange' ? (
                <CheckIcon className={classes.iconOrange} />
              ) : (
                <CloseIcon className={classes.iconGrey} />
              )}
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={
                <Typography className={classes.text}>{title}</Typography>
              }
            />
            <div className={classes.progress}>
              <LinearProgress
                variant="determinate"
                value={progress}
                classes={{
                  barColorPrimary:
                    color === 'green'
                      ? classes.progressGreen
                      : color === 'orange'
                      ? classes.progressOrange
                      : classes.progressGrey,
                }}
              />
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default InfoCompletion;
