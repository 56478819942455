import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Card, CardHeader, Backdrop, CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { errorHandling } from '../../Functions/errorHandling';
import SubscriptionTable from './SubscriptionTable';
import SubscriptionDialog from './SubscriptionDialog';
import BillingInfo from './BillingInfo';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


const { STRIPE_KEY } = process.env;


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  rootCard: {
    width: '100%',
    textAlign: 'left',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.928rem',
      textAlign: 'center',
    },
  },
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
}));

const stripePromise = loadStripe(STRIPE_KEY);


const Subscription = () => {
  const classes = useStyles();
  const [subscriptions, setSubscriptions] = useState([]);
  const [modalOpenId, setModalOpenId] = useState(null);
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [action, setAction] = useState('');
  const [blackdrop, setBlackdrop] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleActionChange = (e, sub) => {
    setSubscription(sub);
    if (e.target.value === 'renew') {
      setOpen(true);
    } else if (sub.invoice_pdf != null) {
      window.open(sub.invoice_pdf, '_blank');
    }
  };

  const openActionMenu = (lineId) => {
    setModalOpenId(lineId);
  };

  const closeActionMenu = () => {
    setModalOpenId(null);
  };

  const getSubscriptions = () => fetch('/subscriptions/').then((res) => res.json());

  useEffect(() => {
    getSubscriptions()
      .then((data) => {
        setSubscriptions(data);
      });
  }, []);

  const updateAutoRenew = () => {
    setModalOpenId(null);
    handleClose();
    setBlackdrop(true);
    axios.post(`/subscription/update-auto-renew/` + subscription.id)
      .then(async (response) => {
        if (response.status === 200 && response.data.error === false) {
          getSubscriptions().then((data) => {
            setSubscriptions(data);
            setBlackdrop(false);
          });
        } else {
          errorHandling(response.data.msg);
          setBlackdrop(false);
        }
      })
      .catch((error) => {
        errorHandling(error);
        setBlackdrop(false);
      });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Elements stripe={stripePromise}>
      <div id="abonnements" className={classes.root}>
        <Grid container>
          <Card square className={classes.rootCard} elevation={0}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <CardHeader
                  title="Abonnements"
                  className={classes.profileTabTitle}
                />
              </Grid>
            </Grid>
          </Card>
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <SubscriptionTable
              subscriptions={subscriptions}
              openActionMenu={openActionMenu}
              closeActionMenu={closeActionMenu}
              handleActionChange={handleActionChange}
              modalOpenId={modalOpenId}
              action={action}
              formatDate={formatDate}
            />
            <SubscriptionDialog
              open={open}
              handleClose={handleClose}
              updateAutoRenew={updateAutoRenew}
              subscription={subscription}
            />
          </Grid>
          <Card square className={classes.rootCard} elevation={0}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <CardHeader
                  title="Facturation"
                  className={classes.profileTabTitle}
                />
              </Grid>
            </Grid>
          </Card>
          <BillingInfo />
        </Grid>
        <Backdrop className={classes.backdrop} open={blackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Elements>
  );
};

export default Subscription;
