import React, { useState } from 'react';
import { Fab, Button, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ServicesAutoComplete from '../../../Form/ServicesAutoComplete';
import theme from '../../../theme';
import useSessionContext from '../../../sessions/useSessionContext';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        minWidth: 500,
      },
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  '& > *': {
    zIndex: 100,
    position: 'relative',
  },

  submit: {
    margin: theme.spacing(3, 0, 3),
  },
}));

const AddService = (props) => {
  const [open, setOpen] = React.useState(false);
  const {
    refreshUserData,
    updateUser,
    user
  } = useSessionContext();
  const [formData, setFormData] = useState({
    services: null,
  });
  const {
    show,
    closeModal,
    userServices,
  } = props;

  const handleClose = () => {
    closeModal(false);
  };
  const errors = {};

  const updateServices = (services) => {
    const raw = {
      user: {
        services: services      
      },
    };
    updateUser(raw).then((response) => {
      if (response.status === 200) {
        handleClose();
        refreshUserData();
        setFormData({ services: null });
      }
    });
  };

  const validateForm = (verifyAll = false) => {
    const errors = {};
    if (formData.services || verifyAll) {
      if (formData.services === null) {
        errors.services = 'Vous devez choisir au moins un service!';
      }
    }

    return Object.keys(errors).length < 1;
  };

  const onFormChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      },
      validateForm(),
    );
  };

  const handleSubmit = () => {
    const newServices = [];
    formData.services.forEach((serv) => { 
      newServices.push(serv.id);
    });
    updateServices(newServices);   
  };  

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);
  return (
    <div>
      <Dialog
        className={classes.root}
        open={show ? open === false : open === true}
        onClose={handleClose}
        aria-labelledby="Servue"
        fullScreen={fullScreen}
        large="500px"
      >
        <Grid container spacing={2}>
          <DialogContent>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 0',
              }}
            >
              <Fab color="secondary" aria-label="close" onClick={handleClose}>
                <CloseIcon/>
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle>Choisir un service dans la liste</DialogTitle>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ServicesAutoComplete
                  name="services"
                  label="Choisir un service *"
                  multiple
                  onFormChange={onFormChange}
                  errors={errors}
                  formData={formData}
                  userServices={userServices}
                  serviceModal
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={handleSubmit}>Valider</Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
};

export default AddService;
