import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/Logo-Moovejob.png";
import logoRecrutement from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-recrutement.png";
import logoSousTraitance from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-sous-traitance.png";
import logoPretPersonnel from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-pret-de-personnel.png";
import logoImmigrantInvestisseur from "../../images/logo/Logo-Moovejob-CMJN-fond-transparent-investisseur.png";
import { Link, useLocation } from 'react-router-dom';
import { Hidden } from "@material-ui/core";

const logos = {
  '/accueil': logo,
  '/service/recrutement-international': logoRecrutement,
  '/service/sous-traitance': logoSousTraitance,
  '/service/pret-de-personnel': logoPretPersonnel,
  '/service/immigrant-investisseur': logoImmigrantInvestisseur
};

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    [theme.breakpoints.down("md")]: {
      padding: 15,
      width: "fit-content",
    },
  },
  logoStyle: {
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
  },
}));

export default function Logo() {
  const classes = useStyles();
  const location = useLocation();
  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    const path = location.pathname;
    setCurrentLogo(logos[path] || logo);
  }, [location.pathname]);

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 100){
      setShowScroll(false)
    }
  };
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;



  const scrollTop = () =>{
    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
    <div className={classes.logoContainer}>
      <Hidden lgUp>
        <Link to="/accueil" className="scrollTop" onClick={scrollTop} >
          <img src={currentLogo} alt="moovejob" className={classes.logoStyle} />
        </Link>
      </Hidden>
    </div>
  );
}
