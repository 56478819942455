import React, { useState } from 'react';
import {
  makeStyles,
  Button,
  TextField,
  Grid,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  FormControl,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmailIcon from '@material-ui/icons/Mail';
import axios from 'axios';
import theme from '../../theme';
import { apiUrl } from '../../Functions/apiUrl';
import { errorHandling } from '../../Functions/errorHandling';
import useSessionContext from '../../sessions/useSessionContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: '100%',
    },
  },
  rootCard: {
    width: '100%',
    textAlign: 'left',
  },
  profileTabTitle: {
    '& span': {
      fontWeight: 600,
      fontSize: '1.928rem',
      textAlign: 'center',
    },
  },
  shareMenu: {
    display: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButton: {
    display: '100%',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '0!important',
    borderRadius: '50px!important',
    border: 0,
    background: 0,
    '&:focus': {
      boxShadow: '0 0 0 2pt #999',
      outline: 0,
    },
    '&  svg': {
      width: '30px',
      height: '30px',
      borderRadius: '50px',
    },
  },
  error: {
    color: 'secondary',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    textAlign: 'left',
    backgroundColor: 'secondary',
    color: '#212123',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    boxShadow: 0,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  reverseButtonStyle: {
    color: '#ffffff',
    backgroundColor: 'secondary',

    '&:hover': {
      backgroundColor: 'secondary',
    },
    '&:focus, &.focus': {
      backgroundColor: 'secondary',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FormMessageSender({
  notification,
  iSubscribed,
  typeOfUser,
}) {
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const [formErrors, setFormError] = useState({});
  const [choiceMessage, setChoiceMessage] = useState(null);
  const [customizeMessage, setCustomizeMessage] = useState('');
  const {
    SnackBar,
    setNavId,
    updateUser,
    user,
    refreshUserData
  } = useSessionContext();
  const {
    informSuccess,
    informError
  } = SnackBar;
  const [state, setState] = useState({ right: false });

  const handleRead = () => {
    const { is_read } = notification;
    if (is_read) return;
    const raw = {
      user: {
        notification: notification.id,
        read_status: true,
      },
    };
    updateUser(raw)
      .then(() => {
        refreshNotification();
      });
  };

  const handleClose = () => {
    setOpen(false);
    setCustomizeMessage('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const history = useHistory();

  function navigate(path) {
    history.push(path);
  }

  const navigateToSubscription = () => {
    toggleDrawer();
    navigate('/mon-compte');
    setNavId(5);
  };
  const {
    id,
    answers
  } = notification;

  const refreshNotification = () => {
    refreshUserData();
  };

  const sendNotification = () => {

    if ((typeOfUser === 'Enterprise' && !iSubscribed && choiceMessage === null && customizeMessage === '') || (typeOfUser === 'Partner' && !iSubscribed && choiceMessage === null && customizeMessage === '')) {
      informError('Vous devez vous abonner a moovejob pour communiquer avec le candidat');
      return;
    }
    if (typeOfUser === 'Candidate') {
      if (customizeMessage === '' && choiceMessage === null) {
        return;
      }
    }

    if (choiceMessage === null) {
      setChoiceMessage('candidate_interested_answer');
    }

    if (typeOfUser === 'Enterprise' && !iSubscribed && choiceMessage === null) {
      setChoiceMessage('enterprise_interest');
    }

    if (typeOfUser === 'Partner' && !iSubscribed && choiceMessage === null) {
      setChoiceMessage('partner_candidate_interest');
    }

    if (typeOfUser === 'Partner' && !iSubscribed && choiceMessage === null) {
      setChoiceMessage('partner_enterprise_interest');
    }

    axios.post(`${apiUrl()}/notifications/${id}/answer/${choiceMessage}`, {
      customizeMessage,
      typeOfUser,
    })
      .then((response) => {
        if (response.status === 200) {
          informSuccess('Notification envoyée avec succès');
          refreshNotification();
          handleRead();
        }
      })
      .catch((error) => errorHandling(error));
    handleClose();

  };

  const handleChoiceMessage = (answer) => () => {
    setChoiceMessage(answer.kind);
  };

  const showTextMark = (answer) => {
    const answerKindMark = (answer.kind === 'candidate_not_interested_answer' || answer.kind === 'enterprise_not_subbed_not_interested' ||
      answer.kind === 'candidate_to_partner_not_interested_answer' || answer.kind === 'enterprise_to_partner_not_interested_answer' ||
      answer.kind === 'applicant_not_interested_answer' || answer.kind === 'provider_not_interested_answer' ||
      answer.kind === 'partner_to_candidate_not_interested_answer' || answer.kind === 'partner_to_enterprise_not_interested_answer');
    if (answerKindMark) {
      return (
        <CancelIcon style={{ fill: 'red' }}/>
      );
    }
    return (
      <CheckCircleIcon style={{ fill: 'green' }}/>
    );
  };

  const handleCustomizeMessage = (e) => {
    setCustomizeMessage(e.target.value);
  };

  const candidateMessageToSubEnterprise = () => {
    if (notification.is_enterprise_subscribed || notification.is_partner_subscribed) {
      return (
        <div>
          <FormControl style={{
            width: 400,
            height: 300
          }}
          >
            <TextField
              style={{
                width: 400,
                height: 200
              }}
              id="outlined-textarea"
              label="Votre message..."
              placeholder="Votre message..."
              multiline
              rows={15}
              variant="outlined"
              ref={notification}
              value={customizeMessage}
              onChange={handleCustomizeMessage}
            />
          </FormControl>
        </div>
      );
    }
  };

  const messageEnterprise = () => {
    if (iSubscribed || notification.is_enterprise_subscribed || notification.is_partner_subscribed) {
      return (
        <div>
          <FormControl style={{
            width: 400,
            height: 300
          }}
          >
            <TextField
              style={{
                width: 400,
                height: 200
              }}
              id="outlined-textarea"
              label="Votre message..."
              placeholder="Votre message..."
              multiline
              rows={15}
              variant="outlined"
              ref={notification}
              value={customizeMessage}
              onChange={handleCustomizeMessage}
            />
          </FormControl>
        </div>
      );
    }
  };

  const renderAnswer = () => {
    if ((typeOfUser === 'Enterprise' && iSubscribed) || (typeOfUser === 'Partner' && iSubscribed) || notification.is_enterprise_subscribed) {
      return (
        <form>
          {messageEnterprise()}
        </form>
      );
    } else if ((typeOfUser === 'Candidate' && notification.is_enterprise_subscribed) || notification.is_partner_subscribed) {
      return (
        <form>
          {candidateMessageToSubEnterprise()}
        </form>
      );
    } 
    else if ((typeOfUser === 'Enterprise' && iSubscribed) &&  (user.enterprise_infos.providing_loan) &&  notification.is_applicant_subscribed) {
      return (
        <form>
          {messageEnterprise()}
        </form>
      );
    }
    else if ((typeOfUser === 'Enterprise' && iSubscribed) &&  (user.enterprise_infos.seeking_loan) &&  notification.is_provider_subscribed) {
      return (
        <form>
          {messageEnterprise()}
        </form>
      );
    }else if (!notification.is_enterprise_subscribed || !notification.is_partner_subscribed) {
      return (
        <List className={classes.rootList}>
          {answers.map((answer, index) => <Answer answer={answer} index={index} key={index}/>)}
        </List>
      );
    } else {
      return (
        <List className={classes.rootList}>
          {answers.map((answer, index) => <Answer answer={answer} index={index} key={index}/>)}
        </List>
      );
    }
  };

  const sendOrLink = () => {
    if (((typeOfUser === 'Enterprise' && iSubscribed === false && notification.notification_kind === 'candidate_interested_answer') || (typeOfUser === 'Partner' && iSubscribed === false && notification.notification_kind === 'candidate_to_partner_interested_answer') || (typeOfUser === 'Enterprise' && iSubscribed === false && notification.notification_kind === 'partner_to_enterprise_interested_answer') || (typeOfUser === 'Partner' && iSubscribed === false && notification.notification_kind === 'enterprise_to_partner_interested_answer'))) {
      return (
        <Button
          onClick={navigateToSubscription}
          className={classes.reverseButtonStyle}
          color="primary"
        >
          Vous Abonner
        </Button>
      );
    } else {
      return (
        <Button
          onClick={sendNotification}
          className={classes.reverseButtonStyle}
          color="primary"
        >
          Envoyer le message
        </Button>
      );
    }
  };

  const Answer = ({
    answer,
    index,
  }) => {
    const { kind } = answer;
    if (kind === 'candidate_custom_message') {
      return null;
    }
    const { message } = answer;
    return (
      <ListItem
        key={kind}
        role={undefined}
        dense
        button
        onClick={handleChoiceMessage(answer)}
        alignItems="flex-start"
      >
        <ListItemIcon>
          <Radio
            id={`answer-${kind}`}
            checked={choiceMessage === kind}
            value={kind}
            name="radio-button-list"
            inputProps={{ 'aria-label': 'A' }}
          />
        </ListItemIcon>
        <ListItemText
          id={`checkbox-list-label-${index}`}
          primary={message}
          secondary={showTextMark(answer)}
        />
      </ListItem>
    );
  };

  const dialogTitle = () => {
    if (typeOfUser == 'Enterprise' && notification.notification_kind == 'candidate_interest') {
      return ('Choisissez le message a envoyer');
    } else if (typeOfUser == 'Enterprise' && iSubscribed == false && notification.notification_kind == 'candidate_interested_answer') {
      return ('Vous devez vous abonner pour envoyer un message');
    } else if ((typeOfUser === 'Enterprise' && iSubscribed === false && notification.notification_kind === 'candidate_interested_answer') || (typeOfUser === 'Partner' && iSubscribed === false && notification.notification_kind === 'candidate_to_partner_interested_answer') || (typeOfUser === 'Enterprise' && iSubscribed === false && notification.notification_kind === 'partner_to_enterprise_interested_answer') || (typeOfUser === 'Partner' && iSubscribed === false && notification.notification_kind === 'enterprise_to_partner_interested_answer')) {
      return ('Vous devez vous abonner pour envoyer un message');
    } else if (typeOfUser == 'Enterprise' && iSubscribed && notification.notification_kind == 'candidate_interested_answer') {
      return ('Envoyer un message');
    } else {
      return ('Choisissez le message a envoyer');
    }
  };

  return (
    <div>
      <Fab
        id={`answers-${id}`}
        color="secondary"
        aria-label="view"
        onClick={handleClickOpen}
      >
        <EmailIcon>Veuillez inscrire votre message.</EmailIcon>
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '10px 0',
              }}
            >
              <Fab
                color="secondary"
                aria-label="close"
                onClick={handleClose}
              >
                <CloseIcon/>
              </Fab>
            </Grid>
            <Grid item xs={12}>
              <DialogTitle style={{ textAlign: 'center' }}>
                {dialogTitle()}
              </DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <DialogActions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {renderAnswer()}
                {sendOrLink()}
                <Button
                  color="primary"
                  onClick={handleClose}
                >
                  Annuler
                </Button>

              </DialogActions>
            </Grid>
          </Grid>

          <div style={{
            position: 'relative',
            top: 20,
            textAlign: 'center'
          }}
          >
            {formErrors.global && (
              <p className={`${classes.error} error`}>
                {formErrors.global}
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
