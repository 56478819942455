import React from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Footer from '../UI-Component/Footer';
import ReferenceCandidateForm from '../Form/ReferenceCandidateForm';
import ReferenceEnterpriseForm from '../Form/ReferenceEnterpriseForm';

const useStyles = makeStyles((theme) => ({
  root:{},
  referenceContainer:{
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    justifyContent: 'space-evenly',
    paddingBottom: '50px',
  },
  bgHero: {
    height: '20vh',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right, #7063798a, #7063798a, rgb(232 22 129 / 43%), rgb(232 22 129 / 32%), rgb(232 22 129 / 28%))',
    marginTop: 80,
  },
  earnLabel :{
    color: '#e5077f!important'
  },
  homeContainer: {
    position: 'absolute',
    top: '30%',
    left: 0,
    zIndex: 1,
    width: '100%',
    textAlign: 'center',
    display:'flex',
    justifyContent: 'center',
    display: 'inline-grid',
    
    '& h1': {
      color: theme.palette.primary.main,
      marginTop: 0
    },
  },
  contentContainer: {
    paddingTop: "40px",
    paddingBottom: "50px"
  },
  subTitle:{
      marginTop: 0,
      marginBottom:0,
      marginLeft: 5,
      width: '100%',
      textAlign: 'center',
  },
  listMoove: {
    position: 'relative',
    margin: '0',
    '&::before': {
      content: '"*"',
      position: 'absolute',
      left: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '30px',
      width: '30px',
      height: '100%',
      fontSize: 45,
      color: theme.palette.secondary.main,
    },
    '& p': {
      margin: '0',
      marginLeft: '20px',
      padding: '0px',
    },
  },
  flexHeightDesktop: {
    height: 'auto',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  listMooveContainer: {
    [theme.breakpoints.down('sm')]: {
      width:'unset',
    },
    display:'flex',
    marginTop: 25,
    "& ul ":{
      backgroundColor: '#f9f9f9!important',
      padding:  15,
      borderRadius: 15,
    }
  },
}));

const Reference = (props) => {
  const { type } = props;
  const classes = useStyles(theme);

  return (
    <>
    <main style={{ overflowX: 'hidden' }}>
        <div className={classes.root}>
            <div className={classes.bgHero}>
                
                <Container maxWidth={false} className={classes.homeContainer}>
                {type=='Candidate' ? (
                  <Typography variant="h1">Programme de parrainage candidat</Typography>
                ):(
                  <Typography variant="h1">Programme de parrainage entreprise</Typography>
                )}
                </Container>
            </div>
            <Grid container className={classes.contentContainer}>
            {type=='Candidate' ? (
              <>
                <Typography variant="h2" className={classes.subTitle}>Parrainer un candidat</Typography>
                <Typography variant="h2" className={`${classes.subTitle} ${classes.earnLabel}`}>et gagner 200 $ *</Typography>
                <Grid item xs={12} md={12}>
          
                    <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>
                      <List>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                          N'importe qui peut parrainer un candidat à moovejob et recevoir un bonus.
                          </Typography>
                        </ListItem>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                            Recevez un bonus de 200 $ sous forme de carte cadeau pour chaque candidat parrainé et embauché par l'une de nos entreprises inscrites.
                          </Typography>
                        </ListItem>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                          Parrainez autant de candidats que vous le souhaitez !
                          </Typography>
                        </ListItem>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                          Chaque parrainage doit être unique et non déjà existante dans notre base de données.
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                </Grid>
              </>
            ):(
              <>
                <Typography variant="h2" className={classes.subTitle}>Parrainer une entreprise</Typography>
                <Typography variant="h2" className={`${classes.subTitle} ${classes.earnLabel}`}>et recever une commission de 10% *</Typography>
                <Grid item xs={12} md={12}>
          
                    <Box className={`${classes.listMooveContainer} ${classes.flexHeightDesktop}`}>
                      <List>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                           N'importe qui peut parrainer une entreprise à moovejob.
                          </Typography>
                        </ListItem>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                           Recevez une commission de 10% pour chaque abonnement annuel souscrit par l'entreprise référencée
                          </Typography>
                        </ListItem>
                        <ListItem className={classes.listMoove}>
                          <Typography className={classes.bulletPoint}>
                            Une réduction de 18% sur les abonnements annuels est offerte à toutes les entreprises référencées.
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                </Grid>
              </>
            )}
              <Grid item md={2} xs={12}>
              </Grid>
              <Grid item md={8} xs={12}>
                <Grid item lg={12} className={classes.referralContainer}>
                    {type=='Candidate' ? (
                      <ReferenceCandidateForm/>
                    ):(
                      <ReferenceEnterpriseForm/>
                    )}
                    
                </Grid>
              </Grid>
            </Grid>
        </div>
      <Footer />
    </main>
    </>
  );
};

export default Reference;
