import React, { useEffect, useState } from 'react';
import { makeStyles, Button, TextField } from '@material-ui/core';
import theme from '../theme';
import ReCaptcha from '../Form/ReCaptcha';
import useSessionContext from '../sessions/useSessionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    'ul li': {
      display: 'flex',
    },
  },

  shareMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  shareButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '0!important',
    borderRadius: '50px!important',
    border: 0,
    background: 0,
    '&:focus': {
      boxShadow: '0 0 0 2pt #999',
      outline: 0,
    },
    '&  svg': {
      width: '30px',
      height: '30px',
      borderRadius: '50px',
    },
  },
  error: {
    color: '#e5087e',
    fontFamily: ['Dosis', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    marginTop: '3px',
    fontWeight: '500',
  },
  forgotPasswordBtn: {
    textAlign: 'left',
    backgroundColor: theme.palette.primary.main,
    color: '#37383e',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    boxShadow: 0,
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  passwordField: {
    borderRadius: 4,
    border: '1px solid #b2b1b1',
    '& input': {
      padding: '18.5px 14px',
    },
    '&.MuiInput-underline:before': {
      content: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  reverseButtonStyle: {
    color: '#E5087E',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    '&:focus, &.focus': {
      backgroundColor: '#E5087E',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '20px',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        color: '#E5087E',
      },
    },
  },
  formFooter: {
    width: '75%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    padding: '0 0 0 40px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '0 0',
      margin: '0 auto',
    },
  },
}));

export default function FormNewsletter() {
  const classes = useStyles(theme);
  const [emailValue, setEmailValue] = useState('');
  const { SnackBar } = useSessionContext();
  const { informSuccess, informError } = SnackBar;

  const emailInputValue = (value) => {
    setEmailValue(value);
  };

  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const handleRecaptchaResponse = (r) => {
    setRecaptchaResponse(r);
  };
  useEffect(() => {
    if (process.env.NO_CAPTCHA) {
      setRecaptchaResponse(-1);
    }
    return () => setRecaptchaResponse(-1);
  }, []);

  const saveEmail = (e) => {
    e.preventDefault();

    if (!recaptchaResponse) return;

    if (emailValue != null && emailValue.includes('@')) {
      fetch('/register/to/mailings/list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailValue,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            informSuccess('Ajouté dans la liste avec succès');
          }
          return res.json();
        })
        .then((response) => {
          const { errors } = response;
          if (errors) informError(errors);
        })
        .catch((error2) => {
          console.error(
            `Une erreur est survenue: ${error2.errorMessage}`,
            error2.errorStatus,
          );
        });
    }
  };

  return (
    <form className={classes.formFooter} onSubmit={saveEmail}>
      
      <TextField 
        id="newsemail" 
        placeholder="Entrez votre e-mail ici"
        label="Votre adresse courriel"
        variant="outlined"
        required
        value={emailValue} 
        onChange={(e) => emailInputValue(e.target.value)}
      />
      <br/>
      <br/>
      {recaptchaResponse !== -1 && (
        <ReCaptcha handleRecaptchaResponse={handleRecaptchaResponse} />
      )}
      <Button
        type="submit"
        value="Submit"
        id="confirmNews"
        className={classes.buttonStyle}
      >
        S'inscrire aux newsletters
      </Button>
    </form>
  );
}
