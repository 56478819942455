import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { AppBar, Grid, Card, Badge, Box, Hidden } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpIcon from '@material-ui/icons/Help';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmailIcon from '@material-ui/icons/Email';
import UserProfile from './UserProfile';
import HelpSection from './HelpSection';

import Notifications from './Notifications';
import Settings from './Settings';
import logoHero from '../../../images/Logo-Moovejob.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import useSessionContext from '../../sessions/useSessionContext';
import Subscription from './Subscriptions';
import Messages from './Messages';

const useStyles = makeStyles((theme) => ({
  root: {},
  bottomNavProfile: {
    bottom: '80px',
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    '& svg': {
      verticalAlign: 'middle',
    },
    '& .Mui-selected': {
      '& svg': {
        color: theme.palette.secondary.main,
        verticalAlign: 'middle',
      },
    },
    '& .Mui-disabled': {
      '& svg': {
        color: 'rgba(0, 0, 0, 0.12)',
      },
    },
    '& span': {
      color: '#333',
      textTransform: 'lowercase',
      '& div span': {
        display: 'inline-flex',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 10,
          minWidth: 15,
          height: 15,
        },
      },
    },
  },
  menuTabsProfile: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    [theme.breakpoints.down('sm')]: {
      top: 100,
      zIndex: 10000,
    },
    '& button': {
      justifyContent: 'center',
      margin: 0,
      width: 120,
      [theme.breakpoints.up('md')]: {
        '& span': {
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'left',
          textTransform: 'none',
          fontSize: 16,
          display: 'inline-flex',
          '& span': {
            flexDirection: 'row',
            fontSize: 12,
            minWidth: 15,
            height: 15,
          },
        },
      },
    },
  },
  menuTabs: {
    '& button': {
      fontSize: 16,
      margin: 0,
      minWidth: 'fit-content',
      '& span': {
        alignItems: 'center',
        textAlign: 'left',
        textTransform: 'none',
        fontSize: 16,
      },
    },
  },
  notificationCircle: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50px',
    width: 15,
    height: 15,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  notificationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ textAlign: 'left' }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { typeOfUser } = props;
  const { user, refreshUserData } = useSessionContext();
  const { navId, setNavId } = useSessionContext();

  var handleChange = function (event, newValue) {
    setNavId(newValue);
  };

  return (
    <Grid container>
      <AppBar position="fixed" className={classes.menuTabsProfile}>
        <Hidden mdDown>
          <Card square style={{ padding: 20 }}>
            <img src={logoHero} alt="logo" className={classes.logoStyleSmall} />
          </Card>
        </Hidden>
        <Tabs
          className={classes.bottom}
          value={navId}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable tabs"
        >
          <Tab
            icon={
              <div className={classes.iconContainer}>
                <AccountCircleIcon />
              </div>
            }
            aria-label="Profil"
            label="Profil"
            {...a11yProps(0)}
          />
          <Tab
            icon={
              <div className={classes.iconContainer}>
                {/* <Badge color="secondary" badgeContent={1}> */}
                <NotificationsIcon />
                {/* </Badge> */}
              </div>
            }
            aria-label="Notifications"
            label={
              <>
                {user.unread_notif > 0 ? (
                  <Badge badgeContent={user.unread_notif} color="secondary" className={classes.badge}>
                    Matchs
                 </Badge>
                ) : (
                  <div className={classes.notificationWrapper}>
                    Matchs
                  </div>
                )}
              </>
            }
            {...a11yProps(1)}
          />
          <Tab
            icon={
              <div className={classes.iconContainer}>
                <EmailIcon />
              </div>
            }
            aria-label="Message"
            label={
              <>
                {user.unread_message > 0 ? (
                  <Badge badgeContent={user.unread_message} color="secondary" className={classes.badge}>
                    Messages
                 </Badge>
                ) : (
                  <div className={classes.notificationWrapper}>
                    Messages
                  </div>
                )}
              </>
            }
            {...a11yProps(2)}
          />
          <Tab
            disabled
            icon={
              <div className={classes.iconContainer}>
                <HelpIcon />
              </div>
            }
            aria-label="Aide"
            label="Aide"
            {...a11yProps(3)}
          />
          <Tab
            icon={
              <div className={classes.iconContainer}>
                <SettingsIcon />
              </div>
            }
            aria-label="Paramètres"
            label="Paramètres"
            {...a11yProps(4)}
          />
          { user.type_of_user != 'Candidate' && <Tab
            icon={
              <div className={classes.iconContainer}>
                <AttachMoneyIcon />
              </div>
            }
            aria-label="Abonnements"
            label="Abonnements"
            {...a11yProps(5)}
          />}
        </Tabs>
      </AppBar>
      <AppBar
        position="relative"
        className={classes.menuTabs}
        style={{ margin: '20px 0 100px' }}
      >
         <TabPanel value={navId} index={0}>
              <UserProfile />
            </TabPanel>
            <TabPanel value={navId} index={1}>
              <Notifications />
            </TabPanel>
            <TabPanel value={navId} index={2}>
              <Messages/>
            </TabPanel>
            <TabPanel value={navId} index={3}>
              <HelpSection/>
            </TabPanel>
            <TabPanel value={navId} index={4}>
              <Settings />
            </TabPanel>
            <TabPanel value={navId} index={5}>
              <Subscription />
            </TabPanel>
      </AppBar>
    </Grid>
  );
}
